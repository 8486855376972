import * as React from 'react'
import Box from '@mui/material/Box'
//import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro'
import { DataGrid } from '@mui/x-data-grid'
import useGlobal from 'store'

export default function UserHistory({ formName, table, sx, select, ...rest }) {
  const [globalState, globalActions] = useGlobal()
  const form = globalState.forms[formName]
  const fields = form.fields
  const tablename = '@user-history'

  React.useEffect(() => {
    if (fields.email_address.widget.value) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'get-user-history',
        apiPayload: {
          email_address: fields.email_address.widget.value,
        },
        spinner: {
          content: 'Loading Grid Content. Please Wait...'
        }
      })
    }
  }, [globalActions.api, fields.email_address.widget.value])
  if (!globalState.repo[tablename]) return
  const {
    columns = [],
    rows = []
  } = globalState.repo[tablename]
  return (
    <Box sx={{ height: 500, width: '100%', ...sx }}>
      <DataGrid
        getRowId={(row) => row.sys_id}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        density="compact"
        autoPageSize
        {...rest}
      />
    </Box>
  )
}
