const Base = {
  border: 0,
  borderRadius: 1,
  boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.3)',
  height: '35px',
  width: '35px',
  cursor: 'pointer',
}
export const ButtonStyle = {
  ...Base,
  margin: '4px',
  padding: '6px'
}

export const MenuButtonStyle = {
  ...Base,
}