import { useSnackbar } from 'notistack'
import useGlobal from 'store'
import context from 'lib/context'

const Notifier = () => {
  const [globalState, globalActions] = useGlobal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const {
    toast = {},
    displayed = []
  } = globalState.notifications


  if (context.isEmpty(toast)) return
  for (const [key, value] of Object.entries(toast)) {
    const { message, options = {}, dismissed = false } = value
    if (dismissed) {
      // dismiss snackbar using notistack
      closeSnackbar(key)
      return
    }

    // do nothing if snackbar is already displayed
    if (displayed.includes(key)) return

    // display snackbar using notistack
    enqueueSnackbar(message, {
      key,
      ...options,
      onClose: (event, reason, myKey) => {
        if (options.onClose) {
          options.onClose(event, reason, myKey)
        }
      },
      onExited: (event, myKey) => {
        globalActions.control.removeDisplayed(myKey)
      }
    })

    // keep track of snackbars that we've displayed
    globalActions.control.storeDisplayed(key)
  }

  return
}

export default Notifier
