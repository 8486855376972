import * as React from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'

function Component({ label, options, callback, initialSelected }) {
  if (initialSelected === undefined) {
    initialSelected = options
  }
  const [selected, setSelected] = React.useState(initialSelected)
  const isAllSelected =
    options.length > 0 && selected.length === options.length

  const handleChange = (event) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options)
      return
    }
    setSelected(value)
  }

  React.useEffect(() => {
    callback(selected)
  }, [selected, callback])

  return (
    <FormControl fullWidth>
      <InputLabel id='mutiple-select-label'
        sx={{
          backgroundColor: 'white',
          zIndex: 1000,
          pl: 1,
          pr: 1,
          l: -1,
        }}>{label}</InputLabel>
      <Select
        labelId='mutiple-select-label'
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      // variant='menu'
      >
        <MenuItem
          value='all'
          sx={{}}>
          <ListItemIcon>
            <Checkbox
              sx={{}}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            sx={{}}
            primary='Select All'
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Component