import * as React from 'react'
import useGlobal from 'store'
import { Grid } from '@mui/material'


import {
  TextField,
  KeyValueField,
  Checkbox,
  Image,
  Radio
} from 'components'

function SpotDonation({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields
  return (
    <>
      <fieldset sx={{
        p: 20,
        borderRadius: 3,
        color: 'rgba(0, 0, 0, 0.4)',
        borderColor: 'rgba(0, 0, 0, 0.1)'
      }}>
        <legend>Spot Donation</legend>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Checkbox
              {...fields.spot_option_show.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              {...fields.spot_option_bottom.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Image
              {...fields.spot_image.widget}
              onChange={onEvent}
              formName={formName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields.spot_option_orientation.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields.spot_option_title.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields.spot_option_label.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields.spot_option_defaultvalue.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValueField
              {...fields.spot_option_values.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields.spot_option_values.widget}
              onChange={onEvent}
            />
          </Grid>
        </Grid>
      </fieldset>
    </>
  )
}

function Metric({ index, formName, ...props }) {

  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields
  return (
    <>

      <fieldset sx={{
        p: 20,
        borderRadius: 3,
        color: 'rgba(0, 0, 0, 0.4)',
        borderColor: 'rgba(0, 0, 0, 0.1)'
      }}>
        <legend>Metric {index} </legend>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_header'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Image
              {...fields['metric_' + index + '_image'].widget}
              onChange={onEvent}
              formName={formName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_label'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_secondarytext'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_helpertext'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_defaultvalue'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValueField
              {...fields['metric_' + index + '_values'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['metric_' + index + '_values'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              {...fields['metric_' + index + '_show_other'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Radio
              {...fields['metric_' + index + '_orientation'].widget}
              onChange={onEvent}
            />
          </Grid>
        </Grid>
      </fieldset>
    </>
  )
}

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            {...fields.form_instructions.widget}
            onChange={onEvent}
          />
        </Grid>

        <Grid item xs={12}>
          <SpotDonation formName={formName} />
        </Grid>

        <Grid item xs={12}>
          <fieldset sx={{
            p: 20,
            borderRadius: 3,
            color: 'rgba(0, 0, 0, 0.4)',
            borderColor: 'rgba(0, 0, 0, 0.1)'
          }}>
            <legend>Metric Options</legend>
            <Checkbox
              {...fields.show_pledge_cap.widget}
              onChange={onEvent}
            />
            <Checkbox
              {...fields.show_rally_whole_season.widget}
              onChange={onEvent}
            />
          </fieldset>
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...fields.metric_section_instructions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Metric index={1} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={2} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={3} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={4} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={5} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={6} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={7} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={8} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Metric index={9} formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
