import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'plaid_item'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    access_token: widget({
      label: 'Access Token'
    }, {
      display: true
    }),
    user_id: widget({
      label: 'User Id'
    }, {
      display: true
    }),
    remote_access_token: widget({
      label: 'Remote Access Token'
    }, {
      display: true
    }),
    last_update: widget({
      label: 'Last Update'
    }, {
      display: true
    }),
    error: widget({
      label: 'Error'
    }, {
      display: true
    }),
    traceback: widget({
      label: 'Traceback'
    }, {
      display: true
    }),
    plaid_institution_id: widget({
      label: 'Plaid Institution Id'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    remote_status: widget({
      label: 'Remote Status'
    }, {
      display: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
    environment: widget({
      label: 'Environment'
    }, {
      display: true
    }),
    remote_institution_id: widget({
      label: 'Remote Institution Id'
    }, {
      display: true
    }),
    remote_item_id: widget({
      label: 'Remote Item Id'
    }, {
      display: true
    }),
    webhook: widget({
      label: 'Webhook'
    }, {
      display: true
    }),
    error_type: widget({
      label: 'Error Type'
    }, {
      display: true
    }),
    error_code: widget({
      label: 'Error Code'
    }, {
      display: true
    }),
    is_legacy: checkbox({
      label: 'Is Legacy'
    }, {
      display: true
    }),
    legacy_id: widget({
      label: 'Legacy Id'
    }, {
      display: true
    }),
    account_id: widget({
      label: 'Account Id'
    }, {
      display: true
    }),
    date_acquired: widget({
      label: 'Date Acquired'
    }, {
      display: true
    }),
    remote_account_id: widget({
      label: 'Remote Account Id'
    }, {
      display: true
    }),
    error_date: widget({
      label: 'Error Date'
    }, {
      display: true
    })
  }
})



export default data
