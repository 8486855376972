import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  color,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'customers'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client_id: widget({
      label: 'Client Id'
    }, {
      display: true,
      searchable: true
    }),
    client_name: widget({
      label: 'Client Name'
    }, {
      display: true,
      searchable: true
    }),
    descriptor: widget({
      helperText: 'The soft descriptor for the credit card transaction. ',
      moreInfo: <>
        Descriptor format is "company*client". Company name section must be either 3, 7 or 12 characters and the < br />
        client info can be up to 18, 14, or 9 characters respectively(with an * in between for a total descriptor name of 22 characters).
      </>,
      label: 'Descriptor'
    }),
    page_title: widget({
      label: 'Page Title'
    }),
    logo: widget({
      label: 'Logo'
    }),
    primary_color: color({
      label: 'Primary Color'
    }),
    secondary_color: color({
      label: 'Secondary Color'
    }),
    header_font: widget({
      label: 'Header Font'
    }),
    text_font: widget({
      label: 'Text Font'
    }),
    inactive: checkbox({
      label: 'Inactive (EVERYTHING OFF. Do not allow donations)'
    }),
    allow_product_monthly_recurring: checkbox({
      label: 'Allow Monthly Recurring'
    }),
    allow_product_swipegive: checkbox({
      label: 'Allow SwipeGive'
    }),
    allow_product_rallygive: checkbox({
      label: 'Allow RallyGive'
    }),
    allow_product_spot_donation: checkbox({
      label: 'Allow Spot Donations'
    }),
    is_political: checkbox({
      label: 'Is Political'
    }),
    primary_email: widget({
      label: 'Primary Contact Email'
    }),
    large_logo_url: widget({
      label: 'Large Logo'
    }),
    small_logo_url: widget({
      label: 'Small Logo'
    }),
    long_description: widget({
      label: 'Long Description',
      multiline: true,
    }),
    short_description: widget({
      label: 'Short Description',
      multiline: true,
    }),
    attestation_text: widget({
      label: 'Attestation Text',
      multiline: true,
    }),
    vanity_url: widget({
      label: 'Vanity URL'
    }),
    website: widget({
      label: 'website'
    }),
    notes: widget({
      label: 'Notes'
    }),
    campaign_title: widget({
      label: 'Campaign Title'
    }),
  }
})




export default data
