import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  ActionButton,
  Checkbox,
  DirtyForm,
  SaveButtons,
  SystemFields,
  TextField,
} from 'components'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Transactions'
          >
            Instructions: Edit Transactions
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item>
              <ActionButton
                data-handler='transactions'
                data-action='reverse-transaction'
                data-title='Reverse transaction?'
                data-warning='Not Implemented.'
              >
                Create Reversing Transaction
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='transactions'
                data-action='duplicate-transaction'
                data-title='Duplicate transaction?'
                data-warning='Not Implemented.'
              >
                Duplicate Transaction
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='transactions'
                data-action='void-transaction'
                data-title='Void transaction?'
                data-warning='(Not Implemented) This action will send a message to the processor to void transaction. This action may fail if the transaction is not in a state that can be voided.'
              >
                Void Transaction
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='transactions'
                data-action='refund-transaction'
                data-title='Refund transaction?'
                data-warning='(Not Implemented) This action will send a message to the processor to refund transaction. This action will create a new transaction for the refund.'
              >
                Refund Transaction
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='transactions'
                data-action='send-receipt'
                data-sys_id={fields.sys_id.widget.value}
                data-title='Send Email Receipt'
                data-warning={`This action will send an email receipt to ${fields.email_address.widget.value}. Are you sure you want to do this?`}
              >
                Send Receipt
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.email_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.first_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.last_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.src.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.src_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.type.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.status.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.amount.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.effective_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.campaign.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.pmt_type.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.pmt_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.subcampaign.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.src_client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.src_campaign.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.update_locked.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.hide_from_client_invoice.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.is_disputed.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
