import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'dashboard_latest_donations'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client: widget({
      label: 'Client'
    }, {
      display: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    authorized_amount: widget({
      label: 'Authorized Amount'
    }, {
      display: true
    }),
    processor_response_text: widget({
      label: 'Processor Response Text'
    }, {
      display: true
    }),
    card_type: widget({
      label: 'Card Type'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    amount: widget({
      label: 'Amount'
    }, {
      display: true
    }),
    pmt_type: widget({
      label: 'Pmt Type'
    }, {
      display: true
    }),
    effective_date: widget({
      label: 'Effective Date'
    }, {
      display: true
    })
  }
})



export default data
