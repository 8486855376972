import * as React from 'react'
import useGlobal from 'store'
import {
  Box,
  Grid
} from '@mui/material'

function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  if (!formName) {
    formName = globalState.forms.current?.formName
  }
  const form = globalState.forms[formName]

  const {
    rows = [],
    config = {}
  } = globalState.repo[formName] || { undefined }

  if (!config.allRowsSelected || !config.allRowsSelected.length) { return null }

  return (
    <>
      <Box sx={{ padding: 2, xfontFamily: 'lucida console, courier, monospace' }}>
        <Box sx={{ paddingBottom: 2 }}>
          {config.allRowsSelected.length} row(s) selected from table  {formName}:
        </Box>
        <Grid container>
          {form.selected_fields.map((i) => {
            return (
              <Grid key={'selected-row-' + i.column} item xs={i.units}>
                <b>{i.column}</b>
              </Grid>
            )
          })}
          {config.allRowsSelected.map(i => {
            const row = rows[i.dataIndex]
            const onClick = () => {
              globalActions.tables.setTable({
                tableName: form.table,
                formName,
                type: 'rowClick',
                rowData: [row.sys_id],
                rowMeta: null
              })
            }
            return (<Grid key={'selected-row-' + row.sys_id} item xs={12}>
              <Box sx={{
                cursor: 'pointer',
                '&:hover': {
                  background: '#ddd',
                }
              }} onClick={onClick}>
                <Grid container>
                  {form.selected_fields.map((i) => {
                    return (
                      <Grid
                        key={'selected-cell-' + i.column + '-' + row.sys_id}
                        item
                        xs={i.units}
                      >
                        {i.display ? i.display(row[i.column]) : row[i.column]}
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Grid>)
          })}
        </Grid>
        {form?.cards?.SystemActions && <form.cards.SystemActions formName={formName} />}
        {form?.cards?.SelectedRowActions && <form.cards.SelectedRowActions formName={formName} />}
      </Box>
    </>
  )
}


export default Component
