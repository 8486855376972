import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useGlobal from 'store'
import context from 'lib/context'

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import * as Icons from '@mui/icons-material'
import { BuildInfo, Logo } from 'components'

const categories = [
  {
    id: '',
    children: [
      { id: 'Back to Admin', icon: <Icons.Undo />, link: '/app/Dashboard' },
    ],
  },

  {
    id: 'Documentation',
    children: [
      { id: 'Home', icon: <Icons.Home />, link: '/documentation/home' },
      {
        id: 'Developers', icon: <Icons.StorageRounded />, link: '/documentation/developers',
        children: [
          { id: 'Start Here', icon: <Icons.Home />, link: '/documentation/start_here' },
          { id: 'GIT Repositories', icon: <Icons.Home />, link: '/documentation/git_repos' },
          { id: 'DB Info (Postgres)', icon: <Icons.Home />, link: '/documentation/db_info', },
          { id: 'Form Builder', icon: <Icons.Home />, link: '/documentation/form_builder' },
        ]
      },
      { id: 'Clients', icon: <Icons.ColorLens />, link: '/documentation/clients' },
      { id: 'Donors', icon: <Icons.ColorLens />, link: '/documentation/donors' },
    ],
  },

]


const header = {
  py: 1.5,
  px: 3,
  color: 'white',
}

const item = {
  py: .1,
  px: 4,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

function NavListItem({ data, level = -1 }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line


  if (level === -1) {
    const { id, children } = data

    return (
      <>
        <Box key={id}>
          <ListItem sx={{ ...header }}>
            <ListItemText>{id}</ListItemText>
          </ListItem>
          {children && children.map(data => (<NavListItem key={data.id} data={data} level={level + 1} />))}
          <Divider />
        </Box>
      </>)
  } else {
    const { id: childId, icon, active, link, children } = data
    const sx = {
      ml: level * 2,
    }
    return (
      <>
        <RouterLink
          style={{ textDecoration: 'none' }}
          to={link}
          key={childId}
          onClick={globalActions.control.closeNavigator}
        >
          <ListItem disablePadding key={childId} sx={sx}>
            <ListItemButton selected={active} sx={item}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText sx={{ ml: 1 }}>{childId}</ListItemText>
            </ListItemButton>
          </ListItem>
        </RouterLink>
        {children && children.map(data => (
          <NavListItem key={data.id} data={data} level={level + 1} />
        ))}
      </>
    )
  }
}


export default function Navigator({ ...other }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    vars = {}
  } = globalState.controls

  return (<>
    <Drawer
      variant='permanent'
      {...other}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: context.gradientVert,
        }
      }}
    >
      <List disablePadding>
        <ListItem>
          {vars.AWS_BRANCH === 'demo' ? <>
            <Box sx={{
              color: 'red',
              fontSize: 'large',
              position: 'relative',
              fontWeight: 'bold',
              top: 15,
              left: 25
            }}>
              DEMO SYSTEM
            </Box>
          </> :
            <Logo style={{ maxHeight: 35 }} />}
        </ListItem>
        {categories.map(data => <NavListItem data={data} />)}
      </List>
      <BuildInfo />
    </Drawer>
  </>
  )
}
