import * as React from 'react'
import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'
import { context } from 'lib/context'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ReplayIcon from '@mui/icons-material/Replay'
import useGlobal from 'store'

const Component = ({ ...rest }) => {

  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    latest_donations = {
      rows: []
    }
  } = globalState.repo

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-latest-donations',
    })
  }, [globalActions.api])

  const handleClick = e => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-latest-donations',
      spinner: {
        content: 'Loading Latest Donation Data. Please Wait...'
      }
    })
  }

  return (
    <Card
      sx={{
        position: 'relative',
        height: '100%'
      }}
      {...rest}
    >
      <CardHeader title='Recent Donations' />
      <ReplayIcon onClick={handleClick} sx={{
        position: 'absolute',
        right: 5,
        top: 5
      }} />
      <Divider />
      <Box minWidth={800}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Campaign
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Email
              </TableCell>

              <TableCell>
                Amount
              </TableCell>
              <TableCell sortDirection='desc'>
                <Tooltip
                  enterDelay={300}
                  title='Sort'
                >
                  <TableSortLabel
                    active
                    direction='desc'
                  >
                    Date
                  </TableSortLabel>
                </Tooltip>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {latest_donations.rows.slice(0, 7).map((donation) => (
              <TableRow
                hover
                key={donation.sys_id}
              >
                <TableCell>
                  {donation.campaign}
                </TableCell>
                <TableCell>
                  {donation.first_name}    {donation.last_name}
                </TableCell>
                <TableCell>
                  {donation.email_address}
                </TableCell>

                <TableCell>
                  ${context.formatMoney(donation.amount)}
                </TableCell>
                <TableCell>
                  {moment(donation.effective_date).format('ll')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
        <RouterLink to='/app/donations'>
          <Box
            display='flex'
            justifyContent='flex-end'
            p={2}
          >
            <Button
              color='primary'
              endIcon={<ArrowRightIcon />}
              size='small'
              variant='text'
            >
              See All Donations
            </Button>
          </Box>
        </RouterLink>
      </Box>
    </Card>
  )
}

export default Component
