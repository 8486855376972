import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'admin_users'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    UserStatus: {
      widget: {},
      defaults: {
        error: false,
        fullWidth: true,
        helperText: 'Cognito Status ',
        label: 'UserStatus',
        placeholder: '',
        size: 'small',
        variant: 'outlined',
        value: '',
        displayonly: 'true',
      }
    },
    username: widget({
      label: 'Username'
    }, {
      display: true,
      searchable: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      searchable: true
    }),
    phone_number: widget({
      label: 'Phone Number'
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true,
      searchable: true
    }),
    profile_image: widget({
      label: 'Profile Image'
    }),
    profile_description: widget({
      label: 'Profile Description'
    })
  }
})

data.selected_fields = [
  { units: 1, column: 'sys_id', display: data => <b>{data}</b> },
  { units: 2, column: 'username' },
  { units: 4, column: 'email_address', display: data => <b>{data}</b> },
  { units: 3, column: 'full_name' },
]


export default data
