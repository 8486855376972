import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'fraud_detection_attempts'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_created desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    source_ip: widget({
      label: 'IP'
    }),
    email_address: widget({
      label: 'Email'
    }),
    attempted_amount: widget({
      label: 'amount'
    }),
    // client: widget({
    //   label: 'client'
    // }),
    // campaign: widget({
    //   label: 'campaign'
    // }),

  }
})




export default data
