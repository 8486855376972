import * as React from 'react'
import {
  Container,
  Grid,
} from '@mui/material'
import {
  Header,
  ToolBar
} from 'components'
import CurrentMonth from './CurrentMonth'
import PreviousMonth from './PreviousMonth'
import useGlobal from 'store'

const Dashboard = () => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line


  return (
    <>
      <Header label='Mail Dashboard' title='Mail Dashboard' />
      <ToolBar
        inspector
        formbuilder
      />
      <Container maxWidth={false} sx={{ pt: 3 }}>
        <Grid
          container
          spacing={3}
        >

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <PreviousMonth />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CurrentMonth />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Dashboard
