import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'email_instance'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    src_table: widget({
      label: 'Src Table'
    }, {
      display: true
    }),
    src_id: widget({
      label: 'Src Id'
    }, {
      display: true
    }),
    email_from: widget({
      label: 'Email From'
    }, {
      display: true
    }),
    email_to: widget({
      label: 'Email To'
    }, {
      display: true
    }),
    email_cc: widget({
      label: 'Email Cc'
    }),
    email_bcc: widget({
      label: 'Email Bcc'
    }),
    email_subject: widget({
      label: 'Email Subject'
    }, {
      display: true
    }),
    email_body: widget({
      label: 'Email Body'
    }),
    email_raw: widget({
      label: 'Email Raw'
    }),
    email_send_status: widget({
      label: 'Email Send Status'
    }, {
      display: true
    }),
    date_start: checkbox({
      label: 'Date Start'
    }, {
      display: true
    }),
    date_complete: checkbox({
      label: 'Date Complete'
    }, {
      display: true
    }),
    email_type: widget({
      label: 'Email Type'
    }, {
      display: true
    })
  }
})



export default data
