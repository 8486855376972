import * as React from 'react'
import useGlobal from 'store'

import {
  Drawer,
} from '@mui/material'
import Tabs from './Tabs'


export default function Inspector() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { inspectorOptions } = globalState.controls
  return (<>
    <Drawer
      variant='persistent'
      open={inspectorOptions.open}
      anchor={inspectorOptions.anchor}
    >
      <Tabs />
    </Drawer>
  </>
  )
}
