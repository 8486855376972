import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  Button,
  Grid,
} from '@mui/material'

import {
  CodeField,
  ActionButton,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CodeField
            {...fields.test_json.widget}
            onChange={onEvent}
            height='40vh'
            defaultLanguage='json'
          />
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <ActionButton
                data-handler='program'
                data-action='validate-json'
                data-test_json={fields.test_json.widget.value}
                tooltip='Validate JSON Syntax'
              >
                Validate JSON (Py)
              </ActionButton>
            </Grid>
            <Grid item>
              <Button
                onClick={e => {
                  try {
                    JSON.parse(fields.test_json.widget.value)
                    context.alertPopup('ok')
                  } catch (error) {
                    context.alertPopup(error.message)
                  }
                }}
                color='secondary'
                variant='contained'
                size='small'
                fullWidth
              >
                Validate JSON (JS)
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={e => {
                  try {
                    const obj = JSON.parse(fields.test_json.widget.value)
                    const action = {
                      formName,
                      type: 'text',
                      name: 'test_json',
                      value: JSON.stringify(obj, null, 4)
                    }
                    globalActions.control.publish('setForm', { action })
                  } catch (error) {
                    context.alertPopup(error.message)
                  }
                }}
                color='secondary'
                variant='contained'
                size='small'
                fullWidth
              >
                Format JSON
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </>
  )
}
