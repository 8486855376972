import * as React from 'react'
import {
  Box,
  Container,
  Grid
} from '@mui/material'
import useGlobal from 'store'
import {
  ActionButton,
} from 'components'
const CustomerListView = () => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'list-tables',
      spinner: {
        content: 'Loading Table Data. Please Wait...'
      }
    })
  }, [globalActions.api])

  return (
    <Container maxWidth={false}>
      <Box mt={3}>
        <Grid container>
          <Grid item>
            <ActionButton
              data-handler='database'
              data-action='get-tables'
              tooltip='Retrieve the list of tables in the database.'
            >
              Get Tables
            </ActionButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default CustomerListView
