import * as React from 'react'
import { Grid, Button } from '@mui/material'
import {
  TextField,
} from 'components'
import ReactPlayer from 'react-player'
import useGlobal from 'store'
import context from 'lib/context'
import { useDropzone } from 'react-dropzone'
import { useImageLibrary } from 'store/state'

function Dropzone({ formName, name, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const {
    [formName]: form,
  } = globalState.forms
  const onDrop = React.useCallback(async files => {
    if (form.fields.sys_id.widget.value) {
      const {
        client,
        client_id,
        campaign
      } = form.shadow
      if (!(client || client_id)) {
        context.alertPopup('No Client was specified. Please set the client first.')
        return
      }
      if (!(files && files.length)) {
        context.alertPopup('No file was specified. Please select a file to upload.')
        return
      }
      if (files && files.length && (client || client_id)) {
        globalActions.api.performApi({
          apiName: 'Events',
          apiPath: '/event',
          apiAction: 'upload-formbuilder-file',
          apiPayload: {
            client: client || client_id,
            campaign,
            file: {
              file: files[0],
              data: await context.readFile(files[0])
            }
          },
          spinner: {
            content: 'Processing file. Please Wait...'
          },
          callback: (store, response) => {
            let path = 'https://s3.amazonaws.com/donate.resources'
            if (client) {
              path += '/' + client
            } else if (client_id) {
              path += '/' + client_id
            }
            if (campaign) {
              path += '/' + campaign
            }
            path += '/' + files[0].name
            path = path.toLowerCase()
            globalActions.control.publish('setForm', {
              action: {
                formName,
                type: 'input',
                name: name,
                value: path
              }
            })
          }
        })
      }
    }
  }, []) // eslint-disable-line
  const {
    open,
    isDragActive,
    getRootProps,
    getInputProps,
    acceptedFiles, // eslint-disable-line
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
  })
  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ))
  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Button variant='contained' color='primary' component='span' onClick={open} fullWidth>
          {
            isDragActive ?
              'Drag Files' : 'Upload Files'
          }

        </Button>
      </div>
      {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
    </div>
  )
}


export const Component = ({ formName, ...props }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const [imageLibrary, setImageLibrary] = useImageLibrary()// eslint-disable-line
  const inputRef = React.useRef()

  const {
    [formName]: form,
    image_library
  } = globalState.forms
  const fields = form.fields

  React.useEffect(() => {
    let filter
    let client

    if (fields.client_id) {
      client = fields.client_id.widget.value
    }
    if (fields.client) {
      client = fields.client.widget.value
    }
    if (context.isDefined(image_library)) {
      if (context.isNotEmpty(client)) {
        setImageLibrary.setPartialState({ client })
        if (client && client[0] === '@') {
          filter = {
            client,
            '%client': '@@'
          }
        } else {
          filter = {
            client: '@@',
            '%client': client
          }
        }
        globalActions.control.mergeState({
          forms: {
            image_library: {
              queryParams: { filter }
            }
          }
        })
        globalActions.control.publish('callAfter', {
          func: globalActions.control.reloadTable,
          params: ['image_library', { content: 'Reloading. Please wait...' }]
        })
      }
    }
    globalActions.control.deleteStateKey(['repo', 'image_library'])
    return () => {
      setImageLibrary.setPartialState({ client: '' })
      globalActions.control.deleteStateKey(['repo', 'image_library'])
    }
  }, [
    fields,
    formName,
    globalActions.control,
    image_library,
    setImageLibrary
  ])



  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <TextField
            {...props}
            value={props.value || ''}
            onChange={props.onChange}
            inputRef={inputRef}
            showImageIcon
            showcount={false}
            onKeyDown={(event) => {
              if (event.code === 'KeyI' && event.ctrlKey) {
                event.stopPropagation()
                event.preventDefault()

                event.target.select()
                setImageLibrary.setPartialState({
                  formName,
                  insertFormat: 'url',
                  selection: {
                    start: event.target.selectionStart,
                    end: event.target.selectionEnd
                  },
                  fieldName: props.name,
                  inputRef: inputRef,
                  open: true
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Dropzone
            formName={formName}
            name={props.name}
          />
        </Grid>
        {props.value
          ? <Grid item xs={12}>
            {props.value.includes('.mp4')
              ? <ReactPlayer
                sx={{
                  height: '50%'
                }}
                url={context.imageUrl(props.value)}
                width='100%'
                controls
              />
              : <img
                src={context.imageUrl(props.value)}
                style={{
                  maxWidth: '100%',
                  maxHeight: 100
                }}
                alt={props.label}
              />}
          </Grid>
          : null}
      </Grid>
    </>
  )
}

export default Component
