import * as React from 'react'
import {
  Box, Button, Card, CardContent, CardHeader, Divider, Grid,
  TextField as MuiTextField
} from '@mui/material'


const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
]


const ProfileDetails = ({ ...rest }) => {
  const [values, setValues] = React.useState({
    firstName: 'Katarina',
    lastName: 'Smith',
    email: 'demo@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  return (
    <form
      autoComplete='off'
      noValidate
      {...rest}
    >
      <Card>
        <CardHeader
          subheader='The information can be edited'
          title='Profile'
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                helperText='Please specify the first name'
                label='First name'
                name='firstName'
                onChange={handleChange}
                required
                value={values.firstName}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                label='Last name'
                name='lastName'
                onChange={handleChange}
                required
                value={values.lastName}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                label='Email Address'
                name='email'
                onChange={handleChange}
                required
                value={values.email}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                label='Phone Number'
                name='phone'
                onChange={handleChange}
                type='number'
                value={values.phone}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                label='Country'
                name='country'
                onChange={handleChange}
                required
                value={values.country}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <MuiTextField
                fullWidth
                label='Select State'
                name='state'
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant='outlined'
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </MuiTextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}
        >
          <Button
            color='primary'
            variant='contained'
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default ProfileDetails
