import * as React from 'react'
import useGlobal from 'store'
import {
  ListAlt,
} from '@mui/icons-material'
import * as defaults from './defaults'

const Component = ({ formName, ...props }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  return (
    <>
      <ListAlt
        title="Calendar View"
        sx={defaults.ButtonStyle}
      />
    </>
  )
}

export default Component
