import * as React from 'react'
import {
    Box,
    Grid,
    Button,
} from '@mui/material'
import useGlobal from 'store'
import context from 'lib/context'
import { useDropzone } from 'react-dropzone'
import { useImageLibrary } from 'store/state'

export const Component = () => {
    const [globalState, globalActions] = useGlobal() // eslint-disable-line
    const [imageLibrary,] = useImageLibrary()
    const { client, campaign, bucket } = imageLibrary

    const onDrop = async files => {
        if (!client) {
            context.alertPopup('No Client was specified. Please set the client first.')
            return
        }
        if (!(files && files.length)) {
            context.alertPopup('No file was specified. Please select a file to upload.')
            return
        }
        if (files && files.length && client) {
            globalActions.api.performApi({
                apiName: 'Events',
                apiPath: '/event',
                apiAction: 'upload-image-file',
                apiPayload: {
                    client,
                    campaign,
                    bucket,
                    file: {
                        file: files[0],
                        data: await context.readFile(files[0])
                    }
                },
                spinner: {
                    content: 'Processing file upload. Please Wait...'
                },
                callback: (store, response) => {
                    // TODO: add the returned image data to the repo
                    // and update the image library
                    // FOR NOW: just reload the image_library table
                    globalActions.control.reloadTable('image_library', { content: 'Reloading. Please wait.' })
                }
            })

        }
    }

    const {
        open,
        isDragActive,
        getRootProps,
        getInputProps,
    } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
    })

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Button variant='contained' color='primary' component='span' onClick={open} fullWidth>
                            {
                                isDragActive ?
                                    'Drag Files' : 'Upload Files'
                            }

                        </Button>
                        <Box sx={{ typography: 'caption', fontStyle: 'italic' }}>
                            Current Upload Bucket: {bucket}
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Component
