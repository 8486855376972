import * as React from 'react'
import context from 'lib/context'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  CardActions,
  Grid,
} from '@mui/material'
import {
  SimpleDropDown as DropDown
} from 'components'
import useGlobal from 'store'
//import { DropzoneAreaBase } from 'material-ui-dropzone'
import { Alert } from '@mui/material'


const Component = ({ ...rest }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { customers = { rows: [] } } = globalState.repo

  const [form, setForm] = React.useState({})
  const [files, setFiles] = React.useState([])
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    if (!globalState.repo.customers) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'query',
        apiPayload: {
          obj: 'customers',
        },
        spinner: {
          content: 'Reading Data. Please Wait...'
        }
      })
    }
  }, [globalActions.api, globalState.repo.customers])

  const clientList = {
    '': 'None - Parse directory from file path'
  }

  customers.rows.forEach((i) => (clientList[i.client_id] = i.description))

  const handleSubmit = () => {
    const localErrors = {}
    if (!form.s3_bucket) {
      localErrors.s3_bucket = 'You must select an S3 bucket.'
    }
    if (files.length === 0) {
      localErrors.files = 'There are no files selected to upload.'
    }
    setErrors(localErrors)
    if (context.isTrue(localErrors)) {
      return
    }
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'admin-upload-files',
      apiPayload: {
        ...form,
        files
      },
      spinner: {
        content: 'Processing files. Please Wait...'
      },
      callback: (store, response) => {
        setErrors({})
        setFiles([])
        setForm({})
      }
    })
  }

  const onFormChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: '' })
  }

  // const onFileChange = (fileObjs) => {
  //   setFiles([...fileObjs])
  //   setErrors({ ...errors, files: '' })
  // }

  // const onFileAdd = (fileObjs) => {
  //   let newFiles = files
  //   fileObjs.forEach((item) => {
  //     newFiles = newFiles.filter(file => file.data !== item.data)
  //   })
  //   setFiles([...newFiles, ...fileObjs])
  //   setErrors({ ...errors, files: '' })
  // }

  // const onFileDelete = (fileObj) => {
  //   setFiles(files.filter(file => file.data !== fileObj.data))
  //   setErrors({ ...errors, files: '' })
  // }

  return (
    <Container maxWidth={false}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
        >
          <Card
            {...rest}
          >
            <CardContent>
              <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
              >
                <Card>
                  <CardHeader
                    title='Bulk Upload'
                    subheader='Internal Bulk Upload'
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >

                      <Grid item xs={6}>
                        <DropDown
                          name='s3_bucket'
                          onChange={onFormChange}
                          required
                          value={form.s3_bucket}
                          values={{
                            'backoffice.resources': 'backoffice.resources',
                            'caringcent.client.files': 'caringcent.client.files',
                            'donate.resources': 'donate.resources'
                          }}
                          label='S3 Bucket'
                          error={errors.s3_bucket}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DropDown
                          name='client_id'
                          value={form.client_id}
                          onChange={onFormChange}
                          values={clientList}
                          label='Client ID'
                          error={errors.client_id}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <DropzoneAreaBase
                            filesLimit={15}
                            showPreviews
                            showPreviewsInDropzone={false}
                            fileObjects={files}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{}}
                            previewText='Selected files'
                            // onChange={onFileChange}
                            onAdd={onFileAdd}
                            onDelete={onFileDelete}
                          /> */}
                        {errors.files &&
                          <Alert severity='error'>{errors.files}</Alert>}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                fullWidth
                variant='text'
                onClick={handleSubmit}
              >
                Upload picture
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Component
