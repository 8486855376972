import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'braintree_customer'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    customer_id: widget({
      label: 'Customer Id'
    }, {
      display: true
    }),
    cc_id: widget({
      label: 'Cc Id'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    street_address: widget({
      label: 'Street Address'
    }, {
      display: true
    }),
    postal_code: widget({
      label: 'Postal Code'
    }, {
      display: true
    }),
    email: widget({
      label: 'Email'
    }, {
      display: true
    }),
    client_id: widget({
      label: 'Client Id'
    }, {
      display: true
    }),
    campaign_id: widget({
      label: 'Campaign Id'
    }, {
      display: true
    }),
    sub_campaign_id: widget({
      label: 'Sub Campaign Id'
    }, {
      display: true
    }),
    record_source: widget({
      label: 'Record Source'
    }, {
      display: true
    }),
    phone_number: widget({
      label: 'Phone Number'
    }, {
      display: true
    }),
    donor_table_updated: widget({
      label: 'Donor Table Updated'
    }, {
      display: true
    }),
    city: widget({
      label: 'City'
    }, {
      display: true
    }),
    state: widget({
      label: 'State'
    }, {
      display: true
    }),
    geopoint: widget({
      label: 'Geopoint'
    }, {
      display: true
    }),
    latitude: widget({
      label: 'Latitude'
    }, {
      display: true
    }),
    longitude: widget({
      label: 'Longitude'
    }, {
      display: true
    })
  }
})



export default data
