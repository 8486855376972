import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'sys_feature_flags'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    applied_system: widget({
      label: 'Apply To System',
      helperText: 'Identifies which system this set of feature flags applies to.'
    }, {
      display: true
    }),
    enable_master_queue_handler: checkbox({
      label: 'Enable Master: Enqueue Handler',
      helperText: '[Permanent].'
    }),
    enable_master_email_sendgrid: checkbox({
      label: 'Enable Master: SendGrid - Allow Email Through Sendgrid',
      helperText: '[Permanent].'
    }),
    enable_auto_send_caringcent_email_receipt_sendgrid: checkbox({
      label: 'Enable Automation: Send CaringCent Email Receipt Through Sendgrid',
      helperText: '[Permanent] This feature sends an email to donors which acknowledges a donation transaction and additionally calls out that the credit transaction will come from CaringCent.'
    }),
    caringcent_email_receipt_days: widget({
      label: 'Email Receipt Days',
      helperText: 'How Many days to wait before sending caringcent email receipt.'
    }),
    email_whitelist: widget({
      label: 'Email Whitelist',
      helperText: 'Who can we send email to? (one per line)',
      multiline: true
    }),
    enable_auto_pull_plaid_transactions: checkbox({
      label: 'Enable Automation: Pull Plaid Transactions',
      helperText: '[Permanent] .'
    }),
    plaid_days_to_pull: widget({
      label: 'Plaid Days',
      helperText: 'How many days back do we pull?'
    }),
    enable_auto_pull_authorize_net_transactions: checkbox({
      label: 'Enable Automation: Pull Authorize.net Transactions',
      helperText: '[Permanent] .'
    }),
    authorize_days_to_pull: widget({
      label: 'Authorize Days',
      helperText: 'How many days back do we pull?'
    }),
    enable_auto_pull_braintree_transactions: checkbox({
      label: 'Enable Automation: Pull Braintree Transactions',
      helperText: '[Permanent] .'
    }),
    braintree_days_to_pull: widget({
      label: 'Braintree Days',
      helperText: 'How many days back do we pull?'
    }),
    enable_master_process_financial_transactions: checkbox({
      label: 'Master: Process Financial/Credit Card Transactions',
      helperText: '[Permanent] .'
    }),
    enable_auto_process_braintree_transactions: checkbox({
      label: 'Enable Automation: Process Braintree Transactions',
      helperText: '[Permanent] .'
    }),
    enable_auto_process_authorize_transactions: checkbox({
      label: 'Enable Automation: Process Authorize.Net Transactions',
      helperText: '[Permanent] .'
    }),
    enable_auto_purge_plaid_accounts: checkbox({
      label: 'Enable Automation: Purge Plaid Accounts',
      helperText: '[Permanent] .'
    }),
    enable_auto_purge_plaid_access_tokens: checkbox({
      label: 'Enable Automation: Purge Plaid Access Tokens',
      helperText: '[Permanent] .'
    }),
    purge_plaid_accounts_days: widget({
      label: 'Purge Plaid Accounts after N Days',
      helperText: 'When an account is suspended, closed, or a plaid account goes into default, purge the account after N days.'
    }),
    enable_auto_purge_syslog: checkbox({
      label: 'Enable Automation: Purge System Log Records',
      helperText: '[Permanent] .'
    }),
    purge_syslog_days: widget({
      label: 'Purge System Log Table after N Days',
      helperText: 'Defaults to 1 day'
    }),

  }
})



export default data
