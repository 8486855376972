import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  ActionButton,
  DirtyForm,
  Image,
  SaveButtons,
  SystemFields,
  TextField
} from 'components'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, true)
  }

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Admin Users'
          >
            Instructions: A user is created in amazon Cognito.
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item>
              <ActionButton
                data-sys_id={fields.sys_id.widget.value}
                data-handler={formName}
                data-table={formName}
                data-action='enable-cognito-account'
                tooltip='Enable Cognito Account'
              >
                Enable Cognito Account
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-sys_id={fields.sys_id.widget.value}
                data-handler={formName}
                data-table={formName}
                data-action='disable-cognito-account'
                tooltip='Disable Cognito Account'
              >
                Disable Cognito Account
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-sys_id={fields.sys_id.widget.value}
                data-handler={formName}
                data-table={formName}
                data-action='update-cognito-user'
                tooltip='Update Cognito Details'
              >
                Update Cognito Details
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-sys_id={fields.sys_id.widget.value}
                data-handler={formName}
                data-table={formName}
                data-action='resend-confirmation-code'
                tooltip='Resend confirmation code'
              >
                Resend Confirmation Code
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-sys_id={fields.sys_id.widget.value}
                data-handler={formName}
                data-table={formName}
                data-action='reset-password'
                tooltip='Autogenerate a new password and send it to the user.'
              >
                Reset User Password
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.UserStatus.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.username.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.email_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.phone_number.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.full_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.profile_description.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Image
            {...fields.profile_image.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
