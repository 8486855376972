import * as React from 'react'
import useGlobal from 'store'
import {
  Grid
} from '@mui/material'

import {
  Checkbox,
  KeyValueField,
  TextField,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields
  console.log(fields)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Checkbox
            {...fields.tipping_enabled.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.tipping_display_text.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.tipping_help_text.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <KeyValueField
            {...fields.tipping_option_values.widget}
            onValidate={(key, value) => {
              // if value does not have a percent at the end or a $ at the beginning, then reject the key,value
              // and display an alert that there is a problem with teh format of the data.
              if (value.indexOf('%') === -1 && value.indexOf('$') === -1) {
                return 'Invalid value. A dollar value must start with $ and a percent value must end with %.'
              }
              return
            }}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.tipping_option_values.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>
    </>
  )
}
