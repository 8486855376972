import * as React from 'react'

import * as Forms from 'views/Forms'
import * as Layouts from 'layouts'
import * as Views from 'views'
import * as Components from 'components'
import * as Docs from 'views/Documentation'

const routes = [
  {
    path: 'app',
    element: <Layouts.ProtectedLayout />,
    children: [
      {
        path: 'blacklist',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.FraudDetectionBlacklist}
          Toolbar={Components.ListViewToolbar}
          title='Fraud Detection Blacklist'
          formName='fraud_detection_blacklist'
        />
      },
      {
        path: 'frauddetection',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.FraudDetectionAttempts}
          Toolbar={Components.ListViewToolbar}
          title='Fraud Detection Attempts'
          formName='fraud_detection_attempts'
        />
      },
      {
        path: 'roundups',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Toolbar={Components.ListViewToolbar}
          title='Roundups'
          formName='roundup'
        />
      },
      {
        path: 'billingfee',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.BillingFeeForm}
          Toolbar={Components.ListViewToolbar}
          title='Billing Fee'
          formName='billing_fee'
        />
      },
      {
        path: 'billingformula',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.BillingFormulaForm}
          Toolbar={Components.ListViewToolbar}
          title='Billing Fee/Formula'
          formName='billing_formula'
        />
      },
      {
        path: 'billingobject',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.BillingObjectForm}
          Toolbar={Components.ListViewToolbar}
          title='Billing Object'
          formName='month_transactions'
        />
      },
      {
        path: 'billingdashboard',
        element: <Views.BillingDashboard />
      },
      {
        path: 'billingperiod',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.BillingPeriodForm}
          Toolbar={Components.ListViewToolbar}
          title='Billing Reports'
          formName='billing_period'
        />
      },
      {
        path: 'billingtranslation',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.BillingTranslationForm}
          Toolbar={Components.ListViewToolbar}
          title='Billing Translation'
          formName='billing_translation'
        />
      },
      {
        path: 'client_campaigns',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.CampaignForm}
          Toolbar={Components.ListViewToolbar}
          title='Campaign'
          formName='campaigns'
        />
      },
      {
        path: 'client_existing',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.ClientExistingForm}
          Toolbar={Components.ListViewToolbar}
          title='Existing Donors'
          formName='existing_donors'
        />
      },
      {
        path: 'clients',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.ClientOrgForm}
          Toolbar={Components.ListViewToolbar}
          title='Clients'
          formName='customers'
        />
      },
      {
        path: 'client_settings',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.ClientSettingsForm}
          Toolbar={Components.ListViewToolbar}
          title='Client Settings'
          formName='client_settings'
        />
      },
      {
        path: 'swipegive_settings',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.SwipeGiveSettingsForm}
          Toolbar={Components.ListViewToolbar}
          title='SwipeGive Settings'
          formName='swipegive_settings'
        />
      },
      {
        path: 'rallygive_settings',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.RallyGiveSettingsForm}
          Toolbar={Components.ListViewToolbar}
          title='RallyGive Settings'
          formName='rallygive_settings'
        />
      },
      {
        path: 'clientaccounts',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.ClientUserForm}
          Toolbar={Components.ListViewToolbar}
          title='Client Users'
          formName='client_users'
        />
      },
      {
        path: 'client_zipcodes',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.ClientZipcodesForm}
          Toolbar={Components.ListViewToolbar}
          title='In Market Zipcodes'
          formName='in_market_zipcodes'
        />
      },
      {
        path: 'emailbuilder',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.EmailTemplateForm}
          Toolbar={Components.ListViewToolbar}
          title='Email Builder'
          formName='email_templates'
        />
      },
      {
        path: 'masteremaillist',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.MasterEmailListForm}
          Toolbar={Components.ListViewToolbar}
          title='Master Email List'
          formName='master_email_list'
        />
      },
      {
        path: 'emailinstance',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.EmailInstanceForm}
          Toolbar={Components.ListViewToolbar}
          title='Email Instance'
          formName='email_instance'
        />
      },
      {
        path: 'SMTPSettings',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.SMTPSettingsForm}
          Toolbar={Components.ListViewToolbar}
          title='SMTP Settings'
          formName='email_settings'
        />
      },
      {
        path: 'formbuilder',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.FormBuilderTabs}
          Toolbar={Components.ListViewToolbar}
          title='Form Builder'
          formName='form_builder_template'
        />
      },
      {
        path: 'rallymetrics',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.RallyMetricsForm}
          Toolbar={Components.ListViewToolbar}
          title='Rally Metrics'
          formName='rally_metrics'
        />
      },
      {
        path: 'paymentprofiles',
        element: <Components.PageView
          List={Components.ListView}
          Toolbar={Components.ListViewToolbar}
          title='Payment Profiles'
          formName='payment_profiles'
        />
      },
      {
        path: 'plaidaccounts',
        element: <Components.PageView
          List={Components.ListView}
          Toolbar={Components.ListViewToolbar}
          title='Plaid Accounts'
          formName='plaid_accounts'
        />
      },
      {
        path: 'transactions/:src_reference_id',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.TransactionForm}
          Toolbar={Components.ListViewToolbar}
          title='Transactions'
          formName='transactions'
        />
      },
      {
        path: 'transactions',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.TransactionForm}
          Toolbar={Components.ListViewToolbar}
          title='Transactions'
          formName='transactions'
        />
      },
      {
        path: 'donation_lineitems',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.DonationLineitemForm}
          Toolbar={Components.ListViewToolbar}
          title='Donation Line Items'
          formName='donation_lineitems'
        />
      },
      {
        path: 'DonorAccounts',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.DonorUserForm}
          Toolbar={Components.ListViewToolbar}
          title='Donor Users'
          formName='donor_users'
        />
      },
      {
        path: 'AdminAccounts',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.AdminUserForm}
          Toolbar={Components.ListViewToolbar}
          title='Admin Users'
          formName='admin_users'
        />
      },
      {
        path: 'disputes',
        element: <Components.PageView
          List={Components.ListView}
          Toolbar={Components.ListViewToolbar}
          title='Disputes'
          formName='braintree_disputes'
        />
      },
      {
        path: 'disbursements',
        element: <Components.PageView
          List={Components.ListView}
          Toolbar={Components.ListViewToolbar}
          title='Disbursements'
          formName='braintree_disbursements'
        />
      },
      {
        path: 'receipts',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.ReceiptLookupForm}
          Toolbar={Components.ListViewToolbar}
          title='Receipt Lookup'
          formName='receipt_lookup'
        />
      },
      {
        path: 'images',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.ImagesForm}
          Toolbar={Components.ListViewToolbar}
          title='Images'
          formName='images'
        />
      },
      {
        path: 'documents',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.DocumentsForm}
          Toolbar={Components.ListViewToolbar}
          title='Documents'
          formName='documents'
        />
      },

      { path: '', element: <Views.DashboardView /> },
      { path: 'swipegivebilling', element: <Views.SwipeGiveBilling /> },
      { path: 'rallygivebilling', element: <Views.RallyGiveBilling /> },
      { path: 'account', element: <Views.AccountView /> },
      { path: 'dashboard', element: <Views.DashboardView /> },
      { path: 'donormap', element: <Views.DonorMapView /> },
      { path: 'settings', element: <Views.SettingsView /> },
      { path: 'upload-existing-donors', element: <Views.UploadExistingDonors /> },
      { path: 'upload-zip-codes', element: <Views.UploadZipCodes /> },
      { path: 'donordashboard', element: <Views.DonorDashboard /> },
      { path: 'donors', element: <Views.DonorDashboard /> },

      {
        path: 'maildashboard',
        element: <Views.MailDashboard />
      },

      { path: '*', element: <Views.NotFoundView /> }
    ]
  },
  {
    path: 'prog',
    element: <Layouts.ProtectedLayout />,
    children: [
      // { path: 'dashboard', element: <Views.ProgDashView /> },
      { path: 'uploadfile', element: <Views.UploadFile /> },
      { path: 'techstack', element: <Views.TechStackView /> },
      { path: 'datastore', element: <Views.DataStoreView /> },
      {
        path: 'ProgramObjects',
        element: <Components.PageView
          List={Components.ListView}
          Form={Views.ProgramObjects}
          Toolbar={Components.ListViewToolbar}
          title='ProgramObjects'
          formName='program_objects'
        />
      },
      { path: 'database', element: <Views.DataBaseView /> },
      //{ path: 'myuserdata', element: <Views.MyUserData /> },
      {
        path: 'systemlog',
        element: <Components.PageView
          List={Components.ListViewServerSide}
          Form={Forms.SysLogForm}
          Toolbar={Components.ListViewToolbar}
          title='System Log'
          formName='sys_log'
        />
      },
      {
        path: 'userdata',
        element: <Components.PageView
          List={Components.ListView}
          Form={Forms.UserDataForm}
          Toolbar={Components.ListViewToolbar}
          title='User Data'
          formName='user_data'
        />
      },
      { path: 'async', element: <Views.ActionCreators /> },
      {
        path: 'featureflags',
        element: <Components.PageView
          List={Components.ListView}
          Form={Views.FeatureFlags}
          Toolbar={Components.ListViewToolbar}
          title='Feature Flags'
          formName='sys_feature_flags'
        />
      },

      { path: 'colors', element: <Views.Colors /> },
      { path: '*', element: <Views.NotFoundView /> }
    ]
  },
  {
    path: 'documentation',
    element: <Layouts.ProtectedDocumentation />,
    children: [
      { path: 'developers', element: <Docs.Developers /> },
      { path: 'administrators', element: <Docs.Administrators /> },
      { path: 'clients', element: <Docs.Clients /> },
      { path: 'donors', element: <Docs.Donors /> },
      { path: 'start_here', element: <Docs.StartHere /> },
      { path: 'git_repos', element: <Docs.GitRepositories /> },
      { path: 'db_info', element: <Docs.DbInfo /> },
      { path: 'home', element: <Docs.Home /> },
      { path: 'contents', element: <Docs.Contents /> },
      { path: 'form_builder', element: <Docs.FormBuilder /> },
      { path: '*', element: <Views.NotFoundView /> }
    ]
  },
  {
    path: '/',
    element: <Layouts.PublicLayout />,
    children: [
      { path: '404', element: <Views.NotFoundView /> },
      { path: 'datastore', element: <Views.DataStoreView /> },
      { path: '/', element: <Views.HomePageView /> },
      { path: '*', element: <Views.NotFoundView /> }
    ]
  }
]

export default routes
