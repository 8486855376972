import context from 'lib/context'
import system from '../system'

import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'donor_users'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    UserStatus: {
      widget: {},
      defaults: {
        error: false,
        fullWidth: true,
        helperText: 'Cognito Status ',
        label: 'UserStatus',
        placeholder: '',
        size: 'small',
        variant: 'outlined',
        value: '',
        displayonly: 'true',

      }
    },
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true,
      searchable: true
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true,
      searchable: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true,
      filter: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }),
    num_transactions: widget({
      label: 'Plaid TX'
    }, {
      display: true
    }),
    default_supported_customer: widget({
      label: 'Supported',
      points_to: (value) => {
        return '/app/clients?sys_id=' + value
      }
    }, {
      display: true,
      searchable: true,
    })
  }
})

data.selected_fields = [
  { units: 1, column: 'sys_id', display: data => <b>{data}</b> },
  { units: 1, column: 'status' },
  { units: 4, column: 'email_address', display: data => <b>{data}</b> },
  { units: 3, column: 'full_name' },
  { units: 3, column: 'default_supported_customer' },
]


export default data
