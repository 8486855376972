import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,
  Box
} from '@mui/material'

import {
  TextField,
  Radio,
  CodeField,
  Checkbox,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Radio
            {...fields.execution_environment.widget}
            onChange={onEvent}
          />
        </Grid>
        {fields.execution_environment.widget.value === 'SQS Event' && <>
          <Grid item xs={2}>
            <Checkbox
              {...fields.in_effect.widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={3}>
            <Radio
              {...fields.trigger.widget}
              onChange={onEvent}
            />
          </Grid>

          {fields.trigger.widget.value === 'Daily' && <>
            <Grid item xs={5}>
              Recurs every <TextField
                {...fields.recurring_period.widget}
                sx={{ width: '100px' }}
                showcount={false}
                onChange={onEvent}
              /> days.

            </Grid>
          </>}
          {fields.trigger.widget.value === 'Hourly' && <>
            <Grid item xs={5}>
              Recurs every <TextField
                {...fields.recurring_period.widget}
                sx={{ width: '100px' }}
                showcount={false}
                onChange={onEvent}
              /> hours.
            </Grid>
          </>}
          {fields.trigger.widget.value === 'Weekly' && <>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  Recurs every <TextField
                    {...fields.recurring_period.widget}
                    sx={{ width: '100px' }}
                    showcount={false}
                    onChange={onEvent}
                  /> weeks on
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.monday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.tuesday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.wednesday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.thursday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.friday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.saturday.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    {...fields.sunday.widget}
                    onChange={onEvent}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>}
          {fields.trigger.widget.value === 'Monthly' && <>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  Recurs every <TextField
                    {...fields.recurring_period.widget}
                    sx={{ width: '100px' }}
                    showcount={false}
                    onChange={onEvent}
                  /> Months on
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    {...fields.first_day.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    {...fields.fifteenth_day.widget}
                    onChange={onEvent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    {...fields.last_day.widget}
                    onChange={onEvent}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>}
        </>}
        <Grid item xs={6}>
          <TextField
            {...fields.program_handle.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.tags.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.num_chars.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.num_lines.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.version.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.last_saved_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 20, pb: 5 }} >
            Program Description
          </Box>
          <CodeField
            {...fields.program_description.widget}
            onChange={onEvent}
            height='30vh'
            defaultLanguage='text'
          />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </>
  )
}
