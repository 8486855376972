import * as React from 'react'
import useGlobal from 'store'
import {
  Grid
} from '@mui/material'

import context from 'lib/context'
import {
  TextField,
} from 'components'

import { useImageLibrary } from 'store/state'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const textAreaRef = React.useRef()
  const [, setImageLibrary] = useImageLibrary()
  const {
    [formName]: form,
    image_library
  } = globalState.forms
  const fields = form.fields

  React.useEffect(() => {
    let filter, client = fields.client.widget.value
    if (context.isDefined(image_library)) {
      if (context.isNotEmpty(client)) {
        setImageLibrary.setPartialState({ client })
        if (client && client[0] === '@') {
          filter = {
            client,
            '%client': '@@'
          }
        } else {
          filter = {
            client: '@@',
            '%client': client
          }
        }
        globalActions.control.mergeState({
          forms: {
            image_library: {
              queryParams: { filter }
            }
          }
        })
        globalActions.control.publish('callAfter', {
          func: globalActions.control.reloadTable,
          params: ['image_library', { content: 'Reloading. Please wait...' }]
        })
      }
    }
    globalActions.control.deleteStateKey(['repo', 'image_library'])
    return () => {
      setImageLibrary.setPartialState({ client: '' })
      globalActions.control.deleteStateKey(['repo', 'image_library'])
    }
  }, [
    fields.client.widget.value,
    formName,
    globalActions.control,
    image_library,
    setImageLibrary
  ])


  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  return (
    <>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            {...fields.receipt_from_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.receipt_subject.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.receipt_body_text.widget}
            inputRef={textAreaRef}
            onChange={onEvent}
            showImageIcon
            onKeyDown={(event) => {
              if (event.code === 'KeyI' && event.ctrlKey) {
                event.stopPropagation()
                event.preventDefault()

                setImageLibrary.setPartialState({
                  formName,
                  insertFormat: 'img',
                  selection: {
                    start: event.target.selectionStart,
                    end: event.target.selectionEnd
                  },
                  fieldName: fields.receipt_body_text.widget.name,
                  inputRef: textAreaRef,
                  open: true
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div dangerouslySetInnerHTML={{ __html: context.swapFields(fields.receipt_body_text.widget.value) }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.mail_bcc.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>

    </>
  )
}
