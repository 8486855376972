import * as React from 'react'
import {
  TextField as MuiTextField,
  Grid
} from '@mui/material'
import _ from 'underscore'
import context from 'lib/context'
import { Alert } from '@mui/material'

const _onChange = _.debounce((event, func) => {
  func(event)
}, 1000)

export default function Component({ formName, onChange, value, ...props }) {
  const [_value, _setValue] = React.useState('')

  value = value || ''

  React.useEffect(() => {
    if (value !== _value) {
      _setValue(value)
    }
  }, [value]) // eslint-disable-line

  const onEvent = (event) => {
    let value = event.target.value.trim()
    if (value && value[0] !== '#') {
      value = '#' + value
    }
    event.target.value = value
    _setValue(event.target.value)
    _onChange(event, onChange)
  }

  return (
    <>
      <Grid container widget='Color'>
        <Grid item xs={12}>
          <span error-id={props.name} />
        </Grid>
        <Grid item xs={8}>
          <MuiTextField
            {...props}
            type='text'
            error={Boolean(props.error)}
            value={_value}
            onChange={onEvent}
            InputProps={{
              sx: context.inputColors
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MuiTextField
            {...props}
            label=''
            error={Boolean(props.error)}
            value={_value || '#000000'}
            onChange={onEvent}
            color={props.color}
            InputProps={{
              sx: context.inputColors
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {props.error && <Alert severity='error'>{props.error}</Alert>}
        </Grid>
      </Grid>
    </>
  )
}
