import * as React from 'react'
import useGlobal from 'store'
import { Grid } from '@mui/material'


import {
  TextField,
  KeyValueField,
  Checkbox,
  Image,
  Radio
} from 'components'

function Donation({ index, formName, ...props }) {

  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>

      <fieldset sx={{
        p: 20,
        borderRadius: 3,
        color: 'rgba(0, 0, 0, 0.4)',
        borderColor: 'rgba(0, 0, 0, 0.1)'
      }}>
        <legend>Donation {index} </legend>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_header'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_html'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Image
              {...fields['donation_' + index + '_image'].widget}
              onChange={onEvent}
              formName={formName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_label'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_secondarytext'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_helpertext'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <Radio
              {...fields['donation_' + index + '_orientation'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValueField
              {...fields['donation_' + index + '_values'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_values'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...fields['donation_' + index + '_defaultvalue'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Checkbox
              {...fields['donation_' + index + '_show_other'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              {...fields['donation_' + index + '_dropdown_label'].widget}
              onChange={onEvent}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              {...fields['donation_' + index + '_dropdown'].widget}
              onChange={onEvent}
            />
          </Grid>
        </Grid>
      </fieldset>
    </>
  )
}

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TextField
            {...fields.donation_section_instructions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Donation index={1} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={2} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={3} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={4} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={5} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={6} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={7} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={8} formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <Donation index={9} formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
