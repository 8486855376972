import PubSub from 'pubsub-js'
import context from 'lib/context'
import * as meta from 'store/meta-data'
/**
 * Load a form if not already loaded.
 * @param store:
 * @param metaName: A PUbSub topic
 * @param formId:
 */
export const onLoad = (store, metaName, formId) => {
  // We have already initialized this form. OK to return.
  if (formId in store.forms) { return }
  // Nothing to initialize. Return error message to user.
  if (!(metaName in meta)) { }
}
/**
 * Async publish form events
 * automatically create subscriptions on first use, based on initialized variable.
 * @param store
 * @param topic
 * @param message
 */
let initialized = false
export const publish = (store, topic, message) => {
  if (initialized === false) {
    PubSub.subscribe('formEvent', function (topic, message) {
      const { formId, event } = message
      onFormEvent(store, formId, event)
    })
    PubSub.subscribe('setForm', function (topic, message) {
      const { action } = message
      setForm(store, action)
    })
    PubSub.subscribe('callAfter', function (topic, message) {
      const { func, params } = message
      func(...params)
    })
    initialized = true
  }
  PubSub.publish(topic, message)
}

/**
 * Async call to back end to save form data
 */
export const saveForm = async (store, { formId, schema, mergeData, sendConfirmation }) => {
  const { fields, original, table } = store.state.forms[formId]
  let diff = {}
  const form = {}
  const errors = {}
  for (const key in fields) {
    form[key] = fields[key].widget.value
    if (key.search('sys_') !== -1) continue
    if (fields[key].widget.value !== original[key]) {
      diff[key] = fields[key].widget.value
    }
  }
  if (context.isObject(mergeData) && !context.isEmpty(mergeData)) {
    diff = context.merge(diff, mergeData)
  }
  if (schema) {
    try {
      await schema.validate(form, { abortEarly: false })
    } catch (err) {
      err.inner.forEach((element) => {
        errors[element.path] = {
          widget: {
            error: true,
            helperText: element.message
          }
        }
      })
      store.actions.control.mergeState({
        forms: {
          [formId]: { fields: errors }
        }
      })
      context.log('VALIDATION FAILED')
      context.log(errors)
      return false
    }
  } // End IF SCHEMA

  if (Object.keys(diff).length) {
    diff.sys_id = original.sys_id
    // The followin code masks sensitive data for display
    // Any field that ends with "_display" will get masked.
    for (const key in diff) {
      if (key.search('_display') !== -1) {
        let k = key.split('_')
        k.pop()
        k = k.join('_')
        const _ = diff[k] = diff[key]
        diff[key] = diff[key].replace(/\d/g, '*')
        if (_.length > 8) {
          const num = Math.round(_.length / 4)
          diff[key] = diff[key].substr(0, _.length - num) + _.substr(-num, num)
        }
      }
    }
    const apiPayload = {
      tableName: table,
      object: diff
    }

    await store.actions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'write-object',
      apiPayload: apiPayload,
      spinner: {
        content: 'Writing Data. Please Wait...'
      },
      stateReducer: (store, result) => {
        return {
          forms: {
            [formId]: {
              dirty: false,
              object: diff,
              shadow: diff
            }
          }
        }
      }
    }) // End PerformAPI
  }// End if DIFF

  return true
}

/**
 * Initial load of form if it does not exist. Calls reset form.
 */
export const initForm = (store, metaName, formId, sys_id) => {
  store.setState({
    forms: {
      ...store.state.forms,
      [formId]: resetForm(store, metaName, sys_id)
    }
  })
}

export const resetForm = (store, metaName, sys_id) => {
  let form
  if (metaName in meta) {
    form = context.copy(meta[metaName].data || {})
  } else {
    form = {}
  }
  form.dirty = false
  form.original = {}
  form.shadow = {}
  const { table, fields } = form
  for (const key in fields) {
    const value = fields[key]
    value.widget = {
      // I put them in this order on purpose.
      // We want the ability to initialize the widget
      // to a non-default value, set to default otherwise.
      name: key,
      label: key,
      ...value.defaults,
      ...value.widget
    }
  }
  const select = []
  const headers = []
  for (const key in fields) {
    if ('dt' in fields[key]) {
      headers.push({
        ...{
          name: fields[key].widget.name,
          label: fields[key].widget.label
        },
        options: {
          ...fields[key].dt
        }
      })
      select.push(fields[key].widget.column || fields[key].widget.id)
    }
  }
  form.query.params.columns = select
  form.query.headers = headers

  if ('functions' in meta[metaName]) {
    form = {
      ...form,
      ...meta[metaName].functions
    }
  }

  // get object based on sys_id and tablename specified in  form object
  if (!sys_id) {
    throw new Error('sys_id is required to initialize this form.')
  }
  sys_id = sys_id.toString()
  let object
  if (table in store.state.repository) {
    if (sys_id in store.state.repository[table]) {
      object = store.state.repository[table][sys_id]
    }
  }
  // IF WE DONT HAVE THE OBJECT IN REPOSITORY, WE OUGHT TO PULL IT HERE. WE CAN MAKE IT
  // A SYNCHRONOUS CALL FOR NOW AND ASYNC LATER IF NEEDED.

  if (context.isObject(object)) {
    // We are going to make a reference to the object in "store.state.repository" here.  Originally we copied the object
    // so the state remained consistent while we perform our form operations.
    // however I think we need to make a reference so it updates from the back end if the object
    // changes we will know.
    // We are following a unidirection data flow model here, so updatese come from teh back end only.
    // WE MUST NEVER MODIFY "ORIGINAL" IN OUR APPLICATION.
    // WE ALWAYS UPDATE "SHADOW" WHICH IS OUR LOCALLY CACHED AND MODIFIED VERSION
    // form.original = context.copy(object)
    form.original = object
    form.shadow = context.copy(object)
    for (const key in object) {
      if (key in fields) {
        fields[key].widget.value = object[key]
      }
    }
    form.dirty = false
  } else {
    throw new Error('Object ' + table + '.' + sys_id + ' not found in state.repository')
  }
  return form
}

export const onFormEvent = async (store, formId, event, asynchronous = true) => {
  let target = event.target
  while (target && !target.hasAttribute('type')) {
    target = target.parentNode
  }
  if (!target) {
    return
  }

  let { name, value, checked, type } = target
  if (event.type === 'keydown') {
    if (event.key === 'Enter') {
      if (name === 'sys_id') {
        publish(store, 'setForm', {
          action: {
            formId,
            type: 'button',
            name: '@load',
            value
          }
        })
      }
    }
    return
  }

  if (type === 'checkbox') {
    value = checked
  }
  if (asynchronous) {
    publish(store, 'setForm', {
      action: {
        formId,
        type,
        name,
        value
      }
    })
  } else {
    setForm(store, {
      formId,
      type,
      name,
      value
    })
  }
}

export const setForm = async (store, action) => {
  const newState = setFormReducer(store, action)
  if (typeof newState === 'object' && newState !== null) {
    store.setState(newState)
  }
}

const setFormReducer = (store, action) => {
  const { forms } = store.state
  let form = forms[action.formId]
  const { fields, shadow, original } = form
  const widget = fields[action.name] && fields[action.name].widget
  const defaults = fields[action.name] && fields[action.name].defaults

  switch (action.type) {
    case 'text':
    case 'date':
    case 'radio':
    case 'checkbox':
      if (fields.sys_id.widget.value) {
        widget.value = action.value
        widget.error = false
        widget.helperText = defaults.helperText
        if (action.value !== original[action.name]) {
          form.dirty = true
        }
        shadow[action.name] = action.value
      }
      break
    case 'setError':
      widget.error = true
      widget.helperText = action.value
      break
    case 'clearError':
      widget.error = false
      widget.helperText = defaults.helperText
      break
    case 'button':
      switch (action.name) {
        case '@clear':
          form = resetForm(action.formId)
          break
        case '@new':
          form = resetForm(action.formId)
          form.fields.sys_id.widget.value = '@new'
          form.fields.sys_id.widget.variant = 'filled'
          form.fields.sys_id.widget.InputProps = {
            readOnly: true
          }
          break
        case '@delete':
          if (fields.sys_id.widget.value) {
            const object = {}
            for (const key in fields) {
              object[key] = fields[key].widget.value
            }
            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'delete-object',
              apiPayload: {
                user: store.state.controls.user.attributes.email,
                tableName: form.table,
                object
              },
              spinner: {
                content: 'Deleting Data. Please Wait...'
              },
              callback: (store, response) => {
                if ('afterDelete' in form.functions) {
                  publish(store, 'callAfter', { func: form.functions.afterDelete, params: [store, form, response] })
                }
                publish(store, 'setForm', { action: { formId: action.formId, type: 'button', name: '@clear' } })
              }
            })
          }
          break
        case '@save':
        case '@save-clear':
        case '@save-keep':
        case '@save-new':
          if (fields.sys_id.widget.value) {
            const object = {}
            for (const key in fields) {
              object[key] = fields[key].widget.value
            }
            if (action.name === '@save-new') {
              object.sys_id = '@new'
            }
            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'write-object',
              apiPayload: {
                user: store.state.controls.user.attributes.email,
                tableName: form.table,
                object
              },
              spinner: {
                content: 'Writing Data. Please Wait...'
              },
              callback: (store, response) => {
                if ('afterSave' in form.functions) {
                  publish(store, 'callAfter', { func: form.functions.afterSave, params: [store, form, response] })
                }
                if (action.name === '@save-clear') {
                  publish(store, 'setForm', { action: { formId: action.formId, type: 'button', name: '@clear' } })
                }
              },
              stateReducer: (store, response) => {
                if ('object' in response) {
                  // This is a special case that modifies the forms object
                  // which is a complex structure
                  const object = response.object
                  for (const key in object) {
                    if (key in fields) {
                      fields[key].widget.value = object[key]
                    }
                  }
                  form.dirty = false
                  return {
                    forms: {
                      ...forms,
                      [action.formId]: form
                    }
                  }
                }
                // Otherwise return the response object for merging
                // into the global state.
                return response
              }
            })
          }
          break
        case '@load':
          const object = {}
          if (action.value) {
            object.sys_id = action.value
          } else if (form.fields.sys_id.widget.value) {
            object.sys_id = form.fields.sys_id.widget.value
          } else {
            return
          }
          store.actions.api.performApi({
            apiName: 'Events',
            apiPath: '/data',
            apiAction: 'get-object',
            apiPayload: {
              user: store.state.controls.user.attributes.email,
              tableName: form.table,
              object
            },
            spinner: {
              content: 'Reading Data. Please Wait...'
            },
            stateReducer: (store, response) => {
              const object = response.object
              for (const key in object) {
                if (key in fields) {
                  fields[key].widget.value = object[key]
                }
              }
              form.fields.sys_id.widget.variant = 'filled'
              form.fields.sys_id.widget.InputProps = {
                readOnly: true
              }
              form.dirty = false
              return {
                forms: {
                  ...forms,
                  [action.formId]: form
                }
              }
            }
          })

          break
        default:
          if (fields.sys_id.widget.value) {
            widget.value = action.value
            widget.error = false
            widget.selected = Boolean(action.value)
            widget.helperText = defaults.helperText
            if (action.value !== original[action.name]) {
              form.dirty = true
            }
            shadow[action.name] = action.value
          }
          break
      }
      break
    default:
      break
  }

  return {
    forms: {
      ...forms,
      [action.formId]: form
    }
  }
}

/**
 * Initial load of form if it does not exist. Calls reset form.
 */
