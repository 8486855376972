import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  ActionButton,
  DirtyForm,
  SaveButtons,
  SystemFields,
  TextField,
} from 'components'
import context from 'lib/context'


const BillingPeriodForm = ({ formName, ...props }) => {
  const [emailAddress, setEmailAddress] = React.useState('')
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { fields, shadow } = globalState.forms[formName]

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Billing Reports'
          >
            Instructions: A billing report defines the date parameters to generate a billing report
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item>
              <ActionButton
                disabled={!!!fields.sys_id.widget.value}
                data-handler='reports'
                data-action='generate-swipe-give-report'
                data-payload={context.to_json({
                  email_address: emailAddress,
                  ...shadow
                })}
                tooltip='Download the preliminary billing report for this period'
              >
                Prelim Billing Report
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                disabled={!!!fields.sys_id.widget.value}
                data-handler='reports'
                data-action='generate-internal-billing-report'
                data-payload={context.to_json({
                  email_address: emailAddress,
                  ...shadow
                })}
                tooltip='Download the internal report for this billing period. The internal report contains extra information that the client report does not contain'
              >
                Internal Billing Report
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                disabled={!!!fields.sys_id.widget.value}
                data-handler='reports'
                data-action='generate-non-profit-report'
                data-payload={context.to_json({
                  email_address: emailAddress,
                  ...shadow
                })}
                tooltip='Download the client billing report for this period'
              >
                NonProfit Billing Report
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                disabled={!!!fields.sys_id.widget.value}
                data-handler='reports'
                data-action='create-client-billing-reports'
                tooltip='Generate client billing report documents and post to client portal.'
              >
                Create Client Billing Reports To S3
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="standard-multiline-flexible"
                label="Send To Email"
                value={emailAddress}
                onChange={(event) => { setEmailAddress(event.target.value) }}
                variant="standard"
                fullWidth={true}
              />
            </Grid>
            <Grid item>
              <ActionButton
                disabled={!!!fields.sys_id.widget.value}
                data-handler='reports'
                data-action='email-billing-reports'
                data-payload={context.to_json({
                  email_address: emailAddress,
                  ...shadow
                })}
                tooltip='Send Billing Reports To Email'
                sx={{ marginTop: 2 }}
              >
                Email Billing Report
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.effective_month.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.effective_year.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.bt_start_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.bt_end_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.an_start_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.an_end_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}

export default BillingPeriodForm
