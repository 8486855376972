import * as React from 'react'
import useGlobal from 'store'
import Header from 'components/Header'
import ToolBar from 'components/ToolBar'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'

import {
  Box,
  Container,
  Typography,
} from '@mui/material'

const isObject = (value) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length)
}
const isArray = (value) => {
  return !!(value && typeof value === 'object' && Array.isArray(value) && value.length)
}
let idx = 0
const Component = () => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const top = (input) => {
    return {
      id: 'root',
      name: 'globalState',
      children: nodes(input)
    }
  }

  const nodes = (input) => {
    const result = []
    for (const key in input) {
      const value = input[key]
      const data = {
        id: `${idx++}`,
        name: `${key}`,
      }
      if (isObject(value) || isArray(value)) {
        data.children = nodes(value)
      } else {
        data.name = `${key}:  ${JSON.stringify(value)}`
      }
      result.push(data)
    }
    return result
  }

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )

  return (
    <>
      <Header label='Datastore' />
      <ToolBar
        inspector
        formbuilder
      />
      <Container sx={{ pt: 3 }}      >
        <Box sx={{
          pb: 3,
          pt: 3
        }}>
          <Typography>
            This is a debugging tool for programmers. It will only shows up on the
            development system and will not be visible on staging and production.
          </Typography>
        </Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
          {renderTree(top(globalState))}
        </TreeView>
      </Container>
    </>
  )
}

export default Component
