import * as React from 'react'

const Logo = (props) => {
  return (
    <img
      alt='Logo'
      //src='https://s3.amazonaws.com/donate.resources/cc/CaringCentLogo_College-Atheltics_logo.webp'
      src='https://s3.amazonaws.com/donate.resources/cc/img/CaringCent_DonateStrip_KO_FIN.png'
      {...props}
    />
  )
}

export default Logo
