import * as React from 'react'
import { Alert, TextField as MuiTextField, Box } from '@mui/material'
import {
  QuestionMark,
  MoreInfo
} from 'components'
import context from 'lib/context'

export default function Component({
  formName,
  value,
  showcount,
  moreInfo,
  ...props }) {

  if (value === null) { value = '' }
  const [_value, _setValue] = React.useState('')
  const [_count, _setCount] = React.useState(value ? value.length : 0)

  React.useEffect(() => {
    if (value !== _value) {
      _setValue(value)
      _setCount(value ? value.length : 0)
    }
  }, [value]) // eslint-disable-line


  return (
    <>
      <Box error-id={props.name}
        sx={{
          position: 'relative',
        }}>
        <MuiTextField
          widget='TextField'
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            sx: {
              ...context.inputColors,
              height: '100%'
            }
          }}
          {...props}
          error={Boolean(props.error)}
          value={_value}
        />
        {props.questionmark && <QuestionMark style={{
          position: 'absolute',
          top: -15,
          right: -15,
        }}>{props.questionmark}</QuestionMark>}
        {moreInfo && <MoreInfo>
          {moreInfo}
        </MoreInfo>}
        {showcount && showcount === 'true' && <Box sx={{
          position: 'absolute',
          top: 10,
          right: 3,
          fontSize: '80%',
          color: 'gray'
        }}>{_count}</Box>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}

      </Box>
    </>
  )
}
