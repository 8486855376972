import * as React from 'react'
import {
  Grid,
  TextField as MuiTextField
} from '@mui/material'
import {
  ActionButton
} from 'components'

export default function Component() {
  const [action, setAction] = React.useState('')
  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='billing'
            data-action='pull-authorize-transactions'
            tooltip='Trigger asyncronous pull of authorize.net transactions'
          >
            Pull Authorize
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>
        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='billing'
            data-action='pull-braintree-transactions'
            tooltip='Trigger asyncronous pull of braintree transactions'
          >
            Pull Braintree
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='billing'
            data-action='get-plaid-transactions'
            tooltip='Trigger asyncronous pull of plaid transactions'
          >
            Get Plaid For All Users
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='testing'
            data-action='update-dashboard-data'
          >
            Update Dashboard
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='testing'
            data-action='load-zipcode-data'
          >
            Load Zipcodes
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='transactions'
            data-action='normalize-client-names'
            tooltip='Normalize names and campaigns'
          >
            Normalize Client Names
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='testing'
            data-action='generate-toast'
            tooltip='Generate Toast'
          >
            Generate Toast
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>

        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='testing'
            data-action='enqueue-send-email'
            tooltip='Enqueue Send Email'
          >
            Enqueue Send Email
          </ActionButton>
        </Grid>

        <Grid item xs={8} md={10}>
          Instructions
        </Grid>
        <Grid item xs={4} md={2}>
          <ActionButton
            data-handler='billing'
            data-action='update-billing-report-table'
            tooltip='Update Billing Report Table'
          >
            Update Billing Report Table
          </ActionButton>
        </Grid>
        <Grid item xs={8} md={10}>
          Instructions
        </Grid>
        <Grid item xs={4}>
          <ActionButton
            data-handler='database'
            data-action='enqueue-job'
            data-request={action}
            beforeCall={(event, extraParams) => {
              if (action === '') {
                alert('Please enter an action')
                return false
              }
            }}
            apiCallback={(store, response) => setAction('')}
          >
            Enqueue Action
          </ActionButton>
        </Grid>
        <Grid item xs={4}>
          <MuiTextField
            //disabled={context.isFalse(fields.sys_id.widget.value)}
            id="outlined-basic"
            label="Action"
            variant="outlined"
            value={action}
            size='small'
            onChange={e => setAction(e.target.value)}
          />
        </Grid>
      </Grid>
    </div >
  )
}
