import * as React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useGlobal from 'store'
import {
  Box,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion
} from '@mui/material'
import {
  ActionButton,
} from 'components'

export default function SelectedRowActions({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]

  const {
    config = {}
  } = globalState.repo[formName] || { undefined }
  const {
    environment
  } = globalState.controls
  if (!config.allRowsSelected || !config.allRowsSelected.length) { return null }

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              System bulk-edit actions for {formName}
            </Typography>
            <Typography variant='caption' sx={{ color: '#E5E4E2', ml: 4 }}>
              (Click to Expand)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item>
                <ActionButton
                  data-handler='database'
                  data-action='push-to-alternate'
                  data-table={form.table}
                  data-rows={config.selected.map(entry => entry.sys_id)}
                  tooltip='Push these objects to alternate system'
                  data-title='Move objects to other system?'
                  data-warning={`
    IF YOU PUSH AN OBJECT TO ANOTHER SYSTEM IT
    WILL OVERWRITE THE OBJECT WITH THE SAME SYSTEM ID IN THE OTHER SYSTEM.
    DOUBLE CHECK THE OTHER SYSTEM FOR THIS ID TO MAKE SURE YOU
    ARE NOT MAKING A MISTAKE!
    `}
                >
                  Push to {environment === 'production' ? 'demo' : 'production'}
                </ActionButton>
              </Grid>
              <Grid item>
                <ActionButton
                  data-handler='database'
                  data-action='delete-rows'
                  data-table={form.table}
                  data-rows={config.selected.map(entry => entry.sys_id)}
                  tooltip='Delete rows from the database'
                  data-title='Delete rows from the database?'
                  data-warning={'Are you sure you want to delete these rows from the database?'}
                >
                  Delete Rows
                </ActionButton>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
