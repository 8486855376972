import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'campaigns'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client: widget({
      label: 'Client',
      query: {
        obj: 'customers',
        datatable: 'customers',
        params: {
          orderby: 'sys_id desc'
        }
      }
    }, {
      display: true
    }),
    formula: widget({
      label: 'Formula'
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    goal_donors: widget({
      label: 'Goal Donors'
    }),
    goal_dollars: widget({
      label: 'Goal Dollars'
    }),
    external_donors: widget({
      label: 'External Donors'
    }),
    external_dollars: widget({
      label: 'External Dollars'
    }),
    page_title: widget({
      label: 'Page Title'
    })
  }
})



export default data
