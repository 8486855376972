import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'daily_campaign_totals'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    source: widget({
      label: 'Source'
    }, {
      display: true
    }),
    client: widget({
      label: 'Client'
    }, {
      display: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    sum: widget({
      label: 'Sum'
    }, {
      display: true
    })
  }
})



export default data
