import { Typography, Checkbox } from '@mui/material'
import context from 'lib/context'

const defaultSx = {}

const readOnlySx = {
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: 'secondary.light',
    }
  },
  '& .MuiOutlinedInput-root': {
    '& > fieldset': {
      borderColor: 'secondary.light',
    }
  }
}


export const defaults = {
  error: false,
  fullWidth: true,
  helperText: '',
  label: 'Change Me',
  size: 'small',
  value: '',
  variant: 'outlined',
  m: 'dense',
  sx: defaultSx,
}
export const dtdefaults = {
  sort: true,
  filter: false,
  display: false,
  searchable: false,
  customBodyRender: (value, tableMeta, updateValue) => {
    if (!value) return
    return <>
      <Typography noWrap align='center'>
        {value}
      </Typography>
    </>
  },
  // customHeadRender: (columnMeta, updateDirection) => (
  //   <th key={1} onClick={() => updateDirection(2)} style={{ cursor: 'pointer' }}>
  //     {columnMeta.label}
  //   </th>
  // )
}

export const widget = (opts = {}, dtopts = {}, no_query = false) => {
  if (dtopts.align) {
    if (dtopts.customBodyRender) {
      console.warn('Widget: Ingoring `dtopts.align` because `customBodyRender` is already defined')
    } else {
      dtopts.customBodyRender = (value, tableMeta, updateValue) => {
        if (!value) return
        return <>
          <Typography noWrap align={dtopts.align} title={dtopts.title}>
            {value}
          </Typography>
        </>
      }
    }
  }

  const result = {
    widget: {},
    defaults: {
      ...defaults,
      sx: opts?.InputProps?.readOnly && readOnlySx,
      showcount: 'true',
      ...opts
    }
  }
  if (!no_query) {
    result.dt = {
      options: {
        ...dtdefaults,
        ...dtopts
      }
    }
  }
  return result
}

export const checkbox = (opts = {}, dtopts = {}) => {
  return widget({
    value: false,
    checked: false,
    selected: false,
    ...opts,
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap align={dtopts.align || 'center'}>
          <Checkbox checked={value} />
        </Typography>
      )
    },
    ...dtopts
  })
}

export const color = (opts = {}, dtopts = {}) => {
  return widget({
    type: 'color',
    ...opts
  }, {
    ...dtopts
  })
}

export const money = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap align={dtopts.align || 'center'}>
          ${context.formatMoney(value)}
        </Typography>
      )
    },
    ...dtopts,
  })
}


export const radio = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    ...dtopts
  })
}


export const code = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap align={dtopts.align || 'left'} sx={{ fontFamily: 'monospace' }}>
          {value}
        </Typography>
      )
    },
    ...dtopts,
  })
}


export const time = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap>
          {new Date(value + ' UTC').toLocaleTimeString()}
        </Typography>
      )
    },
    ...dtopts,
  })
}



export const date = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap>
          {new Date(value + ' UTC').toLocaleDateString()}
        </Typography>
      )
    },
    ...dtopts,
  })
}



export const datetime = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      const _datetime = new Date(value + ' UTC').toLocaleString().replaceAll(' ', '').replace(',', ' ')
      const _date = _datetime.split(' ')[0]
      const _time = _datetime.split(' ')[1]
      return (
        <Typography>
          {_date}<br />{_time}
        </Typography>
      )
    },
    ...dtopts,
  })
}

export const rot13 = (opts = {}, dtopts = {}) => {
  return widget({
    ...opts
  }, {
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Typography noWrap>
          {context.rot13(value)}
        </Typography>
      )
    },
    ...dtopts
  })
}