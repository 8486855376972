import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SystemFields,
  SaveButtons,
  TextField,
  ActionButton,
} from 'components'
import context from 'lib/context'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }


  context.useInterval(() => {
    globalActions.control.reloadTable(formName)
  }, 20000)


  return (
    <>
      <ActionButton
        data-handler='database'
        data-action='clear-sys-log'
      >
        Clear SysLog
      </ActionButton>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='System Logs'
          >
            Instructions: Edit Transactions
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.app_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.function.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.source_ip.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.message.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
