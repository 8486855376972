import * as React from 'react'
import useGlobal from 'store'
import {
  ViewList,
} from '@mui/icons-material'

import * as defaults from './defaults'

const Component = ({ formName }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  return (
    <>
      <ViewList
        title="List View"
        sx={defaults.ButtonStyle}
      />
    </>
  )
}

export default Component
