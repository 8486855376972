import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'dashboard'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    total_donation_dollars: widget({
      label: 'Total Donation Dollars'
    }, {
      display: true
    }),
    total_donation_count: widget({
      label: 'Total Donation Count'
    }, {
      display: true
    }),
    total_failed_dollars: widget({
      label: 'Total Failed Dollars'
    }, {
      display: true
    }),
    total_failed_count: widget({
      label: 'Total Failed Count'
    }, {
      display: true
    }),
    num_active_swipe_users: widget({
      label: 'Num Active Swipe Users'
    }, {
      display: true
    })
  }
})



export default data
