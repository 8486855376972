import * as React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useGlobal from 'store'

export default function Component() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { notifications } = globalState

  const navigate = useNavigate()

  React.useEffect(() => {
    if (notifications.maintenance) {
      navigate('/notifications/maintenance')
    }
  })
  React.useEffect(() => {
    globalActions.control.subscribe()
    return globalActions.control.unsubscribe
  }, [globalActions.control])

  return (
    <>
      <Outlet />
    </>
  )
}