import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'email_templates'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    event: widget({
      label: 'Event'
    }, {
      display: true,
      searchable: true
    }),
    num_lines: widget({
      label: 'Num Lines'
    }),
    version: widget({
      label: 'Version'
    }, {
      display: true
    }),
    num_chars: widget({
      label: 'Num Chars'
    }),
    last_saved: widget({
      label: 'Last Saved'
    }, {
      display: true
    }),
    subject: widget({
      label: 'Subject'
    }, {
      display: true,
      searchable: true
    }),
    html_template: widget({
      label: 'Html Template',
      multiline: true,
    }, {
      display: true,
      searchable: true
    }),
    supported_tags: widget({
      label: 'Supported Tags',
      multiline: true
    }, {
      display: true,
      searchable: true
    }),
    text_template: widget({
      label: 'Text Template',
      multiline: true
    }, {
      display: true,
      searchable: true
    })
  }
})



export default data
