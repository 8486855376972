import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'plaid_link_events'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    environment: widget({
      label: 'Environment'
    }, {
      display: true
    }),
    timestamp: widget({
      label: 'Timestamp'
    }, {
      display: true
    }),
    event: widget({
      label: 'Event'
    }, {
      display: true
    }),
    exit_status: widget({
      label: 'Exit Status'
    }, {
      display: true
    }),
    account_id: widget({
      label: 'Account Id'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    link_session_id: widget({
      label: 'Link Session Id'
    }, {
      display: true
    }),
    institution_search_query: widget({
      label: 'Institution Search Query'
    }, {
      display: true
    }),
    institution_id: widget({
      label: 'Institution Id'
    }, {
      display: true
    }),
    institution_name: widget({
      label: 'Institution Name'
    }, {
      display: true
    }),
    institution_type: widget({
      label: 'Institution Type'
    }, {
      display: true
    }),
    view_name: widget({
      label: 'View Name'
    }, {
      display: true
    }),
    type: widget({
      label: 'Type'
    }, {
      display: true
    }),
    request_id: widget({
      label: 'Request Id'
    }, {
      display: true
    }),
    link_request_id: widget({
      label: 'Link Request Id'
    }, {
      display: true
    }),
    plaid_api_request_id: widget({
      label: 'Plaid Api Request Id'
    }, {
      display: true
    }),
    public_token: widget({
      label: 'Public Token'
    }, {
      display: true
    }),
    error_message: widget({
      label: 'Error Message'
    }, {
      display: true
    }),
    error_type: widget({
      label: 'Error Type'
    }, {
      display: true
    }),
    error_code: widget({
      label: 'Error Code'
    }, {
      display: true
    }),
    remote_account_id: widget({
      label: 'Remote Account Id'
    }, {
      display: true
    }),
    id: widget({
      label: 'Id'
    }, {
      display: true
    }),
    display_message: widget({
      label: 'Display Message'
    }, {
      display: true
    }),
    selected_account: widget({
      label: 'Selected Account'
    }, {
      display: true
    }),
    on_exit: widget({
      label: 'On Exit'
    }, {
      display: true
    }),
    on_success: widget({
      label: 'On Success'
    }, {
      display: true
    }),
    mfa_type: widget({
      label: 'Mfa Type'
    }, {
      display: true
    }),
    timestamp_epoch_sec: widget({
      label: 'Timestamp Epoch Sec'
    }, {
      display: true
    })
  }
})



export default data
