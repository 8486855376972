
import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Alert } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function ImageFormatSelect({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const [values, setValues] = React.useState([])
  const [options, setOptions] = React.useState([])

  const {
    [formName]: form
  } = globalState.forms

  React.useEffect(() => {
    if (context.isDefined(form)) {
      setValues(form.queryParams?.filter?.type || [])
    }
  }, [form])

  React.useEffect(() => {
    if (context.isEmpty(options)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'query',
        apiPayload: {
          obj: 'images',
          params: {
            columns: 'distinct format',
            orderby: 'format'
          },
          result_format: 'raw'
        },
        stateReducer: (store, response) => {
          setOptions(response.rows)
        }

      })
    }
  }, [globalActions.api, options])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    let newValue
    if (value.indexOf('@ALL') > -1) {
      newValue = []
    } else {
      newValue = typeof value === 'string' ? value.split(',') : value
    }
    setValues(newValue)
    props.onChange && props.onChange(newValue)
    globalActions.control.mergeState({
      forms: {
        [formName]: {
          queryParams: {
            filter: { format: newValue }
          }
        }
      }
    })
    globalActions.control.reloadTable(formName)
  }

  if (context.isUndefined(options)) { return null }

  return (
    <>
      <span error-id={props.name} />
      <FormControl fullWidth>
        <InputLabel component='legend'>
          {props.label}
          {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>*</span>}
        </InputLabel >

        <Select
          name={props.name}
          renderValue={(selected) => selected.join(', ')}
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          error={Boolean(props.error)}
          color={props.color}
          inputProps={{
            sx: context.inputColors
          }}
          sx={{ width: '100%' }}
          MenuProps={MenuProps}
        >
          <MenuItem key={1} value='@ALL'>
            <Checkbox checked={values.length === 0} />
            <ListItemText primary='All File Formats' />
          </MenuItem>
          {options.map((item) => {
            if (item.format) {
              return (
                <MenuItem key={item.format} value={item.format} >
                  <Checkbox checked={values.indexOf(item.format) > -1} />
                  <ListItemText primary={item.format} />
                </MenuItem>
              )
            }
            return null
          })}
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error &&
          <Alert severity='error'>{props.error}</Alert>}
      </FormControl >
    </>
  )
}
