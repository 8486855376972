import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import MoreInfo from './TabMoreInfo'
import SelectList from './TabSelectList'

function TabPanel ({ children, value, index, ...other }) {

  return (
    <div
      widget="TablPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`inspector-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}



export default function BasicTabs () {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Select List" />
          <Tab label="More Info" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SelectList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MoreInfo />
      </TabPanel>

    </Box>
  )
}