import * as React from 'react'
import { Container, Grid, Paper, Box, AppBar, Tab, Tabs } from '@mui/material'

import {
  Settings,
} from '@mui/icons-material'

import {
  DirtyForm,
  SystemFields,
  SaveButtons
} from 'components'

import ProgramConfig from './ProgramConfig'
import ProgramBody from './ProgramBody'
import ProgramTest from './ProgramTest'
import ProgramResult from './ProgramResult'

function TabPanel({ children, value, index, ...rest }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default function Component({ formName, ...rest }) {
  const [tabState, setTabState] = React.useState(1)

  const handleTabStateChange = (event, newState) => {
    setTabState(newState)
  }

  return <>
    <Container>
      <Paper sx={{
        p: 3,
        position: 'relative'
      }}>
        <Box sx={{
          position: '-webkit-sticky',
          // eslint-disable-next-line
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 5
        }}>
          <DirtyForm formName={formName} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SaveButtons formName={formName} />
            </Grid>
            <Grid item xs={12}>
              <SystemFields
                formName={formName}
                title='Program Object Template'
                showDescription
              >
                Instructions: Program Objects
              </SystemFields>
            </Grid>
          </Grid>

          <AppBar position='static' color='default'>
            <Tabs
              value={tabState}
              onChange={handleTabStateChange}
              variant='scrollable'
              scrollButtons
              indicatorColor='primary'
              textColor='primary'
              allowScrollButtonsMobile>
              <Tab label='Program Config' icon={<Settings />} />
              <Tab label='Source Code' icon={<Settings />} />
              <Tab label='Test Payload' icon={<Settings />} />
              <Tab label='Result Output' icon={<Settings />} />
            </Tabs>
          </AppBar>
        </Box>

        <TabPanel value={tabState} index={0}>
          <ProgramConfig formName={formName} />
        </TabPanel>

        <TabPanel value={tabState} index={1} >
          <ProgramBody formName={formName} setTabState={setTabState} />
        </TabPanel>

        <TabPanel value={tabState} index={2}>
          <ProgramTest formName={formName} />
        </TabPanel>

        <TabPanel value={tabState} index={3}>
          <ProgramResult formName={formName} />
        </TabPanel>

      </Paper>
    </Container>
  </>
}
