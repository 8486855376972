import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,

} from '@mui/material'

import {
  ActionButton,
  Checkbox,
  ClientDropDown,
  Color,
  MultiSelect,
  TextField,
} from 'components'

import { AvailableFonts } from 'theme/fonts'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    environment
  } = globalState.controls

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }


  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Checkbox
            {...fields.is_published.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={2}>
          {fields.sys_id.widget.value && fields.sys_id.widget.value !== '@new' &&
            <ActionButton
              data-handler='database'
              data-action='push-to-alternate'
              data-table={form.table}
              data-sys_id={fields.sys_id.widget.value}
              tooltip='Push this object to alternate system'
              data-title='Move object to other system?'
              data-warning={`
    IF YOU PUSH AN OBJECT TO ANOTHER SYSTEM IT
    WILL OVERWRITE THE OBJECT WITH THE SAME SYSTEM ID IN THE OTHER SYSTEM.
    DOUBLE CHECK THE OTHER SYSTEM FOR THIS ID TO MAKE SURE YOU
    ARE NOT MAKING A MISTAKE!
    `}
            >
              Push to {environment === 'production' ? 'demo' : 'production'}
            </ActionButton>

          }
        </Grid>
        <Grid item xs={4}>
          <ClientDropDown
            {...fields.client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...fields.campaign.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...fields.sub_campaign.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            {...fields.url_slug.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={8}>
          <TextField
            {...fields.url_slug_alternate.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Color
            {...fields.primary_color.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={3}>
          <Color
            {...fields.secondary_color.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={3}>
          <MultiSelect
            {...fields.header_font.widget}
            options={AvailableFonts}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={3}>
          <MultiSelect
            {...fields.text_font.widget}
            options={AvailableFonts}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.campaign_end_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.page_title.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.descriptor.widget}
            onChange={onEvent}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            {...fields.show_rival_thermometer.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={4}>
          <ClientDropDown
            {...fields.rival_client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...fields.rival_campaign.widget}
            onChange={onEvent}
            required
          />
        </Grid>
      </Grid>
    </>
  )
}
