import * as React from 'react'
import { Box, FormLabel } from '@mui/material'

export default function BorderBox ({
  label,
  children,
  ...props
}) {
  return (<>
    <Box sx={{
      p: 2,
      border: 1,
      borderRadius: 1,
      borderColor: 'grey.400',
      ...props.sx
    }}>
      <FormLabel
        sx={{
          marginLeft: '-.5em',
          marginTop: '-2em',
          paddingLeft: '0.44em',
          paddingRight: '0.44em',
          zIndex: 2,
          position: 'absolute',
          fontSize: '1em',
          width: 'auto',
          backgroundColor: 'white'
        }}>
        {label}
      </FormLabel>
      {children}
    </Box>
  </>)
}
