import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SaveButtons,
  SystemFields,
  TextField,
} from 'components'


export default function BillingFormulaForm({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Billing Formula'
          >
            Instructions: A Billing Formula is an formula that is applied
            to a campaign line item to calcuate how much money is due us.
            <br /> <br />
            {'{campaign_amount}'}<br />
            {'{line_amount}'}<br />
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.campaign.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.applies_to.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.line_description.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.formula.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
