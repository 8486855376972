import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'user_report'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    y_2021_06_carryover: widget({
      label: 'Y 2021 06 Carryover'
    }, {
      display: true
    }),
    y_2021_06_totalcontribution: widget({
      label: 'Y 2021 06 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_06_chargingamount: widget({
      label: 'Y 2021 06 Chargingamount'
    }, {
      display: true
    }),
    y_2021_06_nextmonthcarryover: widget({
      label: 'Y 2021 06 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_07_carryover: widget({
      label: 'Y 2021 07 Carryover'
    }, {
      display: true
    }),
    y_2021_07_totalcontribution: widget({
      label: 'Y 2021 07 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_07_chargingamount: widget({
      label: 'Y 2021 07 Chargingamount'
    }, {
      display: true
    }),
    y_2021_07_nextmonthcarryover: widget({
      label: 'Y 2021 07 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_08_carryover: widget({
      label: 'Y 2021 08 Carryover'
    }, {
      display: true
    }),
    y_2021_08_totalcontribution: widget({
      label: 'Y 2021 08 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_08_chargingamount: widget({
      label: 'Y 2021 08 Chargingamount'
    }, {
      display: true
    }),
    y_2021_08_nextmonthcarryover: widget({
      label: 'Y 2021 08 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_09_carryover: widget({
      label: 'Y 2021 09 Carryover'
    }, {
      display: true
    }),
    y_2021_09_totalcontribution: widget({
      label: 'Y 2021 09 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_09_chargingamount: widget({
      label: 'Y 2021 09 Chargingamount'
    }, {
      display: true
    }),
    y_2021_09_nextmonthcarryover: widget({
      label: 'Y 2021 09 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_10_carryover: widget({
      label: 'Y 2021 10 Carryover'
    }, {
      display: true
    }),
    y_2021_10_totalcontribution: widget({
      label: 'Y 2021 10 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_10_chargingamount: widget({
      label: 'Y 2021 10 Chargingamount'
    }, {
      display: true
    }),
    y_2021_10_nextmonthcarryover: widget({
      label: 'Y 2021 10 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_11_carryover: widget({
      label: 'Y 2021 11 Carryover'
    }, {
      display: true
    }),
    y_2021_11_totalcontribution: widget({
      label: 'Y 2021 11 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_11_chargingamount: widget({
      label: 'Y 2021 11 Chargingamount'
    }, {
      display: true
    }),
    y_2021_11_nextmonthcarryover: widget({
      label: 'Y 2021 11 Nextmonthcarryover'
    }, {
      display: true
    }),
    y_2021_12_carryover: widget({
      label: 'Y 2021 12 Carryover'
    }, {
      display: true
    }),
    y_2021_12_totalcontribution: widget({
      label: 'Y 2021 12 Totalcontribution'
    }, {
      display: true
    }),
    y_2021_12_chargingamount: widget({
      label: 'Y 2021 12 Chargingamount'
    }, {
      display: true
    }),
    y_2021_12_nextmonthcarryover: widget({
      label: 'Y 2021 12 Nextmonthcarryover'
    }, {
      display: true
    }),
    test_account: checkbox({
      label: 'Test Account'
    }, {
      display: true
    }),
    dollar_roundup: checkbox({
      label: 'Dollar Roundup'
    }, {
      display: true
    }),
    recurring_donation: checkbox({
      label: 'Recurring Donation'
    }, {
      display: true
    }),
    payment_account_expired: checkbox({
      label: 'Payment Account Expired'
    }, {
      display: true
    }),
    createddate: widget({
      label: 'Createddate'
    }, {
      display: true
    }),
    firstname: widget({
      label: 'Firstname'
    }, {
      display: true
    }),
    lastname: widget({
      label: 'Lastname'
    }, {
      display: true
    }),
    username: widget({
      label: 'Username'
    }, {
      display: true
    }),
    supported_id: widget({
      label: 'Supported Id'
    }, {
      display: true
    }),
    supported_id_description: widget({
      label: 'Supported Id Description'
    }, {
      display: true
    }),
    remote_profile_id: widget({
      label: 'Remote Profile Id'
    }, {
      display: true
    }),
    remote_payment_id: widget({
      label: 'Remote Payment Id'
    }, {
      display: true
    }),
    remote_access_token: widget({
      label: 'Remote Access Token'
    }, {
      display: true
    }),
    remote_account_id: widget({
      label: 'Remote Account Id'
    }, {
      display: true
    }),
    max_donation: widget({
      label: 'Max Donation'
    }, {
      display: true
    }),
    recurring_donation_type: widget({
      label: 'Recurring Donation Type'
    }, {
      display: true
    }),
    recurring_custom_amount: widget({
      label: 'Recurring Custom Amount'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
    jill_stein: widget({
      label: 'Jill Stein'
    }, {
      display: true
    }),
    legacy_closed: widget({
      label: 'Legacy Closed'
    }, {
      display: true
    }),
    what_to_bill: widget({
      label: 'What To Bill'
    }, {
      display: true
    })
  }
})



export default data
