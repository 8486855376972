import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'braintree_transaction'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client: widget({
      label: 'Client'
    }, {
      display: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    effective_month: widget({
      label: 'Effective Month'
    }, {
      display: true
    }),
    effective_year: widget({
      label: 'Effective Year'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    email: widget({
      label: 'Email'
    }, {
      display: true
    }),
    street_address: widget({
      label: 'Street Address'
    }, {
      display: true
    }),
    postal_code: widget({
      label: 'Postal Code'
    }, {
      display: true
    }),
    amount_attempted: widget({
      label: 'Amount Attempted'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    status_initial: widget({
      label: 'Status Initial'
    }, {
      display: true
    }),
    reason_for_transaction: widget({
      label: 'Reason For Transaction'
    }, {
      display: true
    }),
    payment_ok: checkbox({
      label: 'Payment Ok'
    }, {
      display: true
    }),
    braintree_id: widget({
      label: 'Braintree Id'
    }, {
      display: true
    }),
    graphql_id: widget({
      label: 'Graphql Id'
    }, {
      display: true
    }),
    avs_postal_code_response_code: widget({
      label: 'Avs Postal Code Response Code'
    }, {
      display: true
    }),
    avs_street_address_response_code: widget({
      label: 'Avs Street Address Response Code'
    }, {
      display: true
    }),
    cvv_response_code: widget({
      label: 'Cvv Response Code'
    }, {
      display: true
    }),
    status_submitted_for_settlement: widget({
      label: 'Status Submitted For Settlement'
    }, {
      display: true
    }),
    authorized_amount: widget({
      label: 'Authorized Amount'
    }, {
      display: true
    }),
    processor_authorization_code: widget({
      label: 'Processor Authorization Code'
    }, {
      display: true
    }),
    processor_response_code: widget({
      label: 'Processor Response Code'
    }, {
      display: true
    }),
    processor_response_text: widget({
      label: 'Processor Response Text'
    }, {
      display: true
    }),
    card_last_4: widget({
      label: 'Card Last 4'
    }, {
      display: true
    }),
    card_type: widget({
      label: 'Card Type'
    }, {
      display: true
    }),
    card_expiration_month: widget({
      label: 'Card Expiration Month'
    }, {
      display: true
    }),
    card_expiration_year: widget({
      label: 'Card Expiration Year'
    }, {
      display: true
    }),
    card_customer_location: widget({
      label: 'Card Customer Location'
    }, {
      display: true
    }),
    card_image_url: widget({
      label: 'Card Image Url'
    }, {
      display: true
    }),
    receipt: checkbox({
      label: 'Receipt'
    }, {
      display: true
    }),
    status_settling: widget({
      label: 'Status Settling'
    }, {
      display: true
    }),
    status_processor_declined: widget({
      label: 'Status Processor Declined'
    }, {
      display: true
    }),
    status_gateway_rejected: widget({
      label: 'Status Gateway Rejected'
    }, {
      display: true
    }),
    special_fund: widget({
      label: 'Special Fund'
    }, {
      display: true
    }),
    pay_processing_fee: checkbox({
      label: 'Pay Processing Fee'
    }, {
      display: true
    }),
    computed_processing_fee: widget({
      label: 'Computed Processing Fee'
    }, {
      display: true
    }),
    anonymous_gift: checkbox({
      label: 'Anonymous Gift'
    }, {
      display: true
    }),
    anonymous_name: checkbox({
      label: 'Anonymous Name'
    }, {
      display: true
    }),
    date_last_updated: widget({
      label: 'Date Last Updated'
    }, {
      display: true
    }),
    status_authorized: widget({
      label: 'Status Authorized'
    }, {
      display: true
    }),
    status_settled: widget({
      label: 'Status Settled'
    }, {
      display: true
    }),
    traceback: widget({
      label: 'Traceback'
    }, {
      display: true
    }),
    buy_fan_cutout: checkbox({
      label: 'Buy Fan Cutout'
    }, {
      display: true
    }),
    special_instructions: widget({
      label: 'Special Instructions'
    }, {
      display: true
    }),
    cutout_qty: widget({
      label: 'Cutout Qty'
    }, {
      display: true
    }),
    sub_campaign: widget({
      label: 'Sub Campaign'
    }, {
      display: true
    }),
    buy_family_fourpack: checkbox({
      label: 'Buy Family Fourpack'
    }, {
      display: true
    }),
    buy_contest_entry: checkbox({
      label: 'Buy Contest Entry'
    }, {
      display: true
    }),
    buy_contest_qty: widget({
      label: 'Buy Contest Qty'
    }, {
      display: true
    }),
    include_family_in_contest: widget({
      label: 'Include Family In Contest'
    }, {
      display: true
    }),
    status_authorized_amount: widget({
      label: 'Status Authorized Amount'
    }, {
      display: true
    }),
    status_submitted_for_settlement_amount: widget({
      label: 'Status Submitted For Settlement Amount'
    }, {
      display: true
    }),
    status_settling_amount: widget({
      label: 'Status Settling Amount'
    }, {
      display: true
    }),
    status_settled_amount: widget({
      label: 'Status Settled Amount'
    }, {
      display: true
    }),
    phone_number: widget({
      label: 'Phone Number'
    }, {
      display: true
    }),
    buy_vip_qty: widget({
      label: 'Buy Vip Qty'
    }, {
      display: true
    }),
    buy_vip_courtside: checkbox({
      label: 'Buy Vip Courtside'
    }, {
      display: true
    }),
    donation_frequency: widget({
      label: 'Donation Frequency'
    }, {
      display: true
    }),
    special_fund_notes: widget({
      label: 'Special Fund Notes'
    }, {
      display: true
    }),
    processor_error_message: widget({
      label: 'Processor Error Message'
    }, {
      display: true
    }),
    record_source: widget({
      label: 'Record Source'
    }, {
      display: true
    })
  }
})



export default data
