import * as React from 'react'
import { ScrollingToolbar } from 'components'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from 'react-router-dom'

function handleClick(event) {

}

export default function BasicBreadcrumbs({ links }) {
  return (
    <ScrollingToolbar>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          {links.map(({ label, link }) => <Link
            style={{ textDecoration: 'none' }}
            to={link}
            key={link}
          >
            {label}
          </Link>)}
        </Breadcrumbs>
      </div>
    </ScrollingToolbar>
  )
}