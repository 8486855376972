import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'

export const table = 'zipcodes'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    zip: widget({
      label: 'Zip'
    }, {
      display: true
    }),
    city: widget({
      label: 'City'
    }, {
      display: true
    }),
    state: widget({
      label: 'State'
    }, {
      display: true
    }),
    latitude: widget({
      label: 'Latitude'
    }, {
      display: true
    }),
    longitude: widget({
      label: 'Longitude'
    }, {
      display: true
    }),
    timezone: widget({
      label: 'Timezone'
    }, {
      display: true
    }),
    dstflag: widget({
      label: 'Dstflag'
    }, {
      display: true
    }),
    geopoint: widget({
      label: 'Geopoint'
    }, {
      display: true
    })
  }
})



export default data
