import * as React from 'react'
import useGlobal from 'store'
import { Box } from '@mui/material'

export default function BuildInfo(props) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const {
    environment,
    vars,
    apiSpinner
  } = globalState.controls

  return (
    <>
      <Box
        sx={{
          m: 2,

        }}
      >
        <hr />
        Build Info:&nbsp;{environment}&nbsp;#{vars ? parseInt(vars.AWS_JOB_ID) : ''}
        <br />
        {vars ? vars.BUILD_DATETIME : ''}
        {apiSpinner.count ? ' [' + apiSpinner.count + ']' : ''}
        <br />
        Velocity: {vars ? vars.velocity : ''}
      </Box>
    </>
  )
}
