import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,
} from '@mui/material'

import {
  CodeField,
} from 'components'

export default function Component() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { test_result } = globalState

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CodeField
            value={test_result}
            height='40vh'
          />
        </Grid>
      </Grid>
    </>
  )
}
