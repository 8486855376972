import * as React from 'react'
import useGlobal from 'store'
import {
  Button,
  Grid
} from '@mui/material'
import MenuButton from './MenuButton'
import {
  SkipNext,
  SkipPrevious
} from '@mui/icons-material'
export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }
  const form = globalState.forms[formName]

  return (
    <>
      <Grid container spacing={0}>
        <Grid item>
          <Button
            onClick={onEvent}
            disabled={form.loaded !== true}
            name='@skip-previous'
            size='small'
            startIcon={<SkipPrevious />}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <MenuButton
            formName={formName}
            label='[Pos 0]'
            name=''
            disabled={form.loaded !== true}
            size='small'
            menuItems={[
              {
                label: 'Jump 10 Forward',
                name: '@jump-10-forward'
              }, {
                label: 'Jump 50 Forward',
                name: '@jump-50-forward'
              }, {
                label: 'Jump -10 Back',
                name: '@jump-10-back'
              }, {
                label: 'Jump -50 Back',
                name: '@jump-50-back'
              }
            ]}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={onEvent}
            disabled={form.loaded !== true}
            name='@skip-next'
            size='small'
            endIcon={<SkipNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
