import * as React from 'react'
import {
    Container,
    Grid,
} from '@mui/material'
import {
    Header
} from 'components'
import ToolBar from '../ToolBar'
import useGlobal from 'store'

const Dashboard = () => {
    const [globalState, globalActions] = useGlobal()  // eslint-disable-line

    React.useEffect(() => {
        globalActions.control.setUserData({
            'controls.menu.options.selected': {
                controls: {
                    menu: {
                        options: {
                            selected: 'Dashboard'
                        }
                    }
                }
            }
        })

        globalActions.control.mergeState({
            controls: {
                PageTitle: 'Admin Dashboard',
                menu: {
                    options: {
                        selected: 'Dashboard'
                    }
                }
            }
        }, true)
    }, [globalActions.control])

    return (
        <>
            <Header label='Documentation' title='Documentation' />
            <ToolBar links={[
                { label: 'Home', link: '/documentation/home' },
                { label: 'Developers', link: '/documentation/developers' },
                { label: 'Start Here', link: '/documentation/start_here' }
            ]} />
            <Container maxWidth={false} sx={{ pt: 3 }}>
                <Grid
                    container
                    spacing={3}
                >

                    <Grid item xs={12}>
                        Content
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}

export default Dashboard
