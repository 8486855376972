import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,
} from '@mui/material'

import {
  Checkbox,
  TextField,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    vars = {}
  } = globalState.controls

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          CURRENT BRANCH: {vars.AWS_BRANCH}
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.applied_system.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_purge_syslog.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.purge_syslog_days.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12}>
          <Checkbox
            {...fields.enable_master_queue_handler.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_master_email_sendgrid.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.email_whitelist.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2} />
        <Grid item xs={6}>
          <Checkbox
            {...fields.enable_auto_send_caringcent_email_receipt_sendgrid.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            {...fields.caringcent_email_receipt_days.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_pull_plaid_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.plaid_days_to_pull.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />

        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_pull_authorize_net_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.authorize_days_to_pull.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_pull_braintree_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            {...fields.braintree_days_to_pull.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_process_braintree_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_process_authorize_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_purge_plaid_accounts.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.purge_plaid_accounts_days.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.enable_auto_purge_plaid_access_tokens.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </>
  )
}
