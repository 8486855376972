import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'sys_reset_password'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    email_status: widget({
      label: 'Email Status'
    }, {
      display: true
    }),
    host: widget({
      label: 'Host'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    remote_addr: widget({
      label: 'Remote Addr'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    return_id: widget({
      label: 'Return Id'
    }, {
      display: true
    }),
    traceback: widget({
      label: 'Traceback'
    }, {
      display: true
    }),
    captain_quirk: widget({
      label: 'Captain Quirk'
    }, {
      display: true
    })
  }
})



export default data
