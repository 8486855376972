import * as React from 'react'

import {
  Grid,
  Box,
  AppBar,
  Tab,
  Tabs
} from '@mui/material'


import {
  Facebook,
  Ballot,
  LibraryBooks,
  Settings,
  Check,
  Receipt,
  RecentActors,
  MonetizationOn,
  ReceiptLong,
  Payment,
  AttachMoney
} from '@mui/icons-material'

import {
  DirtyForm,
  SystemFields,
  SaveButtons
} from 'components'

import Config from './FormBuilderConfig'
import FormFields from './FormBuilderMetricFields'
import Confirmation from './FormBuilderConfirmation'
import Body from './FormBuilderBody'
import Social from './FormBuilderSocial'
import EmailReceipt from './FormBuilderEmailReceipt'
import DonorInfo from './FormBuilderDonorInfo'
import DonationFields from './FormBuilderDonationFields'
import RallyBilling from './FormBuilderRallyBilling'
import RallyReceipt from './FormBuilderRallyReceipt'
import Tipping from './FormBuilderTipping'

function TabPanel({ children, value, index, ...rest }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default function Component({ formName, ...rest }) {
  const [tabState, setTabState] = React.useState(0)

  const handleTabStateChange = (event, newState) => {
    setTabState(newState)
  }

  let index = 0

  return <>
    <div
      style={{
        position: '-webkit-sticky',
        // eslint-disable-next-line
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 5
      }}>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Form Builder Template'
            showDescription
          >
            Instructions: Form Builder
          </SystemFields>
        </Grid>
      </Grid>
      <AppBar position='static' color='default'>
        <Tabs
          value={tabState}
          onChange={handleTabStateChange}
          variant='scrollable'
          scrollButtons
          indicatorColor='primary'
          textColor='primary'
          allowScrollButtonsMobile>
          <Tab label='Config' icon={<Settings />} />
          <Tab label='Tipping' icon={<AttachMoney />} />
          <Tab label='Social/SEO' icon={<Facebook />} />
          <Tab label='Body' icon={<LibraryBooks />} />
          <Tab label='Donor Info' icon={<RecentActors />} />
          <Tab label='Metric Fields' icon={<Ballot />} />
          <Tab label='Donation Fields' icon={<MonetizationOn />} />
          <Tab label='Confirmation' icon={<Check />} />
          <Tab label='Email Receipt' icon={<Receipt />} />
          <Tab label='Rally Billing' icon={<Payment />} />
          <Tab label='Rally Receipt' icon={<ReceiptLong />} />
        </Tabs>
      </AppBar>
    </div>

    <TabPanel value={tabState} index={index++}>
      <Config formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <Tipping formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <Social formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <Body formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <DonorInfo formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <FormFields formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <DonationFields formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <Confirmation formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <EmailReceipt formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <RallyBilling formName={formName} />
    </TabPanel>
    <TabPanel value={tabState} index={index++}>
      <RallyReceipt formName={formName} />
    </TabPanel>
    <Grid item xs={12}>
      <br />
      <SaveButtons formName={formName} />
    </Grid>
  </>
}
