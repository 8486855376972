import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid, Box, Typography } from '@mui/material'
import {
  ActionButton,
  BorderBox,
  Color,
  DirtyForm,
  Image,
  MultiSelect,
  SaveButtons,
  Switch,
  SystemFields,
  TextField,
} from 'components'
import { AvailableFonts } from 'theme/fonts'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields formName={formName} title='Client'>
            <Typography>
              Instructions: A Client object defines a client of CaringCent.
              A client has a one-to-many relation to campaigns.
              Client is an integral part of the billing process.
            </Typography>
            <Typography>
              Example: Client ID: OSU, Client Name: Oregon State University
            </Typography>
            <Typography>
              Client ID:<br />
              Client Name:<br />
            </Typography>
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <BorderBox label='Client Active Settings'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Switch
                  {...fields.inactive.widget}
                  onChange={onEvent}
                  questionmark={<>
                    This toggle will turn off all features for this client and hide all content.<br />
                    If this toggle is OFF, it means this client is <b><em>ACTIVE</em></b> and all content that is toggled ON is <b><em>allowed</em></b>.<br />
                  </>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Switch
                  {...fields.allow_product_spot_donation.widget}
                  onChange={onEvent}
                  questionmark={<>
                    This toggle will display Spot Donations on the donor site.<br />
                    If this toggle is OFF, it will hide all mention of Spot Donation content on donor site.<br />
                  </>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Switch
                  {...fields.allow_product_monthly_recurring.widget}
                  onChange={onEvent}
                  questionmark={<>
                    This toggle will display MonthlyRecurring content on the donor site, and will<br />
                    allow MonthlyRecurring forms to be built in form builder. If this toggle is OFF, <br />
                    it will hide all mention of MonthlyRecurring content on donor site.<br />
                  </>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Switch
                  {...fields.allow_product_swipegive.widget}
                  onChange={onEvent}
                  questionmark={<>
                    This toggle will display SwipeGive content on the donor site, and will<br />
                    allow SwipeGive forms to be built in form builder. If this toggle is OFF, <br />
                    it will hide all mention of SwipeGive content on donor site.<br />
                  </>}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Switch
                  {...fields.allow_product_rallygive.widget}
                  onChange={onEvent}
                  questionmark={<>
                    This toggle will display RallyGive content on the donor site, and will<br />
                    allow RallyGive forms to be built in form builder. If this toggle is OFF, <br />
                    it will hide all mention of RallyGive content on donor site.<br />
                  </>}
                />
              </Grid>

              <Grid item xs={12}>
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  disabled={!!!fields.sys_id.widget.value}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='close-all-donor-accounts'
                  tooltip='Close all donor accounts'
                  data-warning='This will close all Plaid, Authorize.net, and Braintree accounts associated with this client. (This will also update the billing table.)'
                  data-title='WARNING'
                >
                  Close Donor Accounts
                </ActionButton>
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  disabled={!!!fields.sys_id.widget.value}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='suspend-all-donor-accounts'
                  tooltip='Suspend all donor accounts'
                  data-warning='This will suspend all accounts associated with this client. (This will also update the billing table.)'
                  data-title='WARNING'
                >
                  Suspend Donor Accounts
                </ActionButton>
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  disabled={!!!fields.sys_id.widget.value}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='activate-suspended-donor-accounts'
                  tooltip='Activate all suspended donor accounts'
                  data-warning='This will activate all suspended accounts. (This will also update the billing table.)'
                  data-title='WARNING'
                >
                  Activate Suspended Accounts
                </ActionButton>
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  disabled={!!!fields.sys_id.widget.value}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='force-update-billing-table'
                  tooltip='Force Update Billing Table'
                  data-warning='This will update the billing table for all client users.'
                  data-title='WARNING'
                >
                  Update Billing Report
                </ActionButton>
              </Grid>
            </Grid>
          </BorderBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client_name.widget}
            onChange={onEvent}
          />
        </Grid>

        <Grid item xs={12}>
          <BorderBox
            label='Form Builder Default Settings'
            sx={{
              backgroundColor: '#FFFFF0',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Color
                  {...fields.primary_color.widget}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Color
                  {...fields.secondary_color.widget}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  {...fields.header_font.widget}
                  options={AvailableFonts}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelect
                  {...fields.text_font.widget}
                  options={AvailableFonts}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  {...fields.page_title.widget}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  {...fields.descriptor.widget}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={12}>
                <Image
                  {...fields.logo.widget}
                  onChange={onEvent}
                  formName={formName}
                />
              </Grid>
            </Grid>
          </BorderBox>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <BorderBox label='Swipegive Settings'
              sx={{
                mt: 3,
                mb: 3,
                backgroundColor: '#F0FFF0',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ p: 1 }} />
                  <Image
                    {...fields.large_logo_url.widget}
                    onChange={onEvent}
                    formName={formName}
                    questionmark={<>
                    </>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Image
                    {...fields.small_logo_url.widget}
                    onChange={onEvent}
                    formName={formName}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.campaign_title.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.vanity_url.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.primary_email.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.short_description.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.long_description.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Switch
                    {...fields.is_political.widget}
                    onChange={onEvent}
                    questionmark={<>
                      test
                    </>}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...fields.attestation_text.widget}
                    onChange={onEvent}
                    questionmark={<>

                    </>}
                  />
                </Grid>

              </Grid>
            </BorderBox>
          </Grid>
        </Grid >
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid >
    </>
  )
}
