import * as React from 'react'
import Box from '@mui/material/Box'
//import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro'
import { DataGrid } from '@mui/x-data-grid'
import useGlobal from 'store'
import context from 'lib/context'

export default function GenericDataGrid({ formName, table, sx, select, ...rest }) {
  const [globalState, globalActions] = useGlobal()
  const form = globalState.forms[formName]
  const fields = form.fields

  const tablename = `${table}-data-grid`

  React.useEffect(() => {
    if (fields.email_address.widget.value) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'query',
        apiPayload: {
          datatable: tablename,
          obj: table,
          headers: true,
          params: {
            columns: select,
            where: {
              email_address: fields.email_address.widget.value,
            },
            orderby: 'sys_id desc'
          },
          result_format: 'x-data-grid'
        },
        spinner: {
          content: 'Loading Grid Content. Please Wait...'
        }
      })
    }
  }, [
    globalActions.api,
    fields.email_address.widget.value,
    tablename,
    table,
    select
  ])
  const datatable = globalState.repo[tablename] || { undefined }
  if (context.isUndefined(datatable)) return
  const { rows, columns } = datatable
  return (
    <Box sx={{ height: 275, width: '100%', ...sx }}>
      <DataGrid
        getRowId={(row) => row.sys_id}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        density="compact"
        autoPageSize
        {...rest}
      />
    </Box>
  )
}
