import * as React from 'react'
import moment from 'moment'
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  subHeader: 'Senior Developer',
  header: 'Katarina Smith',
  timezone: 'GTM-7'
}


const Profile = ({ ...rest }) => {

  return (
    <Card
      {...rest}
    >
      <CardContent>
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
        >
          <Avatar
            sx={{
              height: 100,
              width: 100
            }}
            src={user.avatar}
          />
          <Typography
            color='textPrimary'
            gutterBottom
            variant='h3'
          >
            {user.name}
          </Typography>
          <Typography
            color='textSecondary'
            variant='body1'
          >
            {`${user.city} ${user.country}`}
          </Typography>
          <Typography
            color='textSecondary'
            variant='body1'
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color='primary'
          fullWidth
          variant='text'
        >
          Upload picture
        </Button>
      </CardActions>
    </Card>
  )
}

export default Profile
