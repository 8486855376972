import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  SelectedRows
} from 'components'
import {
  Box,
  Grid,
  Container,
  Paper
} from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { useNavigate } from 'react-router-dom'

export const ListView = ({
  formName,
  Toolbar,
  ...props
}) => {
  const [globalState, globalActions] = useGlobal() // eslint-disable-line
  const params = context.getParams()
  const navigate = useNavigate()

  const {
    rowsPerPage
  } = globalState.controls.datatables

  const form = globalState.forms[formName]

  React.useEffect(() => {
    globalActions.control.reloadTable(formName)
  }, [globalActions.control, formName])

  React.useEffect(() => {
    if (params.sys_id) {
      globalActions.control.publish('setForm', {
        action: {
          formName,
          type: 'button',
          name: '@load',
          value: params.sys_id
        }
      })
    }
  }, [])  // eslint-disable-line


  const datatable = globalState.repo[form.table] || { undefined }
  if (context.isUndefined(datatable)) {
    return (
      <>
        <Container>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MUIDataTable
                  title={props.title}
                />
                <Box sx={{
                  color: '#e0e0e0',
                  position: 'relative',
                  top: -20,
                  left: 5
                }}>LV</Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </>
    )
  }
  const options = {
    filterType: 'multiselect',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20, 50, 75, 100],
    rowsSelected: datatable.config?.rowsSelected,
    customToolbar: () => {
      return (
        <Toolbar
          formName={formName}
        />
      )
    },
    customToolbarSelect: () => { },
    onRowClick: (rowData, rowMeta) => {
      navigate(`?sys_id=${context.getCellData(rowData, form.sysIdx)}`, { replace: false })
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'rowClick',
        rowData,
        rowMeta
      })
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'selectionChange',
        curRowSelected,
        allRowsSelected,
        rowsSelected
      })
    },
    onRowsDelete: (rowsDeleted, deleteData, newTableData) => {
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'deleteRows',
        selectedRows: rowsDeleted,
        deleteData
      })
    },
    onChangeRowsPerPage: (numberOfRows) => {
      globalActions.control.mergeState({
        controls: {
          datatables: {
            rowsPerPage: numberOfRows
          }
        }
      })
      globalActions.control.setUserData({
        'controls.datatables': {
          controls: {
            datatables: {
              rowsPerPage: numberOfRows
            }
          }
        }
      })
    },
    customSort: (data, colIndex, order, meta) => {
      return data.sort((a, b) => {
        return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1)
      })
    }
  }
  return (
    <>
      {
        context.environment !== 'production' &&
        form?.cards?.SelectFilters &&
        <form.cards.SelectFilters formName={formName} />
      }
      <MUIDataTable
        title={props.title}
        columns={context.isUndefined(datatable.columns) ? form.muidatatable.tableHeaders : datatable.columns}
        options={options}
        data={datatable.rows}
      />
      <Box sx={{
        color: '#e0e0e0',
        position: 'relative',
        top: -20,
        left: 5
      }}>LV</Box>
      <SelectedRows formName={formName} />
    </>
  )
}

export default ListView
