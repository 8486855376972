import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'

export const table = 'client_users'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    UserStatus: {
      widget: {},
      defaults: {
        error: false,
        fullWidth: true,
        helperText: 'Cognito Status ',
        label: 'UserStatus',
        placeholder: '',
        size: 'small',
        variant: 'outlined',
        value: '',
        displayonly: 'true',

      }
    },
    client_id: widget({
      label: 'Client ID'
    }, {
      display: true,
      searchable: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true,
      searchable: true
    }),
    phone_number: widget({
      label: 'Phone Number'
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true,
      searchable: true
    }),
    profile_image: widget({
      label: 'Profile Image'
    }),
    profile_description: widget({
      label: 'Profile Description'
    })
  }
})


export default data
