import * as React from 'react'
import {
  Alert,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
} from '@mui/material'

import {
  QuestionMark,
} from 'components'

export const Component = ({
  labelPlacement = 'right',
  ...props
}) => {
  const id = props.id || props.name
  const name = props.name || props.id

  if (labelPlacement === 'left') labelPlacement = 'start'
  if (labelPlacement === 'right') labelPlacement = 'end'

  return (
    <>
      <span error-id={name} />
      <FormControl component='fieldset' fullWidth>
        {props.headerText && <FormLabel component='legend'>{props.headerText}</FormLabel>}
        {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>* Required</span>}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                id={id}
                name={name}
                checked={!!props.checked}
                onChange={props.onChange}
                title={name}
                value={props.value || ''}
                color={props.color}
              />
            }
            label={props.label}
            labelPlacement={labelPlacement}
          />
          {props.questionmark && <QuestionMark style={{
            position: 'relative',
            top: 7,
            left: -10,
          }}>{props.questionmark}</QuestionMark>}
        </FormGroup>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
