import context from 'lib/context'
import system from '../system'
import * as widgets from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import {
  ActionButton,
} from 'components'

export const table = 'program_objects'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    program_handle: widgets.widget({
      label: 'Program Handle'
    }, {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return <>
          <Typography noWrap align='center' title={row.program_description}>
            {value}
          </Typography>
        </>
      },
      display: true,
      align: 'left',
      searchable: true
    }),
    source_code: widgets.code({
      label: 'Source Code',
    }, {
      display: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        return <>
          <Box align='left' sx={{
            maxWidth: 450,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {value}
          </Box>
        </>
      },
      searchable: true
    }),
    tags: widgets.widget({
      label: 'Tags'
    }, {
      searchable: true
    }),
    num_lines: widgets.widget({
      label: 'Num Lines'
    }),
    num_chars: widgets.widget({
      label: 'Num Chars'
    }),

    execution_environment: widgets.radio({
      label: 'Execution Environment',
      options: [
        { value: '', label: 'Manual Trigger' },
        { value: 'HTTP Event', label: 'HTTP Event' },
        { value: 'SQS Event', label: 'SQS Event' }
      ],
      row: true
    }, {
      display: true,
      searchable: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) value = 'Manual'
        return <>
          <Box align='center'>
            {value}
          </Box>
        </>
      },
    }),
    recurring_period: widgets.widget({
      label: 'Recurs Every'
    }),
    first_day: widgets.widget({
      label: 'First Day of Month'
    }),
    last_day: widgets.widget({
      label: 'Last Day of Month'
    }),
    fifteenth_day: widgets.widget({
      label: '15th Day of Month'
    }),
    trigger: widgets.radio({
      label: 'Trigger',
      options: [
        { value: '5Minutes', label: '5 Minutes' },
        { value: 'Hourly', label: 'Hourly' },
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Monthly', label: 'Monthly' },
      ],
      row: false
    }, {
      display: true,
      searchable: true
    }),
    in_effect: widgets.checkbox({
      label: 'Trigger In Effect'
    }, {
      display: true
    }),
    monday: widgets.checkbox({
      label: 'Monday'
    }),
    tuesday: widgets.checkbox({
      label: 'Tuesday'
    }),
    wednesday: widgets.checkbox({
      label: 'Wednesday'
    }),
    thursday: widgets.checkbox({
      label: 'Thursday'
    }),
    friday: widgets.checkbox({
      label: 'Friday'
    }),
    saturday: widgets.checkbox({
      label: 'Saturday'
    }),
    sunday: widgets.checkbox({
      label: 'Sunday'
    }),
    test_json: widgets.code({
      label: 'Test JSON Payload',
    }),
    run_program: widgets.widget({
      label: 'Run Program'
    }, {
      anonymous: true,
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return (
          <ActionButton
            data-handler='program'
            data-action='run-program'
            data-sys_id={row.sys_id}
            tooltip='Run this saved program'
          >
            Run
          </ActionButton>
        )
      }
    }),
    exists_in_other: widgets.checkbox({
      label: 'Exists in Other DB'
    }, {
      anonymous: true,
      display: true,
    }),
    version: widgets.widget({
      label: `${context.environment.substr(0, 4)} Version`
    }, {
      display: true
    }),
    last_saved_date: widgets.datetime({
      label: `${context.environment.substr(0, 4)} Last Saved`
    }, {
      display: true
    }),
    other_version: widgets.widget({
      label: 'Other Version'
    }, {
      anonymous: true,
      display: true
    }),
    other_last_saved_date: widgets.datetime({
      label: 'Other Last Saved'
    }, {
      anonymous: true,
      display: true
    }),
    program_description: widgets.code({
      label: 'Program Description',
    }, {
      display: false,
      searchable: true,
      fetch: true,
    }),
  }
})



export default data
