import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  SystemFields,
  TextField,
  MultiValue
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, true)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Receipt'
          >
            Receipt
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* <Grid item xs={12}>
          <iframe srcDoc={fields.donation_form_html.widget.value} />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.receipt_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.receipt_to.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.braintree_transaction.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.receipt_sender.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiValue
            {...fields.receipt_bcc.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <iframe title='Receipt' srcDoc={fields.receipt_html.widget.value} width='100%' height='500' />
        </Grid>
      </Grid>
    </>
  )
}
