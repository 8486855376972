import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'fraud_detection_blacklist'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'client_id asc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    source_ip: widget({
      label: 'IP'
    }),
    email_address: widget({
      label: 'Email'
    }),
  }
})




export default data
