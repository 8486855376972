import * as React from 'react'
import {
    Container,
    Grid,
} from '@mui/material'
import {
    Header
} from 'components'
import ToolBar from '../ToolBar'
import ReactMarkdown from 'react-markdown'


const Dashboard = () => {


    return (
        <>
            <Header label='Documentation' title='Documentation' />
            <ToolBar links={[
                { label: 'Home', link: '/documentation/home' },
                { label: 'Developers', link: '/documentation/developers' },
                { label: 'Database Info', link: '/documentation/db_info' },
            ]} />
            <Container maxWidth={false} sx={{ pt: 3 }}>
                <Grid
                    container
                    spacing={3}
                >

                    <Grid item xs={12}>
                        <ReactMarkdown>
                            {markdown}
                        </ReactMarkdown>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Dashboard
const markdown = `
    Database Info

    Database version is Postgres 11.17. the database is deployed in a private vpc,
    and is not accessible from the internet.
    The databse needs to be updated to the latest version.
`