import context from 'lib/context'
import system from '../system'
import {
  widget,
  money,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'transaction_lineitems'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    src: widget({
      label: 'Src'
    }, {
      display: true
    }),
    src_id: widget({
      label: 'Src Id'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    client: widget({
      label: 'Client'
    }, {
      display: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    subcampaign: widget({
      label: 'Subcampaign'
    }, {
      display: true
    }),
    quantity: widget({
      label: 'Quantity'
    }, {
      display: true
    }),
    name: widget({
      label: 'Name'
    }, {
      display: true
    }),
    kind: widget({
      label: 'Kind'
    }, {
      display: true
    }),
    unit_amount: money({
      label: 'Unit Amount'
    }, {
      display: true,
    }),
    unit_tax_amount: money({
      label: 'Unit Tax Amount'
    }, {
      display: true
    }),
    discount_amount: money({
      label: 'Discount Amount'
    }, {
      display: true
    }),
    total_amount: money({
      label: 'Total Amount'
    }, {
      display: true
    }),
    unit_of_measure: widget({
      label: 'Unit Of Measure'
    }, {
      display: true
    }),
    product_code: widget({
      label: 'Product Code'
    }, {
      display: true
    }),
    commodity_code: widget({
      label: 'Commodity Code'
    }, {
      display: true
    }),
    url: widget({
      label: 'Url'
    }, {
      display: true
    }),
    tax_amount: money({
      label: 'Tax Amount'
    }, {
      display: true
    })
  }
})



export default data
