import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
  money,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'transactions'
export const data = context.merge(system, {
  table,
  cards,
  functions,

  query: {
    obj: table,
    datatable: table,
    filter: {},
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'effective_date desc, sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    src: widget({
      label: 'Src'
    }),
    src_id: widget({
      label: 'Src Id'
    }),
    src_reference_id: widget({
      label: 'Src Reference Id'
    }),
    client: widget({
      label: 'Client'
    }, {
      display: true,
      searchable: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true,
      searchable: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true,
    }),
    type: widget({
      label: 'Type'
    }),
    amount: money({
      label: 'Amount',
    }, {
      display: true,
    }),
    effective_year: widget({
      label: 'Effective Year'
    }),
    effective_month: widget({
      label: 'Effective Month'
    }),
    effective_date: widget({
      label: 'Effective Date'
    }, {
      display: true,
    }),
    pmt_type: widget({
      label: 'Pmt Type'
    }),
    pmt_id: widget({
      label: 'Pmt Id'
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true,
      searchable: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true,
      searchable: true
    }),
    postal_address: widget({
      label: 'Postal Address'
    }),
    postal_city: widget({
      label: 'Postal City'
    }),
    postal_state: widget({
      label: 'Postal State'
    }),
    postal_zipcode: widget({
      label: 'Postal Zipcode'
    }),
    phone_number: widget({
      label: 'Phone Number'
    }),
    is_disputed: checkbox({
      label: 'Is Disputed'
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true,
      searchable: true
    }),
    subcampaign: widget({
      label: 'Subcampaign'
    }),
    src_client: widget({
      label: 'Src Client'
    }),
    src_campaign: widget({
      label: 'Src Campaign'
    }),
    update_locked: checkbox({
      label: 'Update Locked'
    }),
    hide_from_client_invoice: checkbox({
      label: 'Hide From Client Invoice'
    }),
    donor_table_updated: widget({
      label: 'Donor Table Updated'
    }),
    existing_donor: checkbox({
      label: 'Existing Donor'
    }),
    in_market: checkbox({
      label: 'In Market'
    }),
    existing_donor_display: widget({
      label: 'Existing Donor Display'
    }),
    in_market_display: widget({
      label: 'In Market Display'
    }),
    special_instructions: widget({
      label: 'Special Instructions'
    }),
    special_fund_notes: widget({
      label: 'Special Fund Notes'
    })
  }
})

data.selected_fields = [
  { units: 1, column: 'sys_id', display: data => <b>{data}</b> },
  { units: 1, column: 'client', display: data => <i>{data}</i> },
  { units: 2, column: 'campaign', display: data => <i>{data}</i> },
  { units: 1, column: 'amount', display: data => <b>{context.formatMoney(data)}</b> },
  { units: 2, column: 'first_name' },
  { units: 2, column: 'last_name' },
  { units: 2, column: 'email_address' },
]

export default data
