import * as React from 'react'
import useGlobal from 'store'
import {
  Box,
  Grid,
  TextField
} from '@mui/material'
import context from 'lib/context'
import {
  ActionButton
} from 'components'



const Metric = ({
  formName,
  fields,
  metric_number
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <img
            style={{ width: '70px' }} src={fields[`metric_${metric_number}_image`].widget.value}
            alt='metric identifier'
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          {fields[`metric_${metric_number}_header`].widget.value}
        </Grid>
        <Grid item xs={12} sm={4}>
          {fields[`metric_${metric_number}_label`].widget.value}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={`metric_${metric_number}_writein`}
          />
          <input
            type='hidden'
            name={`metric_${metric_number}_writein_label`}
            value={fields[`metric_${metric_number}_label`].widget.value}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { [formName]: form } = globalState.forms
  if (context.isUndefined(form)) return
  if (context.isUndefined(form.fields)) return

  const dataSet = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((metric_number) => {
    const { value } = form.fields[`metric_${metric_number}_label`].widget
    if (!value) { return null }
    return <>
      <Metric
        formName={formName}
        metric_number={metric_number}
        fields={form.fields}
      />
    </>
  })

  if (context.isUndefined(dataSet)) {
    return <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
        }}
      >
        There are no rally billing metrics to display
      </Box>
    </>
  }


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {dataSet}
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            data-handler='billing'
            data-action='do-rally-billing'
            data-table={form.table}
            data-command='download-billing-report'
            data-sys_id={form.fields.sys_id.widget.value}
            tooltip='Generate an excel report with preview of details from Rally'
            beforeCall={(event, extraParams) => {
              const inputs = document.querySelectorAll('input[name*=writein]')
              if (context.isUndefined(inputs)) return false
              const writein = {}
              for (const input of inputs) {
                if (input) {
                  // split the input name into two parts
                  const [metric, field] = input.name.split('_writein')
                  if (context.isUndefined(writein[metric])) {
                    writein[metric] = {}
                  }
                  writein[metric][field === '' ? 'value' : field] = input.value
                }
              }
              // iterate through writein and take the value of label and make it the key 
              // of a mapping and the value of value and make it the value of the mapping
              const metrics = {}
              for (const [, value] of Object.entries(writein)) {
                metrics[value._label] = value.value
              }
              extraParams['metrics'] = metrics
              return true
            }
            }
          >
            Download Preview
          </ActionButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            data-handler='billing'
            data-action='do-rally-billing'
            data-table={form.table}
            data-command='create-billing-objects'
            data-sys_id={form.fields.sys_id.widget.value}
            tooltip='Generate an excel report with preview of details from Rally'
            beforeCall={(event, extraParams) => {
              const inputs = document.querySelectorAll('input[name*=writein]')
              if (context.isUndefined(inputs)) return false
              const writein = {}
              for (const input of inputs) {
                if (input) {
                  // split the input name into two parts
                  const [metric, field] = input.name.split('_writein')
                  if (context.isUndefined(writein[metric])) {
                    writein[metric] = {}
                  }
                  writein[metric][field === '' ? 'value' : field] = input.value
                }
              }
              // iterate through writein and take the value of label and make it the key 
              // of a mapping and the value of value and make it the value of the mapping
              const metrics = {}
              for (const [, value] of Object.entries(writein)) {
                metrics[value._label] = value.value
              }
              extraParams['metrics'] = metrics
              return true
            }}
          >
            Create Billing Objects
          </ActionButton>
        </Grid>
      </Grid >
    </>
  )
}
