/**
 * Display a spinner during API calls. A semaphore value is incremented. If the semaphore value is > 0, display spinner.
 * @param {*} store
 * @param {*} title
 */
export const spinUp = (store, title, request) => {
  const { apiSpinner } = store.state.controls
  const newCount = ++apiSpinner.count
  const newState = newCount > 0
  const newTitle = [title, ...apiSpinner.title]
  const newRequest = [request, ...apiSpinner.request]
  store.actions.control.mergeState({
    controls: {
      apiSpinner: {
        state: newState,
        count: newCount,
        title: newTitle,
        request: newRequest,
      }
    }
  }, true)
}

/**
 * Hide a spinner after API call completes. A semaphore value is decremented.
 * If the semaphore value is > 0, continue to display spinner until the value reaches 0.
 * @param {*} store
 */
export const spinDown = (store) => {
  const { apiSpinner } = store.state.controls
  const newCount = --apiSpinner.count
  const newState = newCount > 0
  const newTitle = [...apiSpinner.title]
  newTitle.shift()
  const newRequest = [...apiSpinner.request]
  newRequest.shift()
  store.actions.control.mergeState({
    controls: {
      apiSpinner: {
        state: newState,
        count: newCount,
        title: newTitle,
        request: newRequest,
      }
    }
  }, true)
}

/**
 * Page orientation blah blah blah
 * @param {*} store
 * @param {*} tableName
 */
export const pageOrientation = (store, Form, List) => {
  const { layout } = store.state.controls

  const listattrs = { key: 1, id: 'list-scroll-point' }
  const formattrs = { key: 2, id: 'form-scroll-point' }
  const components = []

  if (!Form || !List) {
    if (Form) {
      formattrs.xs = 12
      components.push({
        Component: Form,
        attrs: formattrs
      })
    }
    if (List) {
      listattrs.xs = 12
      components.push({
        Component: List,
        attrs: listattrs
      })
    }
    return components
  }

  if (layout.direction === 'horizontal') {
    listattrs.xs = 6
    formattrs.xs = 6
  } else if (layout.direction === 'vertical') {
    listattrs.xs = 12
    formattrs.xs = 12
  } else {
    listattrs.lg = 12
    listattrs.xl = 6

    formattrs.lg = 12
    formattrs.xl = 6
  }

  switch (layout.orientation) {
    case 'form-list':
      components.push({
        Component: Form,
        attrs: formattrs
      })
      components.push({
        Component: List,
        attrs: listattrs
      })
      break
    case 'list-form':
      components.push({
        Component: List,
        attrs: listattrs
      })
      components.push({
        Component: Form,
        attrs: formattrs
      })
      break
    case 'form':
      components.push({
        component: Form,
        attrs: formattrs
      })
      break
    case 'list':
      components.push({
        Component: List,
        attrs: listattrs
      })
      break
    default:
      components.push({
        Component: List,
        attrs: listattrs
      })
      components.push({
        Component: Form,
        attrs: formattrs
      })
      break
  }
  return components
}
