import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'authorize_transactions'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    legacy_status: widget({
      label: 'Legacy Status'
    }, {
      display: true
    }),
    lastupdateddate: widget({
      label: 'Lastupdateddate'
    }, {
      display: true
    }),
    gatewayresponse: widget({
      label: 'Gatewayresponse'
    }, {
      display: true
    }),
    createddate: widget({
      label: 'Createddate'
    }, {
      display: true
    }),
    effective_month: widget({
      label: 'Effective Month'
    }, {
      display: true
    }),
    processeddate: widget({
      label: 'Processeddate'
    }, {
      display: true
    }),
    is_legacy: checkbox({
      label: 'Is Legacy'
    }, {
      display: true
    }),
    user_id: widget({
      label: 'User Id'
    }, {
      display: true
    }),
    effective_year: widget({
      label: 'Effective Year'
    }, {
      display: true
    }),
    amount: widget({
      label: 'Amount'
    }, {
      display: true
    }),
    date: widget({
      label: 'Date'
    }, {
      display: true
    }),
    traceback: widget({
      label: 'Traceback'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    month_transaction: widget({
      label: 'Month Transaction'
    }, {
      display: true
    }),
    reason_for_transaction: widget({
      label: 'Reason For Transaction'
    }, {
      display: true
    }),
    remote_payment_id: widget({
      label: 'Remote Payment Id'
    }, {
      display: true
    }),
    remote_profile_id: widget({
      label: 'Remote Profile Id'
    }, {
      display: true
    }),
    supported_id: widget({
      label: 'Supported Id'
    }, {
      display: true
    }),
    supported_name: widget({
      label: 'Supported Name'
    }, {
      display: true
    }),
    email_sent: checkbox({
      label: 'Email Sent'
    }, {
      display: true
    }),
    remote_authcode: widget({
      label: 'Remote Authcode'
    }, {
      display: true
    }),
    remote_responsecode: widget({
      label: 'Remote Responsecode'
    }, {
      display: true
    }),
    remote_testrequest: widget({
      label: 'Remote Testrequest'
    }, {
      display: true
    }),
    remote_transid: widget({
      label: 'Remote Transid'
    }, {
      display: true
    }),
    remote_message_code: widget({
      label: 'Remote Message Code'
    }, {
      display: true
    }),
    remote_message_text: widget({
      label: 'Remote Message Text'
    }, {
      display: true
    }),
    remote_error_code: widget({
      label: 'Remote Error Code'
    }, {
      display: true
    }),
    remote_error_text: widget({
      label: 'Remote Error Text'
    }, {
      display: true
    }),
    date_reconciled: widget({
      label: 'Date Reconciled'
    }, {
      display: true
    }),
    reconciled: checkbox({
      label: 'Reconciled'
    }, {
      display: true
    }),
    py_error: widget({
      label: 'Py Error'
    }, {
      display: true
    }),
    reconciliation_error: widget({
      label: 'Reconciliation Error'
    }, {
      display: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
    pyerror: widget({
      label: 'Pyerror'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    settled_amount: widget({
      label: 'Settled Amount'
    }, {
      display: true
    }),
    email: widget({
      label: 'Email'
    }, {
      display: true
    }),
    reconcile_response: widget({
      label: 'Reconcile Response'
    }, {
      display: true
    }),
    settlementtimelocal: widget({
      label: 'Settlementtimelocal'
    }, {
      display: true
    })
  }
})



export default data
