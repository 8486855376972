import * as React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import useGlobal from 'store'
import context from 'lib/context'

const Component = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line

  const [active, setActive] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [plaidTx, setPlaidTx] = React.useState(0)
  const [roundUp, setRoundUp] = React.useState(0)

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'users',
        params: {
          columns: [
            'count(*) as total',
            'SUM(CASE WHEN PLAID_ERROR IS NULL THEN 1 ELSE 0 END) as active'
          ],
          where: { status: 'active' }
        },
        result_format: 'raw'
      },
      stateReducer: (store, response) => {
        if (response && response.rows && response.rows[0]) {
          setActive(response.rows[0].active)
          setTotal(response.rows[0].total)
        }
        return {}
      },
    })
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'roundup',
        params: {
          columns: ['count(*), sum(roundup)'],
          where: `extract (month FROM effective_date) = extract (month FROM CURRENT_DATE) 
                  and extract (year FROM effective_date) = extract (year FROM CURRENT_DATE)`
        },
        result_format: 'raw'
      },
      stateReducer: (store, response) => {
        if (response && response.rows && response.rows[0]) {
          setPlaidTx(response.rows[0].count)
          setRoundUp(response.rows[0].sum)
        }
        return {}
      },
    })
  }, [globalActions.api])

  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent='space-between'
          spacing={3}
        >
          <Grid item>
            <Typography
              color='textSecondary'
              gutterBottom
              variant='h6'
            >
              SwipeGive - Current Month
            </Typography>
            <Typography
              color='textPrimary'
              variant='h3'
            >
              {active}/{total} active users
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >
              {context.formatMoney(plaidTx, 0)} plaid transactions
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >
              ${context.formatMoney(roundUp)} in roundups
            </Typography>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display='flex'
          alignItems='center'
        />
      </CardContent>
    </Card>
  )
}

export default Component
