import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'plaid_accounts'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    access_token: widget({
      label: 'Access Token'
    }),
    item_id: widget({
      label: 'Item ID'
    }),
    ID: widget({
      label: 'ID'
    }, {
      display: true
    }),
    name: widget({
      label: 'Account Name'
    }, {
      display: true
    }),
    mask: widget({
      label: 'Account Number'
    }, {
      display: true
    }),
    institution_name: widget({
      label: 'Bank Name'
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
  }
})



export default data
