import * as React from 'react'
import {
  Alert,
  Box
} from '@mui/material'
import _ from 'underscore'
import {
  QuestionMark,
  MoreInfo
} from 'components'
//import { useKeyValueData } from 'store/state'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {
  DragDropContext,
  Draggable,
  Droppable
} from '@hello-pangea/dnd'

const _onChange = _.debounce((event, func) => {
  func(event)
}, 500)

export default function Component({
  formName,
  onChange,
  value,
  moreInfo,
  headers,
  onValidate,
  ...props }) {

  // const [keyValueData, setKeyValueData] = useKeyValueData()
  // const {
  //   activeDrag,
  //   direction
  // } = keyValueData

  if (value === null) { value = '' }

  const onEvent = (event) => {
    _onChange(event, onChange)
  }
  let dndId = React.useMemo(() => {
    return Math.random().toString(36).substring(7)
  }, [])

  let obj = React.useMemo(() => {
    try {
      const newValue = '{' + value + '}'
      return JSON.parse(newValue)
    } catch (e) {
      console.log(e)
    }
    return {}
  }, [value])

  if (!headers) {
    headers = ['Key', 'Value']
  }



  return (
    <>
      <Box error-id={props.name}
        sx={{
          position: 'relative',
        }}>
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} size="small" >
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                {headers.map((header) => {
                  return (<TableCell key={header} align="center">{header}</TableCell>)
                })}
                <TableCell align="center">
                  <AddIcon
                    sx={{
                      cursor: 'default',
                      '&:hover': {
                        cursor: 'pointer'
                      },
                    }}
                    onClick={() => {
                      const newKey = window.prompt('Enter new key')
                      if (newKey) {
                        const newValue = window.prompt('Enter new value')
                        if (newValue) {
                          if (onValidate) {
                            const result = onValidate(newKey, newValue)
                            if (typeof result === 'string') {
                              alert(result)
                              return
                            }
                          }
                          obj[newKey] = newValue
                          let newValueString = JSON.stringify(obj)
                          newValueString = newValueString.replace('{', '').replace('}', '').replaceAll(',', ',\n')
                          onEvent({
                            target: {
                              name: props.name,
                              value: newValueString,
                              type: 'text'
                            }
                          }, onChange)
                        }
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <DragDropContext
              onDragEnd={(result) => {
                const { destination, source } = result
                if (!destination) {
                  return
                }
                if (destination.droppableId === source.droppableId && destination.index === source.index) {
                  return
                }
                const newOrder = Object.entries(obj)
                const entryToMove = newOrder[source.index]
                newOrder.splice(source.index, 1)
                newOrder.splice(destination.index, 0, entryToMove)
                const newObj = {}
                newOrder.map((entry, i) => { newObj[entry[0]] = entry[1]; return null })

                const newValueString = JSON.stringify(newObj).replace('{', '').replace('}', '').replaceAll(',', ',\n')

                onEvent({
                  target: {
                    name: props.name,
                    value: newValueString,
                    type: 'text'
                  }
                }, onChange)
              }}>
              <Droppable droppableId={dndId}>
                {(outer) => (
                  <TableBody
                    ref={outer.innerRef}
                    {...outer.droppableProps}
                  >
                    {Object.keys(obj).map((k, i) => {
                      return (
                        <Draggable
                          key={k}
                          draggableId={k}
                          index={i}
                        >
                          {(inner) => (
                            <TableRow
                              {...inner.draggableProps}
                              {...inner.dragHandleProps}
                              ref={inner.innerRef}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell
                                align="center"
                                sx={{ whiteSpace: 'nowrap' }}
                              >
                                <DragHandleIcon
                                  sx={{
                                    cursor: 'default',
                                    '&:hover': {
                                      cursor: 'grab'
                                    },
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">{k}</TableCell>
                              <TableCell align="center">{obj[k]}</TableCell>
                              <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                <EditIcon
                                  sx={{
                                    cursor: 'default',
                                    '&:hover': {
                                      cursor: 'pointer'
                                    },
                                  }}
                                  onClick={() => {
                                    const newKey = window.prompt('Enter new key', k)
                                    if (newKey) {
                                      const newValue = window.prompt('Enter new value', obj[k])
                                      if (newValue) {
                                        delete obj[k]
                                        obj[newKey] = newValue
                                        let newValueString = JSON.stringify(obj)
                                        newValueString = newValueString.replace('{', '').replace('}', '').replaceAll(',', ',\n')
                                        onEvent({
                                          target: {
                                            name: props.name,
                                            value: newValueString,
                                            type: 'text'
                                          }
                                        }, onChange)
                                      }
                                    }
                                  }}
                                />
                                &nbsp;
                                <DeleteIcon
                                  sx={{
                                    cursor: 'default',
                                    '&:hover': {
                                      cursor: 'pointer'
                                    },
                                  }}
                                  onClick={() => {
                                    delete obj[k]
                                    let newValueString = JSON.stringify(obj)
                                    newValueString = newValueString.replace('{', '').replace('}', '').replaceAll(',', ',\n')
                                    onEvent({
                                      target: {
                                        name: props.name,
                                        value: newValueString,
                                        type: 'text'
                                      }
                                    }, onChange)
                                  }} />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>)
                    })}
                    {outer.placeholder}
                  </TableBody>
                )
                }
              </Droppable>
            </DragDropContext>

          </Table>
        </TableContainer>
        {props.questionmark && <QuestionMark style={{
          position: 'absolute',
          top: -15,
          right: -15,
        }}>{props.questionmark}</QuestionMark>}
        {moreInfo && <MoreInfo>
          {moreInfo}
        </MoreInfo>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}
      </Box >
    </>
  )
}
