import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,
  Box,
} from '@mui/material'

import {
  CodeField,
  ActionButton,
  TextField
} from 'components'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.program_handle.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField
            {...fields.version.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            {...fields.last_saved_date.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item>
              <ActionButton
                data-handler='program'
                data-action='check-program'
                data-sys_id={fields.sys_id.widget.value}
                data-source_code={fields.source_code.widget.value}
                tooltip='Check syntax. Does not identify runtime errors'
              >
                Check Syntax
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='program'
                data-action='test-program'
                data-sys_id={fields.sys_id.widget.value}
                data-source_code={fields.source_code.widget.value}
                data-test_json={fields.test_json.widget.value}
                tooltip='Test the loaded program with the provided Test JSON'
                beforeCall={(event, extraParams) => {
                  globalActions.control.mergeState({
                    test_result: ''
                  }, true)
                  props.setTabState(3)
                  return true
                }}
              >
                Test Program
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='program'
                data-action='diff-with-original'
                data-sys_id={fields.sys_id.widget.value}
                data-source_code={fields.source_code.widget.value}
                data-last_saved_date={fields.last_saved_date.widget.value}
                tooltip='Diff the current saved program with the program in the same database'
                beforeCall={(event, extraParams) => {
                  globalActions.control.mergeState({
                    test_result: ''
                  }, true)
                  props.setTabState(3)
                  return true
                }}
              >
                Diff with Saved Version
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                data-handler='program'
                data-action='diff-with-alternate'
                data-sys_id={fields.sys_id.widget.value}
                data-source_code={fields.source_code.widget.value}
                data-last_saved_date={fields.last_saved_date.widget.value}
                tooltip='Diff the current program with the program in the alternate database'
                beforeCall={(event, extraParams) => {
                  globalActions.control.mergeState({
                    test_result: ''
                  }, true)
                  props.setTabState(3)
                  return true
                }}
              >
                Diff with Other DB
              </ActionButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 20 }} />
            <CodeField
              {...fields.source_code.widget}
              onChange={onEvent}
              height='40vh'
              defaultLanguage='python'
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </>
  )
}
