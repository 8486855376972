import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid, Typography } from '@mui/material'
import {
  DirtyForm,
  SystemFields,
  SaveButtons,
  TextField
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }



  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Rally Metrics'
          >
            <Typography>
              Instructions: A Rally Metric Instance object defines what the donor
              has signed up for.The Rally Metric Instance object is evaluated at
              billing time to determine how much a donor will pay for the billing period.
            </Typography>
            <Typography>
              Client ID:<br />
              Campaign ID:<br />
              Email Address:<br />
              Metric: A description that indicates what the metric is for.<br />
              Value: The individual metric value that is multiplied by the number of metric events for the billing period.<br />
              Pledge Cap: The cap the donor will pay in a given month accross all metrics.<br />
            </Typography>
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.campaign_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.email_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.special_fund.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.value.widget}
            onChange={onEvent}
          />
        </Grid>   <Grid item xs={12} sm={6}>
          <TextField
            {...fields.monthly_pledge_cap.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
