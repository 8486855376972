import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'images'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    filter: {
      '!%key': '%-thumb%'
    },
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'client, key',
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    thumbnail_key: widget({
      label: 'Thumb',
    }, {
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        const url = 'https://s3.amazonaws.com/donate.resources/' + value
        return <>
          <img alt="nonprofit" style={{ maxWidth: '128px' }} src={url} />
        </>
      },
      searchable: false
    }),
    url: widget({
      label: 'URL'
    }, {
      anonymous: true,
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return <>
          {'https://s3.amazonaws.com/' + row.bucket + '/' + row.key}
        </>
      },
    }),
    type: widget({
      label: 'Type'
    }, {
      searchable: true
    }),
    name: widget({
      label: 'Name'
    }, {
      searchable: true
    }),
    filesize: widget({
      label: 'File Size'
    }),
    height: widget({
      label: 'Height'
    }),
    width: widget({
      label: 'Width'
    }),
    bucket: widget({
      label: 'Bucket'
    }, {
      searchable: true,
      fetch: true
    }),
    key: widget({
      label: 'S3 Key'
    }, {
      searchable: true,
      fetch: true
    }),
    client: widget({
      label: 'Client'
    }, {
      display: true,
      searchable: true,
      fetch: true
    }),
  }
})



export default data
