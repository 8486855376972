import * as React from 'react'
import {
  Alert,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Radio,
} from '@mui/material'
import context from 'lib/context'

export const Component = (props) => {
  const id = props.id || props.name
  const name = props.name || props.id

  return (
    <>
      <span error-id={name} />
      <FormControl component='fieldset' fullWidth>
        {props.label && <FormLabel component='legend'>{props.label}</FormLabel>}
        {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>* Required</span>}
        <FormGroup>
          <RadioGroup
            defaultValue={props.options[0]}
            name={name}
            id={id}
            onChange={props.onChange}
            title={name}
            row={props.row}
            value={props.value}
          >
            {props.options.map(entry => {
              if (context.isString(entry)) {
                return <FormControlLabel key={entry} value={entry} control={<Radio />} label={entry} />
              } else {
                return <FormControlLabel key={entry.value} value={entry.value} control={<Radio />} label={entry.label} />
              }
            })}
          </RadioGroup>
        </FormGroup>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
