import * as React from 'react'
import {
  AppBar,
  Toolbar,
  Slide,
  useScrollTrigger
} from '@mui/material'

function HideOnScroll ({ children, window }) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}


export default function ScrollingToolbar ({ children, ...props }) {
  return (
    <>
      <HideOnScroll {...props}>
        <AppBar sx={{
          backgroundColor: 'white',
          color: 'primary.main',
          top: '48px',
          left: '256px'
        }}>
          <Toolbar>
            {children}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  )
}
