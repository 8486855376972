import * as React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import useGlobal from 'store'
import moment from 'moment'


const Component = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'roundup',
        params: {
          columns: ['count(*), sum(roundup)'],
          where: `extract (month FROM effective_date) = extract (month FROM CURRENT_DATE - interval '1 month')
                  and extract (year FROM effective_date) = extract (year FROM CURRENT_DATE - interval '1 month')`
        },
        result_format: 'raw'
      },
      stateReducer: (store, response) => {
        if (response && response.rows && response.rows[0]) {

        }
        return {}
      },
    })
  }, [globalActions.api])


  return (
    <Card
      sx={{
        height: '100%'
      }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent='space-between'
          spacing={3}
        >
          <Grid item>
            <Typography
              color='textSecondary'
              gutterBottom
              variant='h6'
            >
              Previous Billing Period
            </Typography>
            <Typography
              color='textPrimary'
              variant='h3'
            >
              {moment(firstDay).format('MM/DD/YYYY')} - {moment(lastDay).format('MM/DD/YYYY')}
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >
              This is where stats will go
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >

            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12}>


              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display='flex'
          alignItems='center'
        />
      </CardContent>
    </Card >
  )
}

export default Component
