import * as React from 'react'
import {
  FormLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material'
import { Alert } from '@mui/material'

export const Component = (props) => {
  const id = props.id || props.name
  const name = props.name || props.id

  return (
    <>
      <span error-id={name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend'>
          {props.label}    {
            props.required &&
            <span style={{ color: 'red', fontSize: '.8em' }}>* Required</span>
          }
        </FormLabel>

        <Select
          id={id}
          name={name}
          value={props.value || ''}
          onChange={props.onChange}
          fullWidth
          error={Boolean(props.error)}
          color={props.color}
        >
          {
            Object.entries(props.values).map(([key, val]) => {
              return <MenuItem key={key} value={key}>{val}</MenuItem>
            })
          }
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error &&
          <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
