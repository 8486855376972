import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'aws_api_activity'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    action: widget({
      label: 'Action'
    }, {
      display: true
    }),
    args: widget({
      label: 'Args'
    }, {
      display: true
    }),
    authentication_provider: widget({
      label: 'Authentication Provider'
    }, {
      display: true
    }),
    authentication_type: widget({
      label: 'Authentication Type'
    }, {
      display: true
    }),
    cognito_user: widget({
      label: 'Cognito User'
    }, {
      display: true
    }),
    is_desktop: checkbox({
      label: 'Is Desktop'
    }, {
      display: true
    }),
    is_mobile: checkbox({
      label: 'Is Mobile'
    }, {
      display: true
    }),
    is_smart_tv: checkbox({
      label: 'Is Smart Tv'
    }, {
      display: true
    }),
    is_tablet: checkbox({
      label: 'Is Tablet'
    }, {
      display: true
    }),
    origin: widget({
      label: 'Origin'
    }, {
      display: true
    }),
    path: widget({
      label: 'Path'
    }, {
      display: true
    }),
    referer: widget({
      label: 'Referer'
    }, {
      display: true
    }),
    source_ip: widget({
      label: 'Source Ip'
    }, {
      display: true
    }),
    user_agent: widget({
      label: 'User Agent'
    }, {
      display: true
    }),
    duration: widget({
      label: 'Duration'
    }, {
      display: true
    }),
    device_type: widget({
      label: 'Device Type'
    }, {
      display: true
    }),
    exception: widget({
      label: 'Exception'
    }, {
      display: true
    }),
    traceback: widget({
      label: 'Traceback'
    }, {
      display: true
    })
  }
})



export default data
