import * as React from 'react'

import {
  Grid,
  Typography,
  Link,
  Container
} from '@mui/material'

import {
  //ServiceFeesPopup,
  PrivacyPolicyPopup,
  TermsOfServicePopup
} from 'views'

import { Copyright } from 'components'

const footers = [
  {
    title: 'Services',
    children: [
      { key: 'services-1', label: 'Methodology', link: 'https://www.caringcent.com/methodology/' },
      { key: 'services-2', label: 'How We Partner', link: 'https://www.caringcent.com/how-we-partner/' },
      { key: 'services-3', label: 'Partnership Case Studies', link: 'https://www.caringcent.com/examples/' },
    ],
  },
  {
    title: 'Company',
    children: [
      { key: 'company-1', label: 'Our Story', link: 'https://www.caringcent.com/our-story/' },
      { key: 'company-2', label: 'News and Media', link: 'https://www.caringcent.com/media-coverage/' },
    ],
  },
  // {
  //   title: 'Resources',
  //   children: [
  //     { label: 'Resource', link: '#' },
  //     { label: 'Resource name', link: '#' },
  //     { label: 'Another resource', link: '#' },
  //     { label: 'Final resource', link: '#' }
  //   ],
  // },
  {
    title: 'Legal',
    children: [
      { key: 'legal-1', label: <TermsOfServicePopup />, link: '#' },
      { key: 'legal-2', label: <PrivacyPolicyPopup />, link: '#' },
      //{ key: 'legal-3', label: <ServiceFeesPopup />, link: '#' }
    ],
  },
]

export default function Component (props) {
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        component='footer'
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        {...props}
      >
        <Grid container spacing={4} justifyContent='space-evenly'>
          {footers.map((ft) => (
            <Grid item xs={6} sm={3} key={ft.title}>
              <Typography variant='h6' color='text.primary' gutterBottom>
                {ft.title}
              </Typography>
              <ul>
                {ft.children.map((item) => (
                  <li key={item.key}>
                    {item.link ? <>
                      <Link href={item.link} variant='subtitle1' color='text.secondary'>
                        {item.label}
                      </Link></> : <>
                      {item.label}
                    </>}
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>

    </React.Fragment>
  )
}