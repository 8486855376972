import * as React from 'react'
import { ScrollingToolbar } from 'components'
import * as Buttons from 'components/ToolbarButtons'

const ToolBar = ({
  pageorientation,
  calendar,
  listview,
  form,
  inspector,
  formbuilder,
  ...props
}) => {

  return (
    <>
      <ScrollingToolbar>
        {pageorientation && <Buttons.PageOrientation  {...props} />}
        {calendar && <Buttons.Calendar   {...props} />}
        {form && <Buttons.Form {...props} />}
        {listview && <Buttons.ListView {...props} />}
        {inspector && <Buttons.Inspector {...props} />}
        {formbuilder && <Buttons.FormBuilder {...props} />}
      </ScrollingToolbar>
    </>
  )
}

export default ToolBar
