import * as React from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export default function SimplePopper ({ label = 'more info...', style = {}, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const innerStyle = {
    position: 'absolute',
    right: 20,
    top: 0,
    cursor: 'pointer',
    color: 'blue',
    fontSize: '80%',
    ...style
  }
  return (
    <ClickAwayListener onClickAway={handleClick}>
      <Box
        sx={innerStyle}
        onClick={handleClick}>
        {label}
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
            {props.children}
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener >
  )
}