import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
  money,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'month_transactions'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client_id: widget({
      label: 'Client Id'
    }, {
      display: true,
      searchable: true
    }),
    campaign_id: widget({
      label: 'Campaign Id'
    }, {
      display: true,
      searchable: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true,
      searchable: true
    }),
    carryover: widget({
      label: 'Carryover'
    }),
    chargingamount: money({
      label: 'Charging Amount'
    }, {
      display: true
    }),
    effective_month: widget({
      label: 'Effective Month'
    }, {
      display: true
    }),
    nextmonthcarryover: widget({
      label: 'Nextmonthcarryover'
    }),
    totalcontribution: widget({
      label: 'Totalcontribution'
    }),
    user_id: widget({
      label: 'User Id'
    }),
    effective_year: widget({
      label: 'Effective Year'
    }, {
      display: true
    }),
    createddate: widget({
      label: 'Createddate'
    }),
    lastupdateddate: widget({
      label: 'Lastupdateddate'
    }),
    state: widget({
      label: 'State'
    }),
    isdeleted: checkbox({
      label: 'Isdeleted'
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    supported_id: widget({
      label: 'Supported Id'
    }),
    supported_name: widget({
      label: 'Supported Name'
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
    tx_type: widget({
      label: 'Tx Type'
    }, {
      display: true
    }),
    email_sent: checkbox({
      label: 'Email Sent'
    }),
    test_account: widget({
      label: 'Test Account'
    }),
    notes: widget({
      label: 'Line Notes'
    }, {
      display: true,
      searchable: true
    }),
  }
})



export default data
