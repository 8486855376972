import context from 'lib/context'

/**
 * @name setTable (store, action)
 * @param store
 * @param action
 * @description  Process a table event. Generate a new state by passing the action through setTableReducer. Merge the resultant state into the datastore.
 */
export const setTable = async (store, action) => {
  const newState = await setTableReducer(store, action)
  if (context.isObject(newState)) {
    store.actions.control.mergeState(newState, true)
  }
}

/**
 * @name setTableReducer (store, action)
 * @description  Process a table event. This is where the event actually gets handled. Single and multi-row CRUD operations,
 * row clicks, bulk operations, etc. on tables get handled here.
 * @param {*} store
 * @param {*} action
 * @return {*} the new state of the table after handling the action
 */
const setTableReducer = async (store, action) => {
  const form = store.state.forms[action.formName]
  const {
    rows = [],
    config = {}
  } = store.state.repo[action.tableName]
  context.log(`tables.action.${action.type}`, action)

  switch (action.type) {
    case 'selectionChange':
      config.curRowSelected = action.curRowSelected
      config.allRowsSelected = action.allRowsSelected
      config.rowsSelected = action.rowsSelected
      config.selected = config.allRowsSelected.map(i => rows[i.dataIndex])
      break
    case 'deleteRows':
      try {
        await context.okCancelPopup(
          <>
            This action will delete selected objects. Deleting objects permenant
            and there is no backup. Are you sure you want to delete these objects?
          </>,
          'Permenantly delete objects?'
        )
      } catch {
        store.actions.control.Notify('Delete canceled')
        return
      }
      config.selectedRows = action.selectedRows
      config.displayData = action.displayData
      store.actions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'delete-object',
        apiPayload: {
          tableName: form.table,
          deleteList: action.selectedRows.data.map((item) => action.displayData[item.index].data[0])
        },
        spinner: {
          content: 'Deleting Data. Please Wait...'
        },
        callback: (store, response) => {
          if ('afterDelete' in form.functions) {
            store.actions.control.publish('callAfter', { func: form.functions.afterDelete, params: [store, form, response] })
          }
          store.actions.control.publish('setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
          return store.actions.control.handleResponse(response)
        }
      })
      break
    case 'moveRows':
      try {
        await context.okCancelPopup(
          <>
            This action will move the selected objects to the other system.
            Moving objects will overwrite the existing objects in other system with same system id.
            This is permenant and there is no backup.
            Are you sure you want to delete these objects?
          </>,
          'Move and overwrite objects?'
        )
      } catch {
        store.actions.control.Notify('Action canceled')
        return
      }
      config.selectedRows = action.selectedRows
      config.displayData = action.displayData
      store.actions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'push-to-alternate',
        apiPayload: {
          table: form.table,
          moveRows: action.selectedRows.data.map((item) => action.displayData[item.index].data[0])
        },
        spinner: {
          content: 'Moving Data. Please Wait...'
        },
        callback: (store, response) => {
          store.actions.control.publish('callAfter', { func: store.actions.control.reloadTable, params: [store, action.formName] })
          return store.actions.control.handleResponse(response)
        }
      })
      break
    case 'rowClick':
      if (form.dirty) {
        context.alertPopup('The current form has changes. Save or clear the current form first.', 'Stop')
        return
      }
      config.rowData = action.rowData
      config.rowMeta = action.rowMeta
      store.actions.control.publish('setForm', {
        action: {
          formName: action.formName,
          type: 'button',
          name: '@clear'
        }
      })
      store.actions.control.publish('setForm', {
        action: {
          formName: action.formName,
          type: 'button',
          name: '@load',
          value: context.getCellData(action.rowData, form.sysIdx)
        }
      })
      config.current_index = action.rowMeta.dataIndex
      break
    case 'updateRows':
      try {
        await context.okCancelPopup(
          <>
            This action will update all selected objects. Updating objects is permenant
            and there is no backup. Are you sure you want to update these objects?
          </>,
          'Warning -- Are you sure?'
        )
      } catch {
        store.actions.control.Notify('Action canceled')
        return
      }
      config.selectedRows = action.selectedRows
      config.displayData = action.displayData
      store.actions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'update-rows',
        apiPayload: {
          table: form.table,
          updateRows: action.selectedRows.data.map((item) => action.displayData[item.index].data[0]),
          updateData: action.updateData
        },
        spinner: {
          content: 'Updating Data. Please Wait...'
        },
        callback: (store, response) => {
          if ('afterSave' in form.functions) {
            store.actions.control.publish('callAfter', { func: form.functions.afterSave, params: [store, form, response] })
          }
          return store.actions.control.handleResponse(response)
        }
      })
      break
    default:
      break
  }
  return {
    repo: {
      [action.tableName]: {
        config
      }
    }
  }
}
