import * as React from 'react'
import {
    Container,
    Grid,
} from '@mui/material'
import {
    Header
} from 'components'
import ToolBar from '../ToolBar'
import ReactMarkdown from 'react-markdown'

const Dashboard = () => {

    return (
        <>
            <Header label='Documentation' title='Documentation' />
            <ToolBar links={[
                { label: 'Home', link: '/documentation/home' },
                { label: 'Developers', link: '/documentation/developers' },
                { label: 'GIT Repositories', link: '/documentation/git_repos' },
            ]} />
            <Container maxWidth={false} sx={{ pt: 3 }}>
                <Grid
                    container
                    spacing={3}
                >

                    <Grid item xs={12}>
                        <ReactMarkdown>
                            {markdown}
                        </ReactMarkdown>



                    </Grid>

                </Grid>
            </Container>
        </>
    )
}

export default Dashboard
const markdown = `
  Git Repositories

    Git repositories are lcoated in aws codecommit

    they are linked to amplify projects, and are deployed to amplify environments via the amplify cli.
    there are build projects (pipelines) for the python libraries that are used in the lambda functions. they are deployed to the lambda layers.

    BackOffice
    npm run build-demo
    npm run build-production

    CCDonate

    DonateMain

    admin.caringcent.org

    caringcent-donate

    clients.caringcent.org

    dev_command_line_tools

    developer

    donate.caringcent.com

    donors.caringcent.org

    py-lib-accounting-lambda-layer

    py-lib-iplookup-lambda-layer

    py-lib-support-lambda-layer


    py-lib-velocity-lambda-layer

    secrets
    `