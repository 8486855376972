import * as React from 'react'
import useGlobal from 'store'
import {
  Box
} from '@mui/material'

import {
  FactCheck,
  ViewList,
} from '@mui/icons-material'
import * as defaults from './defaults'

import { MenuButton } from 'components'

const Component = ({ formName, ...props }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onClick = (event) => {
    let data
    let current = event.target
    while (current) {
      data = current.dataset
      if (data && data.orientation) {
        globalActions.control.mergeState({
          controls: {
            layout: Object.assign({}, data)
          }
        }, true)
        globalActions.control.setUserData({
          'controls.layout': {
            controls: {
              layout: Object.assign({}, data)
            }
          }
        })
        break
      }
      current = current.parentNode
    }
  }


  return (
    <>
      <Box
        title="List+Form View"
        sx={{
          ...defaults.MenuButtonStyle,
          width: '130px'
        }}>
        <MenuButton
          formName={formName}
          label='Display'
          size='medium'
          showBrackets
          menuItems={[
            {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='horizontal'
                  data-orientation='form-list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <FactCheck fontSize='inherit' />
                  <ViewList fontSize='inherit' />
                </Box>
                <Box
                  onClick={onClick}
                  data-direction='horizontal'
                  data-orientation='form-list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <Box>Form</Box>
                  <Box sx={{ p: .5 }}>&middot;</Box>
                  <Box>ListView</Box>
                </Box>
              </>,
              name: 'horz-form-list',
              tooltip: 'Show form on left, list on right'
            }, {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='horizontal'
                  data-orientation='list-form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <ViewList fontSize='inherit' />
                  <FactCheck fontSize='inherit' />
                </Box>
                <Box
                  onClick={onClick}
                  data-direction='horizontal'
                  data-orientation='list-form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <Box>ListView</Box>
                  <Box sx={{ p: .5 }}>&middot;</Box>
                  <Box>Form</Box>
                </Box>
              </>,
              name: 'horz-list-form',
              tooltip: 'Show list on left, form on right'
            }, {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='form-list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <FactCheck fontSize='inherit' />
                  <ViewList fontSize='inherit' />
                </Box>
                <Box
                  sx={{ p: 0, m: 0 }}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='form-list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <Box sx={{ position: 'relative', top: 12 }}>Form</Box>
                  <Box >&middot;</Box>
                  <Box sx={{ position: 'relative', bottom: 12 }}>ListView</Box>
                </Box>
              </>,
              name: 'vert-form-list',
              tooltip: 'Show form on top, list on bottom'
            }, {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='list-form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <ViewList fontSize='inherit' />
                  <FactCheck fontSize='inherit' />
                </Box>
                <Box
                  sx={{ p: 0, m: 0 }}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='list-form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <Box sx={{ position: 'relative', top: 12 }}>ListView</Box>
                  <Box >&middot;</Box>
                  <Box sx={{ position: 'relative', bottom: 12 }}>Form</Box>
                </Box>
              </>,
              name: 'vert-list-form',
              tooltip: 'Show list on top, form on bottom'
            }, {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <FactCheck fontSize='inherit' />
                </Box>
                <Box
                  sx={{ p: 0, m: 0 }}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='form'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <Box>Form Only</Box>
                </Box>
              </>,
              name: 'form',
              tooltip: 'Show form only'
            }, {
              label: <>
                <Box
                  sx={defaults.ButtonStyle}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                >
                  <ViewList fontSize='inherit' />
                </Box>
                <Box
                  sx={{ p: 0, m: 0 }}
                  onClick={onClick}
                  data-direction='vertical'
                  data-orientation='list'
                  justifyContent='center'
                  alignItems='center'
                  display='flex'
                  flexDirection="column"
                >
                  <Box>ListView Only</Box>
                </Box>
              </>,
              name: 'list',
              tooltip: 'Show list only'
            }
          ]}
        />
      </Box>
    </>
  )
}

export default Component
