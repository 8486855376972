import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Container } from '@mui/material'

const Page = ({
  children,
  title = '',
  ...props
}) => {
  return (
    <>
      <Container
        sx={{
          minHeight: '100%',
          paddingBottom: 3,
          paddingTop: 3
        }}
        widget='Page'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          {...props}
        >
          <Helmet>
            <title>{title} - CaringCent LLC - Your national partner for College Athletics, Fund Raising, and Donor Acquisition</title>
          </Helmet>
          {children}
        </Box>
      </Container>
    </>
  )
}

export default Page
