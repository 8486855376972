import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid, Typography } from '@mui/material'
import {
  DirtyForm,
  TextField,
  SystemFields,
  SaveButtons,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields formName={formName} title='Client'>
            <Typography>
              Instructions: A Client object defines a client of CaringCent.
              A client has a one-to-many relation to campaigns.
              Client is an integral part of the billing process.
            </Typography>
            <Typography>
              Example: Client ID: OSU, Client Name: Oregon State University
            </Typography>
            <Typography>
              Client ID:<br />
              Client Name:<br />
            </Typography>
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.client_id.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            {...fields.key.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            {...fields.val.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
