import * as React from 'react'
import {
  Typography,
  Box,
  Grid
} from '@mui/material'

export default function BackgroundColor () {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='primary.main' color='primary.contrastText' p={2}>
          primary.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='primary.light' color='primary.contrastText' p={2}>
          primary.light
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='primary.dark' color='primary.contrastText' p={2}>
          primary.dark
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='secondary.main' color='secondary.contrastText' p={2}>
          secondary.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='secondary.light' color='secondary.contrastText' p={2}>
          secondary.light
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='secondary.dark' color='secondary.contrastText' p={2}>
          secondary.dark
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='text.primary' color='background.paper' p={2}>
          text.primary
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='text.secondary' color='background.paper' p={2}>
          text.secondary
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box bgcolor='text.disabled' color='background.paper' p={2}>
          text.disabled
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box bgcolor='error.main' color='error.contrastText' p={2}>
          error.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box bgcolor='warning.main' color='warning.contrastText' p={2}>
          warning.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box bgcolor='info.main' color='info.contrastText' p={2}>
          info.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box bgcolor='success.main' color='success.contrastText' p={2}>
          success.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h1'>H1. Testing </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h2'>H2. Testing </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h3'>H3. Testing </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h4'>H4. Testing </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h5'>H5. Testing </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>H6. Testing </Typography>
      </Grid>
    </Grid>
  )
}
