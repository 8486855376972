import * as React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { context } from 'lib/context'
import useGlobal from 'store'


const TotalDonations = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line

  const [totalTx, setTotalTx] = React.useState(0)
  const [totalDollar, setTotalDollar] = React.useState(0)
  const [monthTx, setMonthTx] = React.useState(0)
  const [monthDollar, setMonthDollar] = React.useState(0)

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'transactions',
        params: {
          columns: [
            'count(*)',
            'status',
            'SUM(amount)'
          ],
          where: { status: ['Disputed'] },
          groupby: 'status'
        },
        result_format: 'raw'
      },
      stateReducer: (store, response) => {
        if (response && response.rows) {
          response.rows.forEach(item => {
            switch (item.status) {
              case 'Disputed':
                setTotalTx(item.count)
                setTotalDollar(item.sum)
                break
              case 'Dispute Won':
                break
              case 'Approved':
                break
              case 'Refunded':
                break
              default:
                break
            }
          })
        }
        return {}
      },
    })
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'transactions',
        params: {
          columns: [
            'count(*)',
            'status',
            'SUM(amount)'
          ],
          groupby: 'status',
          where: `status in ('Disputed')
              and extract (month FROM effective_date) = extract (month FROM CURRENT_DATE) 
              and extract (year FROM effective_date) = extract (year FROM CURRENT_DATE)`
        },
        result_format: 'raw'
      },
      stateReducer: (store, response) => {
        if (response && response.rows) {
          response.rows.forEach(item => {
            switch (item.status) {
              case 'Disputed':
                setMonthTx(item.count)
                setMonthDollar(item.sum)
                break
              case 'Dispute Won':
                break
              case 'Approved':
                break
              case 'Refunded':
                break
              default:
                break
            }
          })
        }
        return {}
      },
    })

  }, [globalActions.api])

  return (
    <Card
      sx={{
        height: '100%'
      }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent='space-between'
          spacing={3}
        >

          <Grid item>
            <Typography
              color='textSecondary'
              gutterBottom
              variant='h6'
            >
              DISPUTED DONATIONS
            </Typography>
            <Typography
              color='textPrimary'
              variant='h3'
            >
              ${context.formatMoney(totalDollar)} ({context.formatMoney(totalTx, 0)})
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              color='textSecondary'
              gutterBottom
              variant='h6'
            >
              THIS MONTH
            </Typography>
            <Typography
              color='textPrimary'
              variant='h3'
            >
              ${context.formatMoney(monthDollar)} ({context.formatMoney(monthTx, 0)})
            </Typography>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

export default TotalDonations
