import * as React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useGlobal from 'store'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material'
import ClientDropDown from 'components/filters/ClientDropDown'

export default function SelectedRowActions({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Accordion
          onChange={(event, expanded) => {
            setExpanded(expanded)
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>
              Filters
            </Typography>
            {!expanded &&
              <Typography
                variant='caption'
                sx={{ color: '#E5E4E2', ml: 4 }}
              >
                (Click to Expand)
              </Typography>
            }
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ClientDropDown
                    Label='By Client'
                    formName={formName}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <MuiTextField
                        value='Amount'
                      />
                    </Grid>
                    <Grid item>
                      <EqualityString></EqualityString>
                    </Grid>
                    <Grid item>
                      <MuiTextField />
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <MuiTextField
                        value='Status'
                      />
                    </Grid>
                    <Grid item>
                      <EqualityString></EqualityString>
                    </Grid>
                    <Grid item>
                      <MuiTextField />
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
