import * as React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'


const NotFoundView = () => {

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'
    >
      <Typography
        align='center'
        color='textPrimary'
        variant='h1'
      >
        404: The page you are looking for isn’t here
      </Typography>
      <Typography
        align='center'
        color='textPrimary'
        variant='subtitle2'
      >
        This site is under constructions, so you either tried some  route that isn't built yet or you came here by mistake.
        Whichever it is, try using the navigation to go somewhere else!
      </Typography>
      <Box textAlign='center'>
        <img
          alt='Under development'
          style={{
            mt: 50,
            display: 'inline-block',
            maxWidth: '100%',
            width: 560
          }}
          src='https://s3.amazonaws.com/donate.resources/cc/CaringCentLogo_College-Atheltics_logo.webp'
        />
      </Box>
    </Box>
  )
}

export default NotFoundView
