import * as React from 'react'
import {
  Box,
  Popper
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export default function SimplePopper ({ style = {}, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState()
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const innerStyle = {
    cursor: 'pointer',
    ...style
  }
  return (
    <ClickAwayListener onClickAway={handleClick}>
      <Box
        sx={innerStyle}
        onClick={handleClick}>
        <HelpOutlineIcon
          sx={{
            width: '.8em',
            height: '.8em'
          }}
        />
        <Popper open={open} anchorEl={anchorEl}>
          <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
            {props.children}
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener >
  )
}