import * as React from 'react'
import {
  Box, Button, Card, CardContent, CardHeader, Divider,
  TextField as MuiTextField
} from '@mui/material'


const Password = ({ ...props }) => {
  const [values, setValues] = React.useState({
    password: '',
    confirm: ''
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  return (
    <form
      {...props}
    >
      <Card>
        <CardHeader
          subheader='Update password'
          title='Password'
        />
        <Divider />
        <CardContent>
          <MuiTextField
            fullWidth
            label='Password'
            margin='normal'
            name='password'
            onChange={handleChange}
            type='password'
            value={values.password}
            variant='outlined'
          />
          <MuiTextField
            fullWidth
            label='Confirm password'
            margin='normal'
            name='confirm'
            onChange={handleChange}
            type='password'
            value={values.confirm}
            variant='outlined'
          />
        </CardContent>
        <Divider />
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}
        >
          <Button
            color='primary'
            variant='contained'
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default Password
