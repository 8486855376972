import * as React from 'react'
import useGlobal from 'store'
import {
  Grid
} from '@mui/material'

import {
  Image,
  TextField,
  Color,
  Checkbox
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.header_banner_text.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Image
            {...fields.header_banner_image_url.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Image
            {...fields.background_image_url.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Color
            {...fields.background_color.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.background_css.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Image
            {...fields.main_image_url.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.body_header_text.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Image
            {...fields.body_header_url.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.body_message_text.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.campaign_goal_dollars.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.campaign_start_dollars.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.campaign_goal_donors.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.campaign_start_donors.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.special_fund_dropdown.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Checkbox
            {...fields.show_special_fund_writein.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>
    </>
  )
}
