import * as React from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import useGlobal from 'store'
import context from 'lib/context'
import moment from 'moment'
import {
  ActionButton,
} from 'components'

const Component = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line

  const [plaidTx, setPlaidTx] = React.useState(0)
  const [roundUp, setRoundUp] = React.useState(0)

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)
  const payload = {
    //emailAddress: 'paul@paulperez.net',
    effective_year: firstDay.getFullYear(),
    effective_month: firstDay.getMonth() + 1,
    bt_start_date: moment(firstDay).format('MM/DD/YYYY'),
    bt_end_date: moment(lastDay).format('MM/DD/YYYY'),
    an_start_date: moment(firstDay).format('MM/DD/YYYY'),
    an_end_date: moment(lastDay).format('MM/DD/YYYY')
  }
  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: 'roundup',
        params: {
          columns: ['count(*), sum(roundup)'],
          where: `extract (month FROM effective_date) = extract (month FROM CURRENT_DATE - interval '1 month') 
                  and extract (year FROM effective_date) = extract (year FROM CURRENT_DATE - interval '1 month')`
        },
        result_format: 'raw',
      },
      stateReducer: (store, response) => {
        if (response && response.rows && response.rows[0]) {
          setPlaidTx(response.rows[0].count)
          setRoundUp(response.rows[0].sum)
        }
        return {}
      },
    })
  }, [globalActions.api])


  return (
    <Card
      sx={{
        height: '100%'
      }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent='space-between'
          spacing={3}
        >
          <Grid item>
            <Typography
              color='textSecondary'
              gutterBottom
              variant='h6'
            >
              Previous Billing Period
            </Typography>
            <Typography
              color='textPrimary'
              variant='h3'
            >
              {moment(firstDay).format('MM/DD/YYYY')} - {moment(lastDay).format('MM/DD/YYYY')}
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >
              {context.formatMoney(plaidTx, 0)} plaid transactions
            </Typography>
            <br />
            <Typography
              color='textPrimary'
              variant='h4'
            >
              ${context.formatMoney(roundUp)} in roundups
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ActionButton
                  data-handler='reports'
                  data-action='generate-swipe-give-report'
                  data-payload={context.to_json(payload)}
                  tooltip='Download the preliminary swipegive billing report for this period'
                >
                  Prelim SwipeGive Report
                </ActionButton>
              </Grid>
              <Grid item xs={12}>
                <ActionButton
                  data-handler='reports'
                  data-action='generate-internal-billing-report'
                  data-payload={context.to_json(payload)}
                  tooltip='Download the internal report for this billing period. The internal report contains extra information that the client report does not contain'
                >
                  Internal Billing Report
                </ActionButton>
              </Grid>
              <Grid item xs={12}>
                <ActionButton
                  data-handler='reports'
                  data-action='generate-non-profit-report'
                  data-payload={context.to_json(payload)}
                  tooltip='Download the client billing report for this period'
                >
                  NonProfit Billing Report
                </ActionButton>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display='flex'
          alignItems='center'
        />
      </CardContent>
    </Card >
  )
}

export default Component
