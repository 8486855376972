import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import useGlobal from 'store'
import {
  TextField
} from 'components'
export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal() // eslint-disable-line
  const form = globalState.forms[formName]
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setLoading(true)
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: {
        obj: formName,
        params: {
          where: form.functions.makeWhere(value, form),
          orderby: 'description desc, sys_id desc',
        },
        result_format: 'raw',
        count: true,
        userData: true
      },
      stateReducer: (store, response) => {
        setLoading(false)
        setOptions(response.rows)
        return
      }
    })
  }

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={(event, reason) => {
        setOpen(false)
      }}
      onChange={(event, item) => {
        if (!item) return
        globalActions.control.publish('setForm', {
          action: {
            formName,
            type: 'button',
            name: '@load',
            value: item.id
          }
        })
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={form.functions.makeLabel}
      options={options}
      loading={loading}
      selectOnFocus
      autoComplete
      autoHighlight
      clearOnBlur
      noOptionsText='Type something to find your records'
      loadingText='Type something to find your records'
      openText='Open Text'
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search'
          onChange={ev => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== '' || ev.target.value !== null) {
              onChangeHandle(ev.target.value)
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
