import * as React from 'react'
import useGlobal from 'store'
import {
  Grid
} from '@mui/material'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line


  return (
    <>
      <Grid container spacing={3} />

    </>
  )
}
