import * as React from 'react'
import useGlobal from 'store'
import {
  Grid,
  Box,
  Container,
  Paper
} from '@mui/material'
import {
  ToolBar,
  Header,
  ListView,
  ListViewServerSide
} from 'components'

const Components = ({
  Form,
  List,
  Toolbar,
  title,
  formName,
  ...props
}) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  React.useEffect(() => {
    globalActions.control.initForm(formName)
  }, [globalActions.control, formName, Form])


  React.useEffect(() => {
    globalActions.control.mergeState({
      controls: {
        PageTitle: title
      }
    }, true)
  }, [globalActions.control, title])

  if (!(formName in globalState.forms)) {
    return null
  }

  if (!Form && !List) {
    return null
  }

  return (
    <>
      <Header label={title} title={title} />
      <ToolBar
        pageorientation
        calendar
        form
        listview
        inspector
        formbuilder
        formName={formName}
      />
      <Container>
        <Grid container spacing={2}>
          {globalActions.views.pageOrientation(
            Form, List
          ).map(({ Component, attrs }) => {
            return (
              <Grid item {...attrs}>
                <Box sx={{ m: 10 }} />
                {Component === ListView || Component === ListViewServerSide ?
                  <Component
                    Toolbar={Toolbar}
                    formName={formName}
                  /> :
                  <Paper square={false} sx={{ p: 3, position: 'relative' }}>
                    <Component formName={formName} />
                  </Paper>
                }
              </Grid>
            )
          })}
          <Box sx={{ m: 5 }} />
        </Grid>
      </Container>
    </>
  )
}

export default Components
