import * as React from 'react'

import { Button, Menu, Tooltip } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import useGlobal from 'store'

export default function SimpleMenu({ formName, label, name, menuItems, showBrackets, onEvent, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const handleEvent = (event) => {
    handleClose()
    onEvent ? onEvent(event) : globalActions.control.onFormEvent(formName, event, false)
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const MainButton = () => {
    if (rest.tooltip) {
      return (
        <Tooltip title={rest.tooltip} enterDelay={1500} leaveDelay={200}>
          <Button
            onClick={handleEvent}
            name={name}
            sx={{
              pr: 0,
              mr: 0
            }}
            {...rest}
          >
            {label}
          </Button>
        </Tooltip>
      )
    }
    return (
      <Button
        onClick={handleEvent}
        name={name}
        sx={{
          pr: 0,
          mr: 0
        }}
        {...rest}
      >
        {label}
      </Button>
    )
  }

  return (
    <>
      <MainButton />
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        {...rest}
      >
        {showBrackets ? '[' : null}<ArrowDropDown /> {showBrackets ? ']' : null}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          menuItems.map((item, i) => {
            if (item.tooltip) {
              return (
                <div key={i}>
                  <Tooltip title={item.tooltip} enterDelay={1500} leaveDelay={200}>
                    <Button sx={{ mr: 1 }} {...rest} onClick={handleEvent} name={item.name}>{item.label}</Button>
                  </Tooltip>
                </div>
              )
            }
            return (
              <div key={i}>
                <Button sx={{ mr: 1 }} {...rest} onClick={handleEvent} name={item.name}>{item.label}</Button>
              </div>
            )
          })
        }
      </Menu>
    </>
  )
}
