import context from 'lib/context'

export const validate = (store, action, form) => {
  let valid = true
  const { fields } = form
  for (const key in fields) {
    const { widget } = fields[key]
    widget.error = 'Oops'
    valid = false
  }
  return valid
}

export const afterUpdateField = (store, action, form) => {
  switch (action.name) {
    case 'client':
      store.actions.api.performApi({
        apiName: 'Events',
        apiPath: '/data',
        apiAction: 'find-object',
        apiPayload: {
          tableName: 'customers',
          query: {
            where: {
              client_id: action.value
            }
          }
        },
        spinner: {
          content: 'Reading Data. Please Wait...'
        },
        stateReducer: (store, response) => {
          const { object } = response
          if (!object) {
            context.alertPopup('Client object was not found')
            return null
          }
          [
            'page_title',
            'primary_color',
            'secondary_color',
            'header_font',
            'text_font',
            'descriptor'

          ].forEach((key) => {
            form.fields[key].widget.value = object[key]
            form.shadow[key] = object[key]
          })

          form.fields.secure_image_url.widget.value = object.logo
          form.shadow.secure_image_url = object.logo

          form.dirty = true

          return {
            forms: {
              [action.formName]: form
            }
          }
        }
      })
      break
    case 'spot_option_values':
    case 'metric_1_values':
    case 'metric_2_values':
    case 'metric_3_values':
    case 'metric_4_values':
    case 'metric_5_values':
    case 'metric_6_values':
    case 'metric_7_values':
    case 'metric_8_values':
    case 'metric_9_values':
    case 'donation_1_values':
    case 'donation_2_values':
    case 'donation_3_values':
    case 'donation_4_values':
    case 'donation_5_values':
    case 'donation_6_values':
    case 'donation_7_values':
    case 'donation_8_values':
    case 'donation_9_values':
      if (!action.value.includes('"') && action.value.includes('\n')) {
        const list = action.value.split('\n')
        const temp = []
        let i
        for (i in list) {
          const value = list[i]
          if (value.includes(':')) {
            const split = value.split(':')
            temp.push(`"${value}":"${split[0].replace('$', '')}"`)
          } else {
            temp.push(`"${value}":"${value.replace('$', '')}"`)
          }
        }
        form.fields[action.name].widget.value = action.value = temp.join(',\n')
      }
      if (action.value) {
        const parseMe = action.value.includes('{') ? action.value : '{' + action.value + '}'
        try {
          let json = JSON.parse(parseMe)
          for (let key in json) {
            if (!context.isNumberOnly(json[key])) {
              form.fields[action.name].widget.error = 'Right side `' + json[key] + '` contains invalid data. (numbers and one decimal point only, no commas or other characters)'
              return
            }
          }
          form.fields[action.name].widget.error = null
        } catch (e) {
          form.fields[action.name].widget.error = e.message
        }
      }
      break
    case 'descriptor':
      try {
        if (action.value) {
          if (action.value.includes('*')) {
            let [left, right] = action.value.split('*')
            if (right.length < 1) {
              form.fields[action.name].widget.error = 'Must include customer info after * character'
              break
            }
            if (left.length === 3) {
              if (right.length > 19) {
                form.fields[action.name].widget.error = 'Right side of * must be 19 or less characters (spaces allowed)'
              }
            } else if (left.length === 7) {
              if (right.length > 14) {
                form.fields[action.name].widget.error = 'Right side of * must be 14 or less characters (spaces allowed)'
              }
            } else if (left.length === 12) {
              if (right.length > 9) {
                form.fields[action.name].widget.error = 'Right side of * must be 9 or less characters (spaces allowed)'
              }
            } else {
              form.fields[action.name].widget.error = 'Left side of * must be 3, 7, or 12 characters (spaces allowed)'
            }
          } else {
            form.fields[action.name].widget.error = 'Must include * character'
          }
        }
      } catch (e) {
        form.fields[action.name].widget.error = e.message
      }
      break
    default:
      break
  }
}
