import * as React from 'react'
import useGlobal from 'store'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  TextField as MuiTextField
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  ActionButton,
  BorderBox,
  DirtyForm,
  GenericGrid,
  Pointer,
  SaveButtons,
  SystemFields,
  TextField,
  UserHistory,
} from 'components'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  const [passwordReset, setPasswordReset] = React.useState('')

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, true)
  }


  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Client Users'
          >
            Instructions: A user is created in amazon Cognito.
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.status.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={3}>
          <ActionButton
            disabled={['closed'].includes(fields.status.widget.value)}
            data-sys_id={fields.sys_id.widget.value}
            data-handler={formName}
            data-table={formName}
            data-action='close-donor-account'
            tooltip='Close donor account'
            data-warning='This will close all Plaid, Authorize.net, and Braintree accounts associated with this profile.'
            data-title='WARNING'
            apiCallback={(store, response) => {
              globalActions.control.publish('setForm', {
                action: {
                  formName,
                  type: 'button',
                  name: '@remote-load',
                  value: fields.sys_id.widget.value
                }
              })
            }}

          >
            Close Donor Account
          </ActionButton>
        </Grid>
        <Grid item xs={3}>
          <ActionButton
            disabled={['closed', 'suspended'].includes(fields.status.widget.value)}
            data-sys_id={fields.sys_id.widget.value}
            data-handler={formName}
            data-table={formName}
            data-action='suspend-donor-account'
            tooltip='Suspend donor account'
            data-warning='This will NOT close Plaid, Authorize.net, and Braintree accounts associated with this profile. User can still sign in, but will not be billed.'
            data-title='WARNING'
            apiCallback={(store, response) => {
              globalActions.control.publish('setForm', {
                action: {
                  formName,
                  type: 'button',
                  name: '@remote-load',
                  value: fields.sys_id.widget.value
                }
              })
            }}
          >
            Suspend Donor Account
          </ActionButton>
        </Grid>
        <Grid item xs={3}>
          <ActionButton
            disabled={['active'].includes(fields.status.widget.value)}
            data-sys_id={fields.sys_id.widget.value}
            data-handler={formName}
            data-table={formName}
            data-action='open-donor-account'
            tooltip='Open donor account'
            apiCallback={(store, response) => {
              globalActions.control.publish('setForm', {
                action: {
                  formName,
                  type: 'button',
                  name: '@remote-load',
                  value: fields.sys_id.widget.value
                }
              })
            }}

          >
            Open Donor Account
          </ActionButton>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.status_notes.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.email_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.full_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.num_transactions.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <Pointer
            {...fields.default_supported_customer.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <BorderBox label='Cognito Account'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...fields.UserStatus.widget}
                  onChange={onEvent}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id='panel1a-header'
                  >
                    User Status Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <b><em>The user status. This can be one of the following: </em></b>
                    <br /><b>UNCONFIRMED</b>- User has been created but not confirmed.
                    <br /><b>CONFIRMED</b>- User has been confirmed.
                    <br /><b>EXTERNAL_PROVIDER</b>- User signed in with a third-party IdP.
                    <br /><b>ARCHIVED</b>- User is no longer active.
                    <br /><b>UNKNOWN</b>- User status isn't known.
                    <br /><b>RESET_REQUIRED</b>- User is confirmed, but the user must request a code and reset their password before they can sign in .
                    <br /><b>FORCE_CHANGE_PASSWORD</b>- The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else.
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={!['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='create-cognito-account'
                  tooltip='Create Cognito Account w/ Send Email'
                >
                  Create/Send Email
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={!['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='create-cognito-account-no-send-email'
                  tooltip='Create Cognito Account w/ NO Send Email'
                >
                  Create/No Send Email
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='update-cognito-account'
                  tooltip='Update cognito account'
                >
                  Update Cognito Account
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='delete-cognito-account'
                  tooltip='Delete Cognito Aaccount'
                >
                  Delete Cognito Account
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-action='resend-confirmation-code'
                  tooltip='Resend confirmation code'
                >
                  Resend Confirmation Code
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4}>
                <ActionButton
                  disabled={['USER_NOT_FOUND'].includes(fields.UserStatus.widget.value)}
                  data-sys_id={fields.sys_id.widget.value}
                  data-handler={formName}
                  data-table={formName}
                  data-password={passwordReset}
                  data-action='set-user-password'
                  tooltip='Set user password'
                  beforeCall={(event, extraParams) => {
                    if (passwordReset === '') {
                      alert('Please enter a password')
                      return false
                    }
                  }}
                  apiCallback={(store, response) => setPasswordReset('')}
                >
                  Set User Password
                </ActionButton>
              </Grid>
              <Grid item xs={4}>
                <MuiTextField
                  //disabled={context.isFalse(fields.sys_id.widget.value)}
                  id="outlined-basic"
                  label="New User Password"
                  variant="outlined"
                  value={passwordReset}
                  size='small'
                  onChange={e => setPasswordReset(e.target.value)}
                />
              </Grid>
            </Grid>
          </BorderBox>
        </Grid>

        <Grid item xs={12}>
          Payment Profiles
          <GenericGrid
            formName={formName}
            table='payment_profiles'
            select='sys_id,card_number,expiration_date,src,card_type,status'
          />
        </Grid>
        <Grid item xs={12}>
          Plaid Accounts
          <GenericGrid
            formName={formName}
            table='plaid_accounts'
            select='sys_id,name,mask,type,subtype,institution_name,status,use_for_rounding,last_download_date,last_download_count'
          />
        </Grid>
        <Grid item xs={12}>
          Monthly Billing Objects
          <GenericGrid
            formName={formName}
            table='month_transactions'
            select='sys_id,state,status,chargingamount,effective_month,effective_year,client_id,campaign_id,tx_type'
          />
        </Grid>
        <Grid item xs={12}>
          All Transactions
          <GenericGrid
            formName={formName}
            table='transactions'
            select='sys_id,status,amount,effective_date,pmt_type,client,campaign,is_disputed'
          />
        </Grid>
        <Grid item xs={12}>
          SwipeGive Settings
          <GenericGrid
            formName={formName}
            table='swipegive_settings'
            select='sys_id,client_id,max_donation_amount,should_round_zero_up'
          />
        </Grid>
        <Grid item xs={12}>
          Monthly Recurring Settings
          <GenericGrid
            formName={formName}
            table='monthly_recurring_settings'
            select='sys_id,client_id,recurring_amount,recurring_amount_type'
          />
        </Grid>
        <Grid item xs={12}>
          Roundups
          <GenericGrid formName={formName} table='roundup' />
        </Grid>
        <Grid item xs={12}>
          Rally Metrics
          <GenericGrid formName={formName} table='rally_metrics' />
        </Grid>
        <Grid item xs={12}>
          User History
          <UserHistory formName={formName} />
        </Grid>
      </Grid >
    </>
  )
}
