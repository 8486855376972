import * as React from 'react'
import {
  Container,
  Grid,
} from '@mui/material'
import {
  Header,
  ToolBar
} from 'components'
import CurrentBillingPeriod from './CurrentBillingPeriod'
import PreviousBillingPeriod from './PreviousBillingPeriod'
import SwipeGiveUsers from './SwipeGiveUsers'
import useGlobal from 'store'

const Dashboard = () => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  React.useEffect(() => {
    globalActions.control.setUserData({
      'controls.menu.options.selected': {
        controls: {
          menu: {
            options: {
              selected: 'Dashboard'
            }
          }
        }
      }
    })

    globalActions.control.mergeState({
      controls: {
        PageTitle: 'Admin Dashboard',
        menu: {
          options: {
            selected: 'Dashboard'
          }
        }
      }
    }, true)
  }, [globalActions.control])

  return (
    <>
      <Header label='Billing Dashboard' title='Billing Dashboard' />
      <ToolBar
        inspector
        formbuilder
      />
      <Container maxWidth={false} sx={{ pt: 3 }}>
        <Grid
          container
          spacing={3}
        >

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <PreviousBillingPeriod />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <CurrentBillingPeriod />
          </Grid>

          <Grid item xs={12}          >
            <SwipeGiveUsers />
          </Grid>

        </Grid>
      </Container>
    </>
  )
}

export default Dashboard
