import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


import {
  ActionButton,
} from 'components'

export const table = 'payment_profiles'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    src: widget({
      label: 'Source'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    card_number: widget({
      label: 'Card Number'
    }, {
      display: true
    }),
    expiration_date: widget({
      label: 'Expiration'
    }, {
      display: true
    }),
    card_type: widget({
      label: 'Card Type'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }),
    status_notes: widget({
      label: 'Status Notes'
    }),
    delete: widget({
      label: 'Delete'
    }, {
      anonymous: true,
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return (
          <ActionButton
            data-handler='billing'
            data-action='delete-payment-profile'
            data-sys_id={row.sys_id}
            tooltip='Delete this payment profile'
            data-title='Delete Payment Profile'
            data-warning={'Are you sure you want to delete this payment profile?'}
            apiCallback={(store, response) => {
              store.actions.control.publish('callAfter', { func: store.state.forms.current?.reloadTable, params: [store, store.state.forms.current?.formName] })
            }}
          >
            Delete
          </ActionButton>
        )
      }
    })
  }
})



export default data
