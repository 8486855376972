
import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  FormLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material'
import { Alert } from '@mui/material'
import { useImageLibrary } from 'store/state'

export default function ClientDropDownFilter({ formName, keyName = 'client', ...props }) {
  const [globalState, globalActions] = useGlobal()

  const {
    active_customers,
    [formName]: form
  } = globalState.forms

  const {
    active_customers: datatable,
  } = globalState.repo

  const [imageLibrary, setImageLibrary] = useImageLibrary()

  React.useEffect(() => {
    globalActions.control.initForm('image_library', false)
    globalActions.control.initForm('active_customers', false)
  }, [globalActions.control, imageLibrary])

  React.useEffect(() => {
    if (context.isDefined(form)) {
      const temp1 = form.queryParams.filter['%' + keyName]
      const temp2 = form.queryParams.filter[keyName]
      if (temp1 && temp1 !== '@@') {
        setImageLibrary.setPartialState({ client: temp1 })
      }
      if (temp2 && temp2 !== '@@') {
        setImageLibrary.setPartialState({ client: temp2 })
      }
    }
  }, [
    form,
    keyName,
    setImageLibrary
  ])

  React.useEffect(() => {
    if (context.isDefined(active_customers)) {
      if (context.isUndefined(datatable)) {
        globalActions.control.reloadTable('active_customers')
      }
    }
  }, [
    globalActions.control,
    datatable,
    active_customers
  ])

  const handleClientChange = (event) => {
    let newValue = event.target.value
    let filter
    setImageLibrary.setPartialState({ client: newValue })
    props.onChange && props.onChange(event)
    if (newValue && newValue[0] === '@') {
      filter = {
        [keyName]: newValue,
        ['%' + keyName]: '@@'
      }
    } else {
      filter = {
        [keyName]: '@@',
        ['%' + keyName]: newValue
      }
    }
    globalActions.control.mergeState({
      forms: {
        [formName]: {
          queryParams: { filter }
        }
      }
    })
    if (newValue === '') {
      globalActions.control.deleteStateKey(['repo', formName])
    } else {
      globalActions.control.publish('callAfter', {
        func: globalActions.control.reloadTable,
        params: [formName, { content: 'Reloading. Please wait...' }]
      })
    }
  }

  if (context.isUndefined(datatable)) { return null }
  return (
    <>
      <span error-id={props.name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend' >
          {props.label}
          {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>*</span>}
        </FormLabel>
        <Select
          name={props.name}
          value={imageLibrary.client}
          onChange={handleClientChange}
          fullWidth
          error={Boolean(props.error)}
          color={props.color}
          inputProps={{
            sx: context.inputColors
          }}
          displayEmpty
        >
          <MenuItem value=''>{props.initialLabel || 'Please select a client...'}</MenuItem>
          <MenuItem value='@@NULL'>NULL client</MenuItem>
          <MenuItem value='@@EMPTY'>Empty string as client</MenuItem>
          {datatable.rows &&
            datatable.rows.map((item) => {
              if (item.sys_id && item.client_id && item.client_name) {
                return (
                  <MenuItem key={item.sys_id} value={item.client_id}>
                    {item.client_name}
                    {item.client_name !== item.client_id && <> ({item.client_id})</>}
                  </MenuItem>
                )
              }
              return null
            })}
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error &&
          <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}
