import * as React from 'react'
import {
    Container,
    Grid,
} from '@mui/material'
import {
    Header
} from 'components'
import ToolBar from '../ToolBar'

const Dashboard = () => {


    return (
        <>
            <Header label='Documentation' title='Documentation' />
            <ToolBar links={[
                { label: 'Home', link: '/documentation/home' },
                { label: 'Developers', link: '/documentation/developers' },
            ]} />
            <Container maxWidth={false} sx={{ pt: 3 }}>
                <Grid
                    container
                    spacing={3}
                >

                    <Grid item xs={12}>
                        Developers
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}

export default Dashboard
