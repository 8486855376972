import * as React from 'react'
import { Line } from 'react-chartjs-2'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import useGlobal from 'store'

const Component = ({ ...rest }) => {
  const theme = useTheme()

  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const {
    daily_sales = [],
    transaction_days = 7
  } = globalState.controls

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-daily-sales',
      apiPayload: {
        transaction_days: transaction_days
      },
    })
  }, [globalActions.api, transaction_days])

  const data = {
    datasets: [
      {
        backgroundColor: theme.palette.primary.main,
        data: daily_sales.map((i) => (i.total_amount || 0)),
        label: 'Daily Total'
      }
    ],
    labels: daily_sales.map((i) => (i.date.split('-').slice(1, 3).join('-')))
  }

  const options = {
    animation: true,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  }

  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardHeader
        action={(
          <FormControl>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={transaction_days}
              onChange={(event) => {
                globalActions.control.mergeState({
                  controls: {
                    transaction_days: event.target.value
                  }
                }, true)
              }}
              sx={{ m: 0, p: 0 }}
            >
              <MenuItem value='7'>Last 7 Days</MenuItem>
              <MenuItem value='14'>Last 14 Days</MenuItem>
              <MenuItem value='30'>Last 30 Days</MenuItem>
            </Select>
          </FormControl>
        )}
        title='Latest Donations'
      />

      <Divider />
      <CardContent>
        <Box
          height={400}
          position='relative'
        >
          <Line
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <RouterLink to='/app/donations'>
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}
        >
          <Button
            color='primary'
            endIcon={<ArrowRightIcon />}
            size='small'
            variant='text'
          >
            See All Donations
          </Button>
        </Box>
      </RouterLink>
    </Card>
  )
}

export default Component
