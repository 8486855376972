import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  color,
  widget,
  radio,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'form_builder_template'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    is_published: checkbox({
      headerText: 'Publish',
      label: 'Publish',
      helperText: 'If checked, this form is published and available for donations.'
    }),
    tipping_enabled: checkbox({
      headerText: 'Tipping',
      label: 'Enable Tipping',
      helperText: 'If checked, tipping is enabled for this form.'
    }),
    tipping_option_values: widget({
      label: 'Values',
      multiline: true,
      headers: ['Display Text', 'Percent or Dollar Value']
    }),
    tipping_other_amount: widget({
      helperText: '',
      label: 'Tip Amount',
      placeholder: ''
    }),
    tipping_display_text: widget({
      helperText: 'The tipping checkbox text displayed to the user in bold.',
      label: 'Tipping Header Text',
      multiline: true,
      placeholder: 'Default: Add a tip to help pay our fund raising fees'
    }),
    tipping_help_text: widget({
      helperText: 'The text displayed to the user in smaller gray text.',
      label: 'Tipping Help Text',
      multiline: true,
      placeholder: 'Default: We rely on the generosity of donors to help us run our campaign.Your tip will cover the processing and platform fee.'
    }),
    client: widget({
      label: 'Client',
      helperText: 'Client must exists in client database.',
      query: {
        obj: 'customers',
        datatable: 'customers',
        params: {
          orderby: 'client_name asc, sys_id asc'
        }
      }
    }, {
      display: true,
      searchable: true
    }),
    campaign: widget({
      helperText: 'The short-code for the campaign',
      label: 'Campaign'
    }, {
      display: true,
      searchable: true
    }),
    sub_campaign: widget({
      helperText: 'The short-code for the subcampaign',
      label: 'Sub Campaign'
    }, {
      display: true,
      searchable: true
    }),
    campaign_end_date: widget({
      helperText: 'The soft end date for this campaign',
      label: 'Campaign End Date'
    }, {
      display: true
    }),
    url_slug: widget({
      helperText: 'The url slug for this campaign',
      label: 'URL Slug'
    }, {
      display: true,
      searchable: true
    }),
    url_slug_alternate: widget({
      helperText: 'The alternate url slug for this campaign',
      label: 'Alternate URL Slug'
    }, {
      display: true,
      searchable: true
    }),
    descriptor: widget({
      helperText: 'The soft descriptor for the credit card transaction. ',
      moreInfo: <>
        Descriptor format is "company*client".  Company name section must be either 3, 7 or 12 characters and the < br />
        client info can be up to 18, 14, or 9 characters respectively(with an * in between for a total descriptor name of 22 characters).
      </>,
      label: 'Descriptor'
    }),
    page_title: widget({
      helperText: 'The browser tab/page title',
      label: 'Browser Tab/Page Title'
    }),
    secure_image_type: widget({
      helperText: 'image/jpg',
      label: 'Secure Image Type'
    }),
    secure_image_url: widget({
      label: 'Secure Image URL'
    }),
    share_message: widget({
      label: 'Share Message'
    }),
    share_url: widget({
      label: 'Share Url'
    }),
    header_banner_image_url: widget({
      label: 'Header Banner Image URL',
      helperText: 'URL to main header banner image'
    }),
    header_banner_text: widget({
      label: 'Header Banner Text',
      helperText: 'Text to display in main header banner'
    }),
    background_image_url: widget({
      label: 'Background Image Url'
    }),
    background_color: color({
      label: 'Background Color'
    }),
    background_css: widget({
      label: 'Background CSS'
    }),
    main_image_url: widget({
      label: 'Main Image URL',
      helperText: 'URL to main image or video'
    }),
    body_header_url: widget({
      label: 'Body Header Image URL',
      helperText: 'URL to body header image'
    }),
    body_header_text: widget({
      label: 'Body Header Text'
    }),
    body_message_text: widget({
      label: 'Body Message Text',
      multiline: true
    }),
    form_instructions: widget({
      label: 'Form Field Instructions',
      multiline: true
    }),
    spot_image: widget({
      label: 'Spot Donation Image'
    }),
    spot_option_show: checkbox({
      label: 'Show Spot Donation',
      helperText: 'If checked, show the spot donation on the page.'
    }),
    spot_option_bottom: checkbox({
      label: 'Show Below Metrics',
      helperText: 'If checked, show the spot donation after the metrics on the page.'
    }),
    spot_option_orientation: radio({
      label: 'Spot Donation Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    spot_option_title: widget({
      label: 'Spot Donation Title'
    }),
    spot_option_label: widget({
      label: 'Spot Donation Label'
    }),
    spot_option_defaultvalue: widget({
      label: 'Default Value'
    }),
    spot_option_values: widget({
      label: 'Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_1_header: widget({
      label: 'Metric 1 Header'
    }),
    metric_1_image: widget({
      label: 'Metric 1 Image'
    }),
    metric_1_label: widget({
      label: 'Metric 1 Label'
    }),
    metric_1_secondarytext: widget({
      label: 'Metric 1 Secondary Text'
    }),
    metric_1_helpertext: widget({
      label: 'Metric 1 Helper Text'
    }),
    metric_1_defaultvalue: widget({
      label: 'Metric 1 Default Value'
    }),
    metric_1_values: widget({
      label: 'Metric 1 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_2_header: widget({
      label: 'Metric 2 Header'
    }),
    metric_2_image: widget({
      label: 'Metric 2 Image'
    }),
    metric_2_label: widget({
      label: 'Metric 2 Label'
    }),
    metric_2_secondarytext: widget({
      label: 'Metric 2 Secondary Text'
    }),
    metric_2_helpertext: widget({
      label: 'Metric 2 Helper Text'
    }),
    metric_2_defaultvalue: widget({
      label: 'Metric 2 Default Value'
    }),
    metric_2_values: widget({
      label: 'Metric 2 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_3_header: widget({
      label: 'Metric 3 Header'
    }),
    metric_3_image: widget({
      label: 'Metric 3 Image'
    }),
    metric_3_label: widget({
      label: 'Metric 3 Label'
    }),
    metric_3_secondarytext: widget({
      label: 'Metric 3 Secondary Text'
    }),
    metric_3_helpertext: widget({
      label: 'Metric 3 Helper Text'
    }),
    metric_3_defaultvalue: widget({
      label: 'Metric 3 Default Value'
    }),
    metric_3_values: widget({
      label: 'Metric 3 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_4_header: widget({
      label: 'Metric 4 Header'
    }),
    metric_4_image: widget({
      label: 'Metric 4 Image'
    }),
    metric_4_label: widget({
      label: 'Metric 4 Label'
    }),
    metric_4_secondarytext: widget({
      label: 'Metric 4 Secondary Text'
    }),
    metric_4_helpertext: widget({
      label: 'Metric 4 Helper Text'
    }),
    metric_4_defaultvalue: widget({
      label: 'Metric 4 Default Value'
    }),
    metric_4_values: widget({
      label: 'Metric 4 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_5_header: widget({
      label: 'Metric 5 Header'
    }),
    metric_5_image: widget({
      label: 'Metric 5 Image'
    }),
    metric_5_label: widget({
      label: 'Metric 5 Label'
    }),
    metric_5_secondarytext: widget({
      label: 'Metric 5 Secondary Text'
    }),
    metric_5_helpertext: widget({
      label: 'Metric 5 Helper Text'
    }),
    metric_5_defaultvalue: widget({
      label: 'Metric 5 Default Value'
    }),
    metric_5_values: widget({
      label: 'Metric 5 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_6_header: widget({
      label: 'Metric 6 Header'
    }),
    metric_6_image: widget({
      label: 'Metric 6 Image'
    }),
    metric_6_label: widget({
      label: 'Metric 6 Label'
    }),
    metric_6_secondarytext: widget({
      label: 'Metric 6 Secondary Text'
    }),
    metric_6_helpertext: widget({
      label: 'Metric 6 Helper Text'
    }),
    metric_6_defaultvalue: widget({
      label: 'Metric 6 Default Value'
    }),
    metric_6_values: widget({
      label: 'Metric 6 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_7_header: widget({
      label: 'Metric 7 Header'
    }),
    metric_7_image: widget({
      label: 'Metric 7 Image'
    }),
    metric_7_label: widget({
      label: 'Metric 7 Label'
    }),
    metric_7_secondarytext: widget({
      label: 'Metric 7 Secondary Text'
    }),
    metric_7_helpertext: widget({
      label: 'Metric 7 Helper Text'
    }),
    metric_7_defaultvalue: widget({
      label: 'Metric 7 Default Value'
    }),
    metric_7_values: widget({
      label: 'Metric 7 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_8_header: widget({
      label: 'Metric 8 Header'
    }),
    metric_8_image: widget({
      label: 'Metric 8 Image'
    }),
    metric_8_label: widget({
      label: 'Metric 8 Label'
    }),
    metric_8_secondarytext: widget({
      label: 'Metric 8 Secondary Text'
    }),
    metric_8_helpertext: widget({
      label: 'Metric 8 Helper Text'
    }),
    metric_8_defaultvalue: widget({
      label: 'Metric 8 Default Value'
    }),
    metric_8_values: widget({
      label: 'Metric 8 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    metric_9_header: widget({
      label: 'Metric 9 Header'
    }),
    metric_9_image: widget({
      label: 'Metric 9 Image'
    }),
    metric_9_label: widget({
      label: 'Metric 9 Label'
    }),
    metric_9_secondarytext: widget({
      label: 'Metric 9 Secondary Text'
    }),
    metric_9_helpertext: widget({
      label: 'Metric 9 Helper Text'
    }),
    metric_9_defaultvalue: widget({
      label: 'Metric 9 Default Value'
    }),
    metric_9_values: widget({
      label: 'Metric 9 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    primary_color: color({
      label: 'Primary Color'
    }),
    secondary_color: color({
      label: 'Secondary Color'
    }),
    header_font: widget({
      label: 'Header Font',
      helperText: 'Defaults to "Open Sans,sans-serif" if blank'
    }),
    text_font: widget({
      label: 'Text Font',
      helperText: 'Defaults to "Open Sans,sans-serif" if blank'
    }),
    confirmation_header_text: widget({
      label: 'Confirmation Header Text'
    }),
    confirmation_header_image: widget({
      label: 'Confirmation Header Image'
    }),
    confirmation_body_image: widget({
      label: 'Confirmation Body Image'
    }),
    confirmation_body_text: widget({
      label: 'Confirmation Body Text',
      multiline: true,
      helperText: <>
        <div>&#123;first_name&#125;: Donor's First Name</div>
        <div>&#123;last_name&#125;: Donor's Last Name</div>
        <div>&#123;email_address&#125;: Donor's Email Address</div>
        <div>&#123;summary&#125;: Donation summary information (campaign, total amount, etc.)</div>
        <div>&#123;descriptor&#125;: Credit Card Descriptor</div>
        <div>&#123;share_url&#125;: URL to donation page </div>
        <div>Press ctrl-I to open the image library</div>
      </>
    }),
    receipt_from_address: widget({
      label: 'Receipt From Address'
    }),
    receipt_subject: widget({
      label: 'Receipt Subject'
    }),
    receipt_body_text: widget({
      label: 'Receipt Body Text',
      multiline: true,
      minRows: 5,
      helperText: <>
        <div>&#123;&#123;first_name&#125;&#125;: Donor's First Name</div>
        <div>&#123;&#123;last_name&#125;&#125;: Donor's Last Name</div>
        <div>&#123;&#123;email_address&#125;&#125;: Donor's Email Address</div>
        <div>&#123;&#123;summary&#125;&#125;: Summary of Donation and Rally Pledge </div>
        <div>&#123;&#123;amount&#125;&#125;: Total Donation Amount</div>
        <div>&#123;&#123;descriptor&#125;&#125;: Credit Card Descriptor</div>
        <div>&#123;&#123;share_url&#125;&#125;: URL to donation page </div>
        <div>Press ctrl-I to open the image library</div>
      </>
    }),
    mail_bcc: widget({
      label: 'List of comma separated emails to BCC donation receipts',
      helperText: 'name@mail.com,name@mail.com'
    }),
    rally_receipt_from_address: widget({
      label: 'Rally Receipt From Address'
    }),
    rally_receipt_subject: widget({
      label: 'Rally Receipt Subject'
    }),
    rally_receipt_body_text: widget({
      label: 'Rally Receipt Body Text',
      multiline: true,
      minRows: 5,
      helperText: <>
        <div>&#123;&#123;first_name&#125;&#125;: Donor's First Name</div>
        <div>&#123;&#123;last_name&#125;&#125;: Donor's Last Name</div>
        <div>&#123;&#123;email_address&#125;&#125;: Donor's Email Address</div>
        <div>&#123;&#123;payment_type&#125;&#125;: Payment type (visa, paypal, etc.)</div>
        <div>&#123;&#123;account_number&#125;&#125;: Last four of credit card or email address of paypal</div>
        <div>&#123;&#123;line_items&#125;&#125;: Explanation of each line item&#60;&#60;&#60;</div>
        <div>&#123;&#123;descriptor&#125;&#125;: Credit Card Descriptor</div>
        <div>&#123;&#123;share_url&#125;&#125;: URL to donation page </div>
        <div>Press ctrl-I to open the image library</div>
      </>
    }),
    rally_mail_bcc: widget({
      label: 'List of comma separated emails to BCC rally receipts',
      helperText: 'name@mail.com,name@mail.com'
    }),
    show_pledge_cap: checkbox({
      label: 'Show Pledge Cap',
      helperText: 'If checked, the pledge cap widget will display to the donor below the metrics.'
    }),
    show_rally_whole_season: checkbox({
      label: 'Show Whole Season Rally',
      helperText: 'Show the checkbox to allow donor to opt in to donate for whole season including past games.'
    }),
    special_fund_dropdown: widget({
      label: 'Special Fund Dropdown',
      multiline: true
    }),
    show_special_fund_writein: checkbox({
      label: 'Show Special Fund Write-In',
      helperText: 'Show the text field to write-in how special fund should be applied.'
    }),
    show_rival_thermometer: checkbox({
      label: 'Show Rivalry Thermomter',
      helperText: 'Add description'
    }),
    rival_client: widget({
      label: 'Rival Client',
      helperText: 'Rival client code. Client must exists in client database.',
      query: {
        obj: 'customers',
        datatable: 'customers',
        params: {
          orderby: 'client_name asc, sys_id asc'
        }
      }
    }),
    rival_campaign: widget({
      helperText: 'The short-code for the rivals campaign',
      label: 'Rival Campaign'
    }),
    campaign_goal_dollars: widget({
      label: 'Campaign Goal Dollars',
      helperText: 'What is the goal of this campaign in dollars.'
    }),
    campaign_goal_donors: widget({
      label: 'Campaign Goal Donors',
      helperText: 'What is the goal of this campaign in number of donors contributing.'
    }),
    campaign_start_dollars: widget({
      label: 'Starting Dollars',
      helperText: 'What is the starting number of dollars before adding dollars from our system.'
    }),
    campaign_start_donors: widget({
      label: 'Starting Donors',
      helperText: 'What is the starting number of donors before adding donors from our system.'
    }),
    pii_instructions: widget({
      label: 'Donor Information Instructions',
      multiline: true,
      helperText: 'Displays above the fields that collect donor information. HTML allowed. (This is usually left blank)'
    }),
    pii_collect_first_name: checkbox({
      label: 'Collect First Name',
      helperText: 'If checked, show the field to collect the donors first name. This is usually checked.'
    }),
    pii_collect_last_name: checkbox({
      label: 'Collect Last Name',
      helperText: 'If checked, show the field to collect the donors last name. This is usually checked.'
    }),
    pii_collect_email_address: checkbox({
      label: 'Collect Email Address',
      helperText: 'If checked, show the field to collect the donors email address. This is mandatory to be collected.'
    }),
    pii_collect_phone_number: checkbox({
      label: 'Collect Phone Number',
      helperText: 'If checked, show the field to collect the donors phone number'
    }),
    pii_collect_postal_address: checkbox({
      label: 'Collect Street Address',
      helperText: 'If checked, show the field to collect the donors street address. This is usually checked.'
    }),
    pii_collect_postal_city: checkbox({
      label: 'Collect City',
      helperText: 'If checked, show the field to collect the donors city. This is usually left unchecked.'
    }),
    pii_collect_postal_state: checkbox({
      label: 'Collect State',
      helperText: 'If checked, show the field to collect the donors state. This is usually left unchecked.'
    }),
    pii_collect_postal_zipcode: checkbox({
      label: 'Collect Zip Code',
      helperText: 'If checked, show the field to collect the donors zip code. This is usually checked.'
    }),
    metric_section_instructions: widget({
      label: 'Metric Section Instructions',
      multiline: true,
      helperText: 'Displays just above all the metric fields.'
    }),
    donation_section_instructions: widget({
      label: 'Donation Section Instructions',
      multiline: true,
      helperText: 'Displays just above all the Donation fields.'
    }),
    donation_1_header: widget({
      label: 'Donation 1 Header'
    }),
    donation_1_image: widget({
      label: 'Donation 1 Image'
    }),
    donation_1_label: widget({
      label: 'Donation 1 Label'
    }),
    donation_1_secondarytext: widget({
      label: 'Donation 1 Secondarytext'
    }),
    donation_1_helpertext: widget({
      label: 'Donation 1 Helpertext'
    }),
    donation_1_defaultvalue: widget({
      label: 'Donation 1 Defaultvalue'
    }),
    donation_1_values: widget({
      label: 'Donation 1 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_2_header: widget({
      label: 'Donation 2 Header'
    }),
    donation_2_image: widget({
      label: 'Donation 2 Image'
    }),
    donation_2_label: widget({
      label: 'Donation 2 Label'
    }),
    donation_2_secondarytext: widget({
      label: 'Donation 2 Secondarytext'
    }),
    donation_2_helpertext: widget({
      label: 'Donation 2 Helpertext'
    }),
    donation_2_defaultvalue: widget({
      label: 'Donation 2 Defaultvalue'
    }),
    donation_2_values: widget({
      label: 'Donation 2 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_3_header: widget({
      label: 'Donation 3 Header'
    }),
    donation_3_image: widget({
      label: 'Donation 3 Image'
    }),
    donation_3_label: widget({
      label: 'Donation 3 Label'
    }),
    donation_3_secondarytext: widget({
      label: 'Donation 3 Secondarytext'
    }),
    donation_3_helpertext: widget({
      label: 'Donation 3 Helpertext'
    }),
    donation_3_defaultvalue: widget({
      label: 'Donation 3 Defaultvalue'
    }),
    donation_3_values: widget({
      label: 'Donation 3 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_4_header: widget({
      label: 'Donation 4 Header'
    }),
    donation_4_image: widget({
      label: 'Donation 4 Image'
    }),
    donation_4_label: widget({
      label: 'Donation 4 Label'
    }),
    donation_4_secondarytext: widget({
      label: 'Donation 4 Secondarytext'
    }),
    donation_4_helpertext: widget({
      label: 'Donation 4 Helpertext'
    }),
    donation_4_defaultvalue: widget({
      label: 'Donation 4 Defaultvalue'
    }),
    donation_4_values: widget({
      label: 'Donation 4 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_5_header: widget({
      label: 'Donation 5 Header'
    }),
    donation_5_image: widget({
      label: 'Donation 5 Image'
    }),
    donation_5_label: widget({
      label: 'Donation 5 Label'
    }),
    donation_5_secondarytext: widget({
      label: 'Donation 5 Secondarytext'
    }),
    donation_5_helpertext: widget({
      label: 'Donation 5 Helpertext'
    }),
    donation_5_defaultvalue: widget({
      label: 'Donation 5 Defaultvalue'
    }),
    donation_5_values: widget({
      label: 'Donation 5 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_6_header: widget({
      label: 'Donation 6 Header'
    }),
    donation_6_image: widget({
      label: 'Donation 6 Image'
    }),
    donation_6_label: widget({
      label: 'Donation 6 Label'
    }),
    donation_6_secondarytext: widget({
      label: 'Donation 6 Secondarytext'
    }),
    donation_6_helpertext: widget({
      label: 'Donation 6 Helpertext'
    }),
    donation_6_defaultvalue: widget({
      label: 'Donation 6 Defaultvalue'
    }),
    donation_6_values: widget({
      label: 'Donation 6 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_7_header: widget({
      label: 'Donation 7 Header'
    }),
    donation_7_image: widget({
      label: 'Donation 7 Image'
    }),
    donation_7_label: widget({
      label: 'Donation 7 Label'
    }),
    donation_7_secondarytext: widget({
      label: 'Donation 7 Secondarytext'
    }),
    donation_7_helpertext: widget({
      label: 'Donation 7 Helpertext'
    }),
    donation_7_defaultvalue: widget({
      label: 'Donation 7 Defaultvalue'
    }),
    donation_7_values: widget({
      label: 'Donation 7 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_8_header: widget({
      label: 'Donation 8 Header'
    }),
    donation_8_image: widget({
      label: 'Donation 8 Image'
    }),
    donation_8_label: widget({
      label: 'Donation 8 Label'
    }),
    donation_8_secondarytext: widget({
      label: 'Donation 8 Secondarytext'
    }),
    donation_8_helpertext: widget({
      label: 'Donation 8 Helpertext'
    }),
    donation_8_defaultvalue: widget({
      label: 'Donation 8 Defaultvalue'
    }),
    donation_8_values: widget({
      label: 'Donation 8 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_9_header: widget({
      label: 'Donation 9 Header'
    }),
    donation_9_image: widget({
      label: 'Donation 9 Image'
    }),
    donation_9_label: widget({
      label: 'Donation 9 Label'
    }),
    donation_9_secondarytext: widget({
      label: 'Donation 9 Secondarytext'
    }),
    donation_9_helpertext: widget({
      label: 'Donation 9 Helpertext'
    }),
    donation_9_defaultvalue: widget({
      label: 'Donation 9 Defaultvalue'
    }),
    donation_9_values: widget({
      label: 'Donation 9 Values',
      multiline: true,
      headers: ['Display Text', 'Dollar Amount']
    }),
    donation_1_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_2_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_3_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_4_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_5_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_6_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_7_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_8_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_9_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    donation_1_html: widget({
      label: 'Donation 1 HTML',
      multiline: true
    }),
    donation_2_html: widget({
      label: 'Donation 2 HTML',
      multiline: true
    }),
    donation_3_html: widget({
      label: 'Donation 3 HTML',
      multiline: true
    }),
    donation_4_html: widget({
      label: 'Donation 4 HTML',
      multiline: true
    }),
    donation_5_html: widget({
      label: 'Donation 5 HTML',
      multiline: true
    }),
    donation_6_html: widget({
      label: 'Donation 6 HTML',
      multiline: true
    }),
    donation_7_html: widget({
      label: 'Donation 7 HTML',
      multiline: true
    }),
    donation_8_html: widget({
      label: 'Donation 8 HTML',
      multiline: true
    }),
    donation_9_html: widget({
      label: 'Donation 9 HTML',
      multiline: true
    }),
    donation_1_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_2_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_3_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_4_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_5_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_6_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_7_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_8_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_9_orientation: radio({
      label: 'Donation Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    donation_1_dropdown: widget({
      label: 'Donation 1 Dropdown',
      multiline: true
    }),
    donation_2_dropdown: widget({
      label: 'Donation 2 Dropdown',
      multiline: true
    }),
    donation_3_dropdown: widget({
      label: 'Donation 3 Dropdown',
      multiline: true
    }),
    donation_4_dropdown: widget({
      label: 'Donation 4 Dropdown',
      multiline: true
    }),
    donation_5_dropdown: widget({
      label: 'Donation 5 Dropdown',
      multiline: true
    }),
    donation_6_dropdown: widget({
      label: 'Donation 6 Dropdown',
      multiline: true
    }),
    donation_7_dropdown: widget({
      label: 'Donation 7 Dropdown',
      multiline: true
    }),
    donation_8_dropdown: widget({
      label: 'Donation 8 Dropdown',
      multiline: true
    }),
    donation_9_dropdown: widget({
      label: 'Donation 9 Dropdown',
      multiline: true
    }),
    donation_1_dropdown_label: widget({
      label: 'Donation 1 Dropdown Label',
    }),
    donation_2_dropdown_label: widget({
      label: 'Donation 2 Dropdown Label',
    }),
    donation_3_dropdown_label: widget({
      label: 'Donation 3 Dropdown Label',
    }),
    donation_4_dropdown_label: widget({
      label: 'Donation 4 Dropdown Label',
    }),
    donation_5_dropdown_label: widget({
      label: 'Donation 5 Dropdown Label',
    }),
    donation_6_dropdown_label: widget({
      label: 'Donation 6 Dropdown Label',
    }),
    donation_7_dropdown_label: widget({
      label: 'Donation 7 Dropdown Label',
    }),
    donation_8_dropdown_label: widget({
      label: 'Donation 8 Dropdown Label',
    }),
    donation_9_dropdown_label: widget({
      label: 'Donation 9 Dropdown Label',
    }),
    metric_1_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_2_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_3_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_4_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_5_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_6_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_7_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_8_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_9_show_other: checkbox({
      label: 'Show Other',
      helperText: 'If checked, show the `other` option on this section.'
    }),
    metric_1_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_2_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_3_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_4_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_5_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_6_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_7_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_8_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
    metric_9_orientation: radio({
      label: 'Metric Values Orientation',
      options: [
        { value: 'vertical', label: 'Vertical Orientation' },
        { value: 'horizontal', label: 'Horizontal Orientation' }
      ],
      row: true
    }, {
      display: false,
      searchable: false,
    }),
  }, // End fields
})





export default data
