import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
  money
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'donation_lineitems'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client_id: widget({
      label: 'Client Id'
    }, {
      display: true
    }),
    campaign_id: widget({
      label: 'Campaign Id'
    }, {
      display: true
    }),
    selected: checkbox({
      label: 'Selected'
    }),
    option: widget({
      label: 'Option'
    }),
    value: money({
      label: 'Value'
    }, {
      display: true,
    }),
    value_error: widget({
      label: 'Value Error'
    }),
    label: widget({
      label: 'Label'
    }, {
      display: true
    }),
    text: widget({
      label: 'Text'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    sub_campaign_id: widget({
      label: 'Sub Campaign Id'
    }),
    special_fund: widget({
      label: 'Special Fund'
    }),
    special_fund_notes: widget({
      label: 'Special Fund Notes'
    }),
    braintree_transaction: widget({
      label: 'Braintree Transaction'
    }),
    line_type: widget({
      label: 'Line Type'
    })
  }
})



export default data
