import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Grid,
} from '@mui/material'
import { ExitToApp } from '@mui/icons-material'
import Logo from 'components/Logo'
import context from 'lib/context'


const TopBar = ({ ...rest }) => {

  return (
    <AppBar
      style={{ background: context.gradient }}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Grid
          justifyContent='space-between' // Add it here :)
          container
          spacing={10}
        >
          <Grid item>
            <RouterLink to='/'>
              <Logo style={{ maxHeight: 35 }} />
            </RouterLink>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <RouterLink
                to='/app/dashboard'
                style={{ textDecoration: 'none' }}
              >
                <Button sx={{ color: 'white', ml: 5 }}>
                  <ExitToApp sx={{ transform: 'rotate(0deg)' }} /> Sign In
                </Button>
              </RouterLink>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar >
  )
}

export default TopBar
