import PubSub from 'pubsub-js'

export const validate = (store, action, form) => {
  let valid = true
  const { fields } = form
  for (const key in fields) {
    const { widget } = fields[key]
    widget.error = 'Oops'
    valid = false
  }
  return valid
}

export const afterUpdateField = (store, action, form) => {
  const { shadow } = form
  switch (action.name) {
    case 'client_id':
    case 'client_name':
      PubSub.publish('setForm',
        {
          action: {
            formName: action.formName,
            type: 'string',
            name: 'description',
            value: `${shadow.client_id} - ${shadow.client_name}`
          }
        }
      )
      break
    case 'descriptor':
      try {
        if (action.value) {
          if (action.value.includes('*')) {
            let [left, right] = action.value.split('*')
            if (right.length < 1) {
              form.fields[action.name].widget.error = 'Must include customer info after * character'
              break
            }
            if (left.length === 3) {
              if (right.length > 19) {
                form.fields[action.name].widget.error = 'Right side of * must be 19 or less characters (spaces allowed)'
              }
            } else if (left.length === 7) {
              if (right.length > 14) {
                form.fields[action.name].widget.error = 'Right side of * must be 14 or less characters (spaces allowed)'
              }
            } else if (left.length === 12) {
              if (right.length > 9) {
                form.fields[action.name].widget.error = 'Right side of * must be 9 or less characters (spaces allowed)'
              }
            } else {
              form.fields[action.name].widget.error = 'Left side of * must be 3, 7, or 12 characters (spaces allowed)'
            }
          } else {
            form.fields[action.name].widget.error = 'Must include * character'
          }
        }
      } catch (e) {
        form.fields[action.name].widget.error = e.message
      }
      break
    default:
      break
  }
}
