import * as React from 'react'
import useGlobal from 'store'
import {
    Grid,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { useImageLibrary } from 'store/state'
import ImageList from './ImageList'
import Upload from './Upload'
import context from 'lib/context'
import {
    ClientDropDown,
    ImageFormatSelect
} from 'components/filters'

export default function Popup() {
    const [globalState, globalActions] = useGlobal()// eslint-disable-line
    const [selectedImage, setSelectedImage] = React.useState({})
    const [originalValue, setOriginalValue] = React.useState(false)
    const [originalSelection, setOriginalSelection] = React.useState(false)
    const [imageLibrary, setImageLibrary] = useImageLibrary()
    const descriptionElementRef = React.useRef(null)
    const {
        formName,
        fieldName,
        inputRef,
        selection,
        insertFormat
    } = imageLibrary

    React.useEffect(() => {
        globalActions.control.initForm('image_library', false)
    }, [globalActions.control])

    React.useEffect(() => {
        if (inputRef) {
            if (originalValue === false) {
                setOriginalValue(inputRef.current.value)
                setOriginalSelection(inputRef.current.value.substring(selection.start, selection.end))
            }
        }
    }, [originalValue,
        originalSelection,
        selection.end,
        selection.start,
        inputRef])

    const handleCancel = () => {
        setImageLibrary.setPartialState({
            open: false,
            selectedImage: {}
        })
        setSelectedImage({})
        setOriginalValue(false)
        setOriginalSelection(false)
    }

    const handleInsert = () => {
        if (context.isEmpty(selectedImage)) {
            setImageLibrary.setPartialState({
                open: false,
                selectedImage: {}
            })
            setSelectedImage({})
            setOriginalValue(false)
            setOriginalSelection(false)
            return
        }
        if (inputRef && selection) {
            const url = 'https://s3.amazonaws.com/' + selectedImage.bucket + '/' + selectedImage.key
            let input = inputRef.current
            let len = input.value.length
            let text
            if (insertFormat === 'url') {
                text = url
            } else if (insertFormat === 'img') {
                text = `<img style="width: 100%" src="${url}" alt="email image" />`
            } else {
                throw new Error('Invalid image format')
            }
            let pre = input.value.substring(0, selection.start)
            let post = input.value.substring(selection.end, len)
            if (pre[pre.length - 1] !== ' ' && pre[pre.length - 1] !== '\n') {
                text = ' ' + text
            }
            if (post[0] !== ' ' && post[0] !== ' ') {
                text = text + ' '
            }
            globalActions.control.onFormEvent(
                formName,
                {
                    target: {
                        name: fieldName,
                        type: 'text',
                        value: pre + text + post
                    }
                },
                false
            )
            input.selectionStart = selection.start
            input.selectionEnd = selection.start + text.length
            input.focus()
            globalActions.control.publish('callAfter', {
                func: (input, selection, text) => {
                    input.selectionStart = selection.start
                    input.selectionEnd = selection.start + text.length
                    input.focus()
                }, params: [input, selection, text]
            })
        }
        setImageLibrary.setPartialState({
            open: false,
            selectedImage: {}
        })
        setSelectedImage({})
        setOriginalValue(false)
        setOriginalSelection(false)
    }

    React.useEffect(() => {
        if (imageLibrary.open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [imageLibrary.open])

    return (
        <Dialog
            open={imageLibrary.open}
            onClose={handleCancel}
        >
            <DialogTitle>Image Library</DialogTitle>
            <DialogActions>

                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography>
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ClientDropDown
                            Label='By Client'
                            formName='image_library'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ImageFormatSelect
                            label='By File Format'
                            formName='image_library'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Upload />
                    </Grid>
                </Grid>
            </DialogActions>

            <DialogContent dividers sx={{ p: 0 }}>
                <ImageList
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                />
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: '100%' }}>
                    {context.isNotEmpty(selectedImage) ? 'Selected Image: ' + selectedImage.name : ''}
                </Box>
            </DialogActions>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleInsert}>Insert</Button>
            </DialogActions>
        </Dialog >
    )
}
