import * as React from 'react'
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material'
import useGlobal from 'store'
import context from 'lib/context'
import {
  Header,
  ActionButton,
  MultiSelectNew,
  ToolBar
} from 'components'
//import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro'
import { DataGrid } from '@mui/x-data-grid'
const columns = [
  {
    field: 'status',
    headerName: 'Status',
    type: 'string'
  },
  {
    field: 'client_id',
    headerName: 'Client Id',
    type: 'string'
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign Id',
    type: 'string'
  },
  {
    field: 'email_address',
    headerName: 'Email Address',
    type: 'string',
    minWidth: 250
  },
  {
    field: 'chargingamount',
    headerName: 'Chargingamount',
    type: 'number',
    valueGetter: (params) => '$' + context.formatMoney(params.row.chargingamount)
  },
  {
    field: 'carryover',
    headerName: 'Carryover',
    type: 'number'
  },
  {
    field: 'nextmonthcarryover',
    headerName: 'Nextmonthcarryover',
    type: 'number'
  },
  {
    field: 'totalcontribution',
    headerName: 'Totalcontribution',
    type: 'number'
  },
  {
    field: 'user_id',
    headerName: 'User Id',
    type: 'number'
  },

  {
    field: 'createddate',
    headerName: 'Createddate',
    type: 'string'
  },
  {
    field: 'lastupdateddate',
    headerName: 'Lastupdateddate',
    type: 'string'
  },

  {
    field: 'tx_type',
    headerName: 'Tx Type',
    type: 'string'
  },

  {
    field: 'effective_month',
    headerName: 'Effective Month',
    type: 'number',
    valueGetter: (params) => params.row.effective_month.toString().padStart(2, '0')
  },
  {
    field: 'effective_year',
    headerName: 'Effective Year',
    type: 'number',
    valueGetter: (params) => params.row.effective_year.toString()
  },
  {
    field: 'email_sent',
    headerName: 'Email Sent',
    type: 'string'
  },
  {
    field: 'test_account',
    headerName: 'Test Account',
    type: 'string'
  },

  {
    field: 'testing_id',
    headerName: 'Testing Id',
    type: 'number'
  },
  {
    field: 'success_email',
    headerName: 'Success Email',
    type: 'string'
  },
  {
    field: 'decline_email',
    headerName: 'Decline Email',
    type: 'string'
  },
  {
    field: 'notes',
    headerName: 'Notes',
    type: 'string'
  }
]

const TX_STATUS = ['pending', 'queued', 'skipped', 'complete', 'error', 'new']
const TX_STATUS_INITIAL = ['pending', 'queued', 'complete', 'error']
const TX_TYPE = ['minimum', 'rounding', 'recurring']
const pageSizeOptions = [10, 20, 30, 40, 50, 100]


const Component = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line
  const [selectedIds, setSelectedIds] = React.useState([])
  const [tx, setTx] = React.useState({ rows: [], columns: [] })
  const [lastUpdate, setLastUpdate] = React.useState('')
  const [updateFrequency, setUpdateFrequency] = React.useState(30000)
  const [visibleRows, setVisibleRows] = React.useState(10)
  const [statusFilter, setStatusFilter] = React.useState(TX_STATUS_INITIAL)
  const [typeFilter, setTypeFilter] = React.useState(TX_TYPE)

  const where = {}
  if (!context.isEmpty(typeFilter)) {
    where['tx_type'] = typeFilter
  }
  if (!context.isEmpty(statusFilter)) {
    where['status'] = statusFilter
  }
  where['effective_month'] = new Date().getMonth()
  where['effective_year'] = new Date().getFullYear()
  const settings = {
    apiName: 'Events',
    apiPath: '/data',
    apiAction: 'query',
    apiPayload: {
      obj: 'month_transactions',
      params: {
        where: where
      },
      result_format: 'raw',
    },
    stateReducer: (store, response) => {
      if (response && response.rows) {
        setTx(response)
        setLastUpdate(new Date().toTimeString())
      }
    }
  }


  context.useInterval(() => {
    globalActions.api.performApi(settings)
  }, updateFrequency)

  const onRefresh = () => {
    globalActions.api.performApi(settings)
  }

  return (<>
    <Header label='SwipeGive Billing ' title='Billing ' />
    <ToolBar
      inspector
      formbuilder
    />
    <Container sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <MultiSelectNew
            label='Month Transaction Status'
            callback={setStatusFilter}
            options={TX_STATUS}
            initialSelected={TX_STATUS_INITIAL}
          />
        </Grid>
        <Grid item xs={4}>
          <MultiSelectNew
            label='Month Transaction Type'
            callback={setTypeFilter}
            options={TX_TYPE}
          />
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Visible Rows</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={visibleRows}
              label='Visible Rows'
              onChange={e => setVisibleRows(e.target.value)}
            >
              {pageSizeOptions.map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Update Frequency</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={updateFrequency}
              label='UpdateFrequency'
              onChange={e => setUpdateFrequency(e.target.value)}
            >
              <MenuItem value={5000}>5 Sec</MenuItem>
              <MenuItem value={10000}>10 Sec</MenuItem>
              <MenuItem value={15000}>15 Sec</MenuItem>
              <MenuItem value={20000}>20 Sec</MenuItem>
              <MenuItem value={30000}>30 Sec</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={onRefresh}>Refresh</Button>
        </Grid>
        <Grid item xs={12}>
          {lastUpdate && <>Last Updated: {lastUpdate}</>}
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => row.sys_id}
        rows={tx.rows}
        columns={columns}
        checkboxSelection
        density='compact'
        autoHeight
        onPageSizeChange={(n) => setVisibleRows(n)}
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectedIds(newSelectionModel)
        }}
        rowSelectionModel={selectedIds}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <ActionButton
            data-handler='billing'
            data-action='skip-billing-objects'
            data-selected={selectedIds}
            tooltip='Skip Billing Objects'
            apiCallback={(store, response) => globalActions.api.performApi(settings)}
          >
            {context.isEmpty(selectedIds) ? 'Skip All Transactions' : `Skip ${selectedIds.length} Selected Transactions`}
          </ActionButton>
        </Grid>
        <Grid item xs={6} md={8}>
          Instructions
        </Grid>
        <Grid item xs={6} md={4}>
          <ActionButton
            data-handler='billing'
            data-action='enqueue-billing-objects'
            data-selected={selectedIds}
            tooltip='Enqueue Billing Objects'
            apiCallback={(store, response) => globalActions.api.performApi(settings)}
          >
            {context.isEmpty(selectedIds) ? 'Enqueue All Transactions' : `Enqueue ${selectedIds.length} Selected Transactions`}
          </ActionButton>
        </Grid>
        <Grid item xs={6} md={8}>
          Instructions
        </Grid>
        <Grid item xs={6} md={4}>
          <ActionButton
            data-handler='billing'
            data-action='dequeue-billing-objects'
            data-selected={selectedIds}
            tooltip='Dequeue Billing Objects'
            apiCallback={(store, response) => globalActions.api.performApi(settings)}
          >
            {context.isEmpty(selectedIds) ? 'Dequeue All Transactions' : `Dequeue ${selectedIds.length} Selected Transactions`}
          </ActionButton>
        </Grid>
        <Grid item xs={6} md={8}>
          Instructions
        </Grid>
        <Grid item xs={6} md={4}>
          <ActionButton
            data-handler='billing'
            data-action='update-billing-report-table'
            tooltip='Update Billing Report Table'
          >
            Update Billing Report Table
          </ActionButton>
        </Grid>
        <Grid item xs={6} md={8}>
          Instructions
        </Grid>
      </Grid>
    </Container>
  </>
  )
}

export default Component
