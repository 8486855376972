import * as React from 'react'
import { Container, Grid, Paper, Box, AppBar, Tab, Tabs } from '@mui/material'

import {
  Facebook,
  Ballot,
  LibraryBooks,
  Settings,
  RecentActors,
} from '@mui/icons-material'

import {
  DirtyForm,
  SaveButtons,
  SystemFields,
} from 'components'

import Config from './FeatureFlagConfig'
import Operations from './FeatureFlagOperations'

function TabPanel ({ children, value, index, ...rest }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default function ProgramObjectTabs ({ formName, ...rest }) {
  const [tabState, setTabState] = React.useState(0)

  const handleTabStateChange = (event, newState) => {
    setTabState(newState)
  }

  return <>
    <Container>
      <Paper sx={{
        p: 3,
        position: 'relative'
      }}>
        <Box sx={{
          position: '-webkit-sticky',
          // eslint-disable-next-line
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 5
        }}>
          <DirtyForm formName={formName} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SaveButtons formName={formName} />
            </Grid>
            <Grid item xs={12}>
              <SystemFields
                formName={formName}
                title='Feature Flag Template'
                showDescription
              >
                Instructions: Feature Flag
              </SystemFields>
            </Grid>
          </Grid>
          <AppBar position='static' color='default'>
            <Tabs
              value={tabState}
              onChange={handleTabStateChange}
              variant='scrollable'
              scrollButtons
              indicatorColor='primary'
              textColor='primary'
              allowScrollButtonsMobile>
              <Tab label='Config' icon={<Settings />} />
              <Tab label='Release Toggles' icon={<Facebook />} />
              {/* Release toggles: More often than not these are on/off switches that are used to
               control whether a feature is enabled or not. They are short-lived and removed after
                feature is released. */}
              <Tab label='Operational Toggles' icon={<LibraryBooks />} />
              {/* Operational toggles: Typically used to control flow on the back end. An example
               could be algorithm changes, upgrading or retiring old APIs, etc. These are also
                short-lived and ought to be removed after task is complete. */}
              <Tab label='Experimental Toggles' icon={<RecentActors />} />
              {/* Experimental Toggles: These are typically used for A/B or multivariate testing
              and are typically longer-lived flags that can be removed once we no longer need to
              collect user testing data. */}
              <Tab label='Permission Toggles' icon={<Ballot />} />
              {/* Permission Toggles: This is commonly talked about and is used to control product
               experience users have. I think doing this is actually a bad use case for feature
               flags and personally don’t recommend it. This sort of information
               would be coming from your user properties. */}
            </Tabs>
          </AppBar>
        </Box>

        <TabPanel value={tabState} index={0}>
          <Config formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={2}>
          <Operations formName={formName} />
        </TabPanel>
        {/*
        <TabPanel value={tabState} index={2}>
          <Body formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={3}>
          <DonorInfo formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={4}>
          <FormFields formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={5}>
          <DonationFields formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={6}>
          <Confirmation formName={formName} />
        </TabPanel>
        <TabPanel value={tabState} index={7}>
          <EmailReceipt formName={formName} />
        </TabPanel> */}
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Paper>
    </Container>
  </>
}
