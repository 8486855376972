import * as React from 'react'
import {
     Container,
     Grid,
} from '@mui/material'
import {
     Header
} from 'components'
import ToolBar from '../ToolBar'
import ReactMarkdown from 'react-markdown'

const Dashboard = () => {


     return (
          <>
               <Header label='Documentation' title='Documentation' />
               <ToolBar links={[
                    { label: 'Home', link: '/documentation/home' },
                    { label: 'Developers', link: '/documentation/developers' },
                    { label: 'Form Builder', link: '/documentation/form_builder' },
               ]} />
               <Container maxWidth={false} sx={{ pt: 3 }}>
                    <Grid
                         container
                         spacing={3}
                    >
                         <Grid item xs={12}>
                              <ReactMarkdown>
                                   {markdown}
                              </ReactMarkdown>
                         </Grid>

                    </Grid>
               </Container>
          </>
     )
}

export default Dashboard
const markdown = `
To configure your computer for an AWS Amplify project, follow these steps:

1. Install Node.js:
- Go to the official Node.js website(https://nodejs.org).
    - Download the recommended version for your operating system(Windows, macOS, or Linux).
   - Run the installer and follow the instructions to complete the installation.

2. Install Git:
- Go to the official Git website(https://git-scm.com).
    - Download the installer for your operating system.
   - Run the installer and follow the instructions to complete the installation.

3. Configure Git:
- Open a terminal or command prompt.
   - Set your Git username by running the following command and replacing "Your Name" with your actual name:
\`\`\`
     git config--global user.name "Your Name"
\`\`\`
    - Set your Git email address by running the following command and replacing "your.email@example.com" with your actual email address:
\`\`\`
     git config--global user.email your.email@example.com
\`\`\`

4. Install the AWS CLI:
- Go to the official AWS Command Line Interface(CLI) website(https://aws.amazon.com/cli/).
    - Follow the instructions to download and install the AWS CLI for your operating system.
   - Once installed, open a terminal or command prompt and run the following command to verify the installation:
\`\`\`
aws--version
\`\`\`

5. Configure the AWS CLI:
- Run the following command and follow the prompts to configure your AWS credentials:
\`\`\`
     aws configure
\`\`\`
    - You'll need your AWS Access Key ID, AWS Secret Access Key, default region name, and default output format. You can obtain these details from your AWS account or an administrator.

6. Install the Amplify CLI:
- Open a terminal or command prompt.
   - Run the following command to install the Amplify CLI globally:
\`\`\`
     npm install - g @aws-amplify / cli
\`\`\`

7. Set up an AWS Amplify project:
- Navigate to the project directory where you want to set up your Amplify project.
   - Run the following command to initialize a new Amplify project:
\`\`\`
     amplify init
\`\`\`
    - Follow the prompts to configure your Amplify project.You'll need to select the appropriate options based on your project requirements.

8. Install project dependencies:
- In the project directory, run the following command to install the necessary dependencies:
\`\`\`
     npm install
\`\`\`

9. Start working on your Amplify project:
- Now that your computer is configured and the project is set up, you can start developing your AWS Amplify project.Use the Amplify CLI and refer to the Amplify documentation for instructions on adding and configuring services, managing authentication, deploying your application, and more.

Remember to regularly update your dependencies and keep your development environment up to date.Also, consult the official documentation and resources provided by AWS Amplify for more detailed information on specific features and workflows.
`