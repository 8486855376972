import * as React from 'react'
import { Alert, TextField as MuiTextField, Box } from '@mui/material'
import _ from 'underscore'
import {
  QuestionMark,
  MoreInfo
} from 'components'
import context from 'lib/context'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'

const _onChange = _.debounce((event, func) => {
  func(event)
}, 500)

export default function Component({
  formName,
  onChange,
  value,
  showcount,
  moreInfo,
  showImageIcon,
  ...props }) {

  if (value === null) { value = '' }
  const [_value, _setValue] = React.useState('')
  const [_count, _setCount] = React.useState(value ? value.length : 0)

  React.useEffect(() => {
    if (value !== _value) {
      _setValue(value)
      _setCount(value ? value.length : 0)
    }
  }, [value]) // eslint-disable-line

  const onEvent = (event) => {
    _setValue(event.target.value)
    _setCount(event.target.value ? event.target.value.length : 0)
    _onChange(event, onChange)
  }

  return (
    <>
      <Box error-id={props.name}
        sx={{
          position: 'relative',
        }}>
        <MuiTextField
          widget='TextField'
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            sx: context.inputColors
          }}
          {...props}
          error={Boolean(props.error)}
          value={_value}
          onChange={onEvent}
          onKeyDown={props.onKeyDown}
        />
        {props.questionmark && <QuestionMark style={{
          position: 'absolute',
          top: -15,
          right: -15,
        }}>{props.questionmark}</QuestionMark>}
        {moreInfo && <MoreInfo>
          {moreInfo}
        </MoreInfo>}
        {showcount && showcount === 'true' && <Box sx={{
          position: 'absolute',
          top: 10,
          right: 5,
          fontSize: '80%',
          color: 'gray'
        }}>{_count}</Box>}
        {showImageIcon && <>
          <span title='Ctrl-I for Image Library'>
            <ImageSearchIcon
              sx={{
                position: 'absolute',
                top: 7,
                right: showcount ? _count.toString().length * 10 : 7,
                color: 'gray'
              }}
            />
          </span>
        </>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}

      </Box>
    </>
  )
}
