import * as React from 'react'
import {
  Alert, TextField as MuiTextField, Box, Typography
} from '@mui/material'
import _ from 'underscore'
import { Link } from 'react-router-dom'

const _onChange = _.debounce((event, func) => {
  func(event)
}, 500)

export default function Component({
  formName,
  onChange,
  value,
  showcount,
  points_to,
  ...props
}) {

  if (value === null) { value = '' }
  const [_value, _setValue] = React.useState('')
  const [_count, _setCount] = React.useState(value ? value.length : 0)

  React.useEffect(() => {
    if (value !== _value) {
      _setValue(value)
      _setCount(value ? value.length : 0)
    }
  }, [value]) // eslint-disable-line

  const onEvent = (event) => {
    _setValue(event.target.value)
    _setCount(event.target.value ? event.target.value.length : 0)
    _onChange(event, onChange)
  }

  return (
    <>
      <Box error-id={props.name} sx={{
        position: 'relative'
      }}>
        <MuiTextField
          widget='TextField'
          InputLabelProps={{ shrink: true }}
          {...props}
          error={Boolean(props.error)}
          value={_value}
          onChange={onEvent}
        />
        {props.error && <Alert severity='error'>{props.error}</Alert>}
        {showcount && showcount === 'true' && <Box sx={{
          position: 'absolute',
          top: 10,
          right: 3,
          fontSize: '80%',
          color: 'gray'
        }}>{_count}</Box>}
        <Link
          sx={{ color: 'white', textDecoration: 'none' }}
          to={points_to(value)}
        >
          <Typography variant='body2'>
            ->
          </Typography>
        </Link>
      </Box>
    </>
  )
}
