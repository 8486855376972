import * as React from 'react'
import context from 'lib/context'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  FormControl,
  List,
  Select,
  ListItem,
  ListItemText,
  MenuItem,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import useGlobal from 'store'

const RecentCampaigns = ({ ...rest }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line


  const {
    recent_campaign_totals = {
      data: [],
      transaction_days: 30
    }
  } = globalState.repo

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-recent-campaign-totals',
      apiPayload: {
        transaction_days: recent_campaign_totals.transaction_days
      },
    })
  }, [globalActions.api, recent_campaign_totals.transaction_days])

  return (
    <Card
      sx={{
        height: '100%'
      }}
      {...rest}
    >
      <CardHeader
        subtitle={`${recent_campaign_totals.data.length} in total`}
        action={(
          <FormControl>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={recent_campaign_totals.transaction_days}
              onChange={(event) => {
                globalActions.control.mergeState({
                  datatables: {
                    recent_campaign_totals: {
                      transaction_days: event.target.value
                    }
                  }
                }, true)
              }}
              sx={{ m: 0, p: 0 }}
            >
              <MenuItem value='30'>Last 30 Days</MenuItem>
              <MenuItem value='60'>Last 60 Days</MenuItem>
              <MenuItem value='90'>Last 90 Days</MenuItem>
            </Select>
          </FormControl>
        )}
        title='Recent Campaigns Totals'
      />
      <Divider />
      <List sx={{ height: '430px', overflow: 'auto' }}>
        {recent_campaign_totals.data.map((row, i) => (
          <ListItem
            divider={i < recent_campaign_totals.data.length - 1}
            key={'recent-campaigns-' + row.client + '-' + row.campaign}
          >
            <ListItemText
              primary={row.campaign}
              secondary={row.client}
            />
            <IconButton
              edge='end'
              size='small'
            >
              {'$' + context.formatMoney(row.total)}
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        display='flex'
        justifyContent='flex-end'
        p={2}
      >
        <Button
          color='primary'
          endIcon={<ArrowRightIcon />}
          size='small'
          variant='text'
        >
          View all
        </Button>
      </Box>
    </Card>
  )
}

export default RecentCampaigns
