/*******************************************************************
 * imports
 *   imports in all files should follow the order:
 *     non-mui imports
 *     mui imports
 *     local files
 *
 *
 *******************************************************************/
import * as React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import useGlobal from 'store'
import * as constants from 'store/constants'
import context from 'lib/context'
import { Notifier, KeyHandler, Spinner, Copyright, ImageLibrary } from 'components'
import {
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  Typography
} from '@mui/material'

import Navigator from './Navigator'
import Inspector from './Inspector'
import { SnackbarProvider } from 'notistack'


function Component() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { notifications } = globalState
  const { mobileOpen } = globalState.controls
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  React.useEffect(() => {
    if (notifications.maintenance) {
      navigate('/notifications/maintenance')
    }
  })

  React.useEffect(() => {
    globalActions.control.subscribe()
    return globalActions.control.unsubscribe
  }, [globalActions.control])

  React.useEffect(() => {
    if (context.isTrue(globalState.controls.current_user)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'get-user-data'
    })
  }, [
    globalActions.api,
    globalActions.control,
    globalState.controls.current_user
  ])
  if (!context.isTrue(globalState.controls.current_user)) return
  return (
    <>
      <Box sx={{ display: 'flex', minHeight: '80vh' }}>
        <Box
          component='nav'
          sx={{ width: { sm: constants.drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ?
            <Navigator
              PaperProps={{
                style: { width: constants.drawerWidth }
              }}
              sx={{
                display: { sm: 'block', xs: 'none' }
              }}
            /> : (
              <Navigator
                PaperProps={{ style: { width: constants.drawerWidth } }}
                variant='temporary'
                open={mobileOpen}
                onClose={globalActions.control.toggleNavigator}
              />
            )}
        </Box>
        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <SnackbarProvider maxSnack={3}>
            <KeyHandler />
            <Notifier />
            <Spinner />
            <ImageLibrary />
            <Outlet />
          </SnackbarProvider>
        </Box>
        <Inspector />
      </Box>
    </>
  )
}

const components = {
  Header() {
    return (
      <Box textAlign="center" >
        <Paper elevation={3} sx={{ p: 5, m: 5 }}>
          <img
            alt="Caringcent Logo"
            src="https://caringcent.org/static/media/CC.017074a8deee366dd490.jpg"
          />
          <Typography
            sx={{ mt: 1 }}
            variant='h3'
          >
            Administrators
          </Typography>
          <Typography sx={{
            mt: 1,
            fontSize: '90%'
          }}>
            Please contact support for new accounts
          </Typography>
          <Typography sx={{
            mt: 4,
            p: 2,
            fontSize: '90%'
          }}>
            Donors - please sign in here<br />
            <a href="https://donors.caringcent.org/">https://donors.caringcent.org</a><br />
            <br />
            Clients - please sign in here<br />
            <a href="https://clients.caringcent.org/">https://clients.caringcent.org</a><br />
          </Typography>
        </Paper>
      </Box >
    )
  },

  Footer() {
    return (
      <Box textAlign="center" sx={{ p: 5, m: 5 }}>
        <Copyright />
      </Box>
    )
  },
}

const formFields = {
  signIn: {
    username: {
      label: 'User name: (User names are case sensitive)',
      placeholder: 'Enter your user name',
      order: 1
    },
    password: {
      label: 'Password: (min 8 characters)',
      placeholder: 'Enter your password',
      order: 2,
    },
  },
}

export default withAuthenticator(Component, {
  usernameAttributes: 'email',
  signUpConfig: {
    usernameAlias: 'email'
  },
  signInConfig: {
    usernameAlias: 'email'
  },
  hideSignUp: true,
  components,
  formFields
})