import * as React from 'react'
import useGlobal from 'store'
import { Grid, Typography } from '@mui/material'
import {
  ActionButton,
  TextField,
  DisplayField
} from 'components'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    environment
  } = globalState.controls

  const control = globalState.controls[formName] || {}
  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const onKeyDown = (event) => {
    if (event.type === 'keydown') {
      if (event.key === 'Enter') {
        globalActions.control.publish('setForm', {
          action: {
            formName,
            type: 'button',
            name: '@remote-load',
            value: event.target.value
          }
        })
      }
    }
  }


  const key = 'controls.' + formName + '.systemFieldsToggle'

  const onNodeToggle = (event) => {
    globalActions.control.setUserData({
      [key]: {
        controls: {
          [formName]: {
            systemFieldsToggle: !control.systemFieldsToggle
          }
        }
      }
    })

    globalActions.control.mergeState({
      controls: {
        [formName]: {
          systemFieldsToggle: !control.systemFieldsToggle
        }
      }
    }, true)
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            {...fields.sys_id.widget}
            onChange={onEvent}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <div onClick={onNodeToggle}>
            <Typography sx={{
              fontSize: 15
            }}>{props.title}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.description.widget}
            onChange={onEvent}
          />
        </Grid>
        {control.systemFieldsToggle
          ? <>
            <Grid item xs={12}>
              <Typography variant='caption'>
                {props.children}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <DisplayField
                {...fields.sys_created.widget}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DisplayField
                {...fields.sys_modified.widget}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <DisplayField
                {...fields.sys_modified_by.widget}
              />
            </Grid>
            {fields.sys_id.widget.value && fields.sys_id.widget.value !== '@new' &&
              <>
                <Grid item xs={12} sm={2}>
                  <ActionButton
                    data-handler='database'
                    data-action='push-to-alternate'
                    data-table={form.table}
                    data-sys_id={fields.sys_id.widget.value}
                    tooltip='Push this object to alternate system'
                    data-title='Move object to other system?'
                    data-warning={`
    IF YOU PUSH AN OBJECT TO ANOTHER SYSTEM IT
    WILL OVERWRITE THE OBJECT WITH THE SAME SYSTEM ID IN THE OTHER SYSTEM.
    DOUBLE CHECK THE OTHER SYSTEM FOR THIS ID TO MAKE SURE YOU
    ARE NOT MAKING A MISTAKE!
    `}
                  >
                    Push to {environment === 'production' ? 'demo' : 'production'}
                  </ActionButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <ActionButton
                    data-handler='database'
                    data-action='pull-from-alternate'
                    data-table={form.table}
                    data-sys_id={fields.sys_id.widget.value}
                    tooltip='Pull this object from alternate system'
                    data-title='Overwrite this object from other system?'
                    data-warning={`
    IF YOU PILL AN OBJECT FROM ANOTHER SYSTEM IT
    WILL OVERWRITE THE LOCAL OBJECT WITH THE SAME SYSTEM ID FROM THE OTHER SYSTEM.
    DOUBLE CHECK THE OBJECT IN OTHER SYSTEM FOR THIS ID TO MAKE SURE YOU
    ARE NOT MAKING A MISTAKE!
    `}
                  >
                    Pull from {environment === 'production' ? 'demo' : 'production'}
                  </ActionButton>
                </Grid>
              </>
            }
          </>
          : null}

      </Grid>

    </>
  )
}
