import * as React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import {
  ParallaxScroll,
  PublicFooter,
  PublicTopBar,
} from 'components'
import useGlobal from 'store'
import context from 'lib/context'

const Component = () => {

  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  return (
    <>

      <PublicTopBar />
      <Box>
        <ParallaxScroll
          backgroundImage='https://s3.amazonaws.com/donate.resources/cc/CCWebImage1-Main.jpg'
          minHeight='100vh'

        >
          <Paper sx={{
            height: '100%',
            p: 3,
            backgroundColor: 'webpage.main',
            width: '80%',
            ml: 'auto',
            mr: 'auto',
            mt: '20vh',
            textAlign: 'center',
            maxWidth: '750px'
          }}>
            <Typography sx={{
              p: 2,
              m: 2,
              color: 'webpage.hilight',
              opacity: 1,
              fontWeight: 700,
              fontSize: '32px'
            }}>
              Tech Stack
            </Typography>
            <Typography sx={{
              m: 2,
              color: 'webpage.contrastText',
              opacity: 1,
              fontWeight: 500,
              fontSize: '23px'
            }}>
              <img
                alt='Serverless Architecture Diagram'
                src='https://s3.amazonaws.com/backoffice.resources/technology/aws+serverless+diagram.png'
              />
            </Typography>
          </Paper>
        </ParallaxScroll>
      </Box>
      <br />
      <Box>
        <ParallaxScroll
          backgroundImage='https://s3.amazonaws.com/donate.resources/cc/txstbobcatbasketball.jpg'
          minHeight='100vh'
        >
          <Paper sx={{
            height: '100%',
            p: 3,
            backgroundColor: 'webpage.main',
            width: '80%',
            ml: 'auto',
            mr: 'auto',
            mt: '20vh',
            textAlign: 'center',
            maxWidth: '750px'
          }}>
            <Typography sx={{
              p: 2,
              m: 2,
              color: 'webpage.hilight',
              opacity: 1,
              fontWeight: 700,
              fontSize: '32px'
            }}>
              Braintree Flow Diagram
            </Typography>
            <Typography sx={{
              m: 2,
              color: 'webpage.contrastText',
              opacity: 1,
              fontWeight: 500,
              fontSize: '23px'
            }}>
              <img
                alt='Braintree Flow Diagram'
                src='https://s3.amazonaws.com/backoffice.resources/technology/Braintree Flow diagram.PNG'
              />
            </Typography>
          </Paper>
        </ParallaxScroll>
      </Box>
      <PublicFooter
        style={{ background: context.gradient }}
        sx={{
          pt: 3,
          pb: 3,
        }}
      />
    </>
  )
}

export default Component
