import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'sys_tracking'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    last_activity: widget({
      label: 'Last Activity'
    }, {
      display: true
    }),
    activity_counter: widget({
      label: 'Activity Counter'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true
    }),
    activity_date: widget({
      label: 'Activity Date'
    }, {
      display: true
    }),
    account_id: widget({
      label: 'Account Id'
    }, {
      display: true
    }),
    user_id: widget({
      label: 'User Id'
    }, {
      display: true
    }),
    ip_address: widget({
      label: 'Ip Address'
    }, {
      display: true
    }),
    last_page: widget({
      label: 'Last Page'
    }, {
      display: true
    }),
    timestamp: widget({
      label: 'Timestamp'
    }, {
      display: true
    }),
    location: widget({
      label: 'Location'
    }, {
      display: true
    })
  }
})



export default data
