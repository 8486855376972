import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'leads'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    date_of_lead: widget({
      label: 'Date Of Lead'
    }, {
      display: true
    }),
    major_gift: checkbox({
      label: 'Major Gift'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    zip_code: widget({
      label: 'Zip Code'
    }, {
      display: true
    }),
    street_address: widget({
      label: 'Street Address'
    }, {
      display: true
    }),
    state: widget({
      label: 'State'
    }, {
      display: true
    }),
    city: widget({
      label: 'City'
    }, {
      display: true
    }),
    date_imported: widget({
      label: 'Date Imported'
    }, {
      display: true
    }),
    in_market: checkbox({
      label: 'In Market'
    }, {
      display: true
    }),
    existing_donor: checkbox({
      label: 'Existing Donor'
    }, {
      display: true
    }),
    email: widget({
      label: 'Email'
    }, {
      display: true
    }),
    campaign: widget({
      label: 'Campaign'
    }, {
      display: true
    }),
    client: widget({
      label: 'Client'
    }, {
      display: true
    }),
    existing_donor_display: widget({
      label: 'Existing Donor Display'
    }, {
      display: true
    }),
    in_market_display: widget({
      label: 'In Market Display'
    }, {
      display: true
    }),
    geopoint: widget({
      label: 'Geopoint'
    }, {
      display: true
    }),
    latitude: widget({
      label: 'Latitude'
    }, {
      display: true
    }),
    longitude: widget({
      label: 'Longitude'
    }, {
      display: true
    }),
    donor_table_updated: checkbox({
      label: 'Donor Table Updated'
    }, {
      display: true
    })
  }
})



export default data
