import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  SelectedRows
} from 'components'
import {
  Box,
  Grid,
  Container,
  Paper
} from '@mui/material'
import MUIDataTable from 'mui-datatables'
import _ from 'underscore'
import { useNavigate } from 'react-router-dom'

export const ListViewServerSide = ({
  formName,
  Toolbar,
  ...props
}) => {
  const [globalState, globalActions] = useGlobal() // eslint-disable-line
  const params = context.getParams()
  const navigate = useNavigate()

  const {
    rowsPerPage
  } = globalState.controls.datatables

  const form = globalState.forms[formName]
  React.useEffect(() => {
    form.queryParams = context.merge(form.queryParams, {
      count: true,
      params: {
        qty: rowsPerPage,
        start: 0
      }
    })
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: context.calcQueryParams(form),
      spinner: {
        content: 'Loading ListView Data. Please Wait...'
      }
    })
  }, [globalActions.api, form, rowsPerPage])

  React.useEffect(() => {
    if (params.sys_id) {
      globalActions.control.publish('setForm', {
        action: {
          formName,
          type: 'button',
          name: '@load',
          value: params.sys_id
        }
      })
    }
    //eslint-disable-next-line
  }, [])

  if (!globalState.repo[form.table]) {
    return (
      <>
        <Container>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MUIDataTable
                  title={props.title}
                />
                <Box sx={{
                  color: '#e0e0e0',
                  position: 'relative',
                  top: -20,
                  left: 5
                }}>LVSS</Box>

              </Grid>
            </Grid>
          </Paper>
        </Container>
      </>
    )
  }

  const onTableChange = _.debounce(function (action, tableState) {
    const params = {}
    const {
      columns,
      searchText,
      page,
      rowsPerPage,
      sortOrder
    } = tableState
    switch (action) {
      case 'propsUpdate':
      case 'rowSelectionChange':
        break
      default:
        params.qty = rowsPerPage
        params.start = page * rowsPerPage
        if (sortOrder.name) {
          params.orderby = `${sortOrder.name} ${sortOrder.direction}`
        }
        if (tableState.searchText) {
          const text = searchText.split(' ')
          const where = []
          text.forEach(item => {
            if (!item) { return }
            columns.forEach(col => {
              if (col.searchable) {
                where.push(`${col.name} ilike '%${item}%'`)
              }
            })
          })
          params.where = where.join(' OR ')
        } else {
          params.where = ''
        }
        form.queryParams = context.merge(form.queryParams, {
          params: params
        })
        globalActions.api.performApi({
          apiName: 'Events',
          apiPath: '/data',
          apiAction: 'query',
          apiPayload: context.calcQueryParams(form)
        })
    }
  }, 1000)

  const datatable = globalState.repo[form.table] || { undefined }

  const options = {
    count: datatable.count,
    serverSide: true,
    onTableChange: onTableChange,
    filterType: 'multiselect',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20, 50, 75, 100],
    rowsSelected: datatable.config.rowsSelected,
    customToolbar: () => {
      return (
        <Toolbar
          formName={formName}
        />
      )
    },
    customToolbarSelect: () => { },
    onRowClick: (rowData, rowMeta) => {
      navigate(`?sys_id=${context.getCellData(rowData, form.sysIdx)}`, { replace: false })
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'rowClick',
        rowData,
        rowMeta
      })
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'selectionChange',
        curRowSelected,
        allRowsSelected,
        rowsSelected
      })
    },
    onRowsDelete: (rowsDeleted, deleteData, newTableData) => {
      globalActions.tables.setTable({
        tableName: form.table,
        formName,
        type: 'deleteRows',
        selectedRows: rowsDeleted,
        deleteData
      })
    },
    onChangeRowsPerPage: (numberOfRows) => {
      globalActions.control.mergeState({
        controls: {
          datatables: {
            rowsPerPage: numberOfRows
          }
        }
      })
      globalActions.control.setUserData({
        'controls.datatables': {
          controls: {
            datatables: {
              rowsPerPage: numberOfRows
            }
          }
        }
      })
    },
    customSort: (data, colIndex, order, meta) => {
      return data.sort((a, b) => {
        return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1)
      })
    }
  }


  return (
    <>
      {
        context.environment !== 'production' &&
        form?.cards?.SelectFilters &&
        <form.cards.SelectFilters formName={formName} />
      }
      <MUIDataTable
        title={props.title}
        columns={context.isUndefined(datatable.columns) ? form.muidatatable.tableHeaders : datatable.columns}
        options={options}
        data={datatable.rows}
      />
      <Box sx={{
        color: '#e0e0e0',
        position: 'relative',
        top: -20,
        left: 5
      }}>LVSS</Box>
      <SelectedRows formName={formName} />
    </>
  )
}

export default ListViewServerSide
