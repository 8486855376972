import * as React from 'react'
import useGlobal from 'store'
import { Box, Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SystemFields,
  SaveButtons,
  TextField,
} from 'components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export default function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const url = 'https://s3.amazonaws.com/' + fields.bucket.widget.value + '/' + fields.key.widget.value

  return (
    <>

      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Images'
          >
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} >
          <TextField
            {...fields.key.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.bucket.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...fields.filesize.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            {...fields.type.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            {...fields.height.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            {...fields.width.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} >
          <Box
            onClick={() => {
              navigator.clipboard.writeText(url)
            }}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            {url} <ContentCopyIcon />
            <br /><br />
            <img alt="nonprofit" style={{ maxWidth: 500 }} src={url} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
