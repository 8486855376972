import { createSharedStoreHook } from 'shared-store-hook'
import context from 'lib/context'

export const useImageLibrary = createSharedStoreHook({
    initialState: {
        images: [],
        insertFormat: 'img',
        open: false,
        client: '',
        campaign: '',
        selection: { start: 0, end: 0 },
        bucket: (context.environment === 'production') ? 'donate.resources' : 'caringcent-test-bucket',
    }
})