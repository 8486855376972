import * as React from 'react'
import useGlobal from 'store'
import {
  Grid
} from '@mui/material'

import {
  Image,
  TextField
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields
  console.log(fields)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Image
            {...fields.secure_image_url.widget}
            onChange={onEvent}
            formName={formName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.secure_image_type.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.share_message.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.share_url.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>
    </>
  )
}
