import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'sys_account'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    user_alias: widget({
      label: 'User Alias'
    }, {
      display: true
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true
    }),
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    role: widget({
      label: 'Role'
    }, {
      display: true
    }),
    start_date: widget({
      label: 'Start Date'
    }, {
      display: true
    }),
    date_terms_accepted: widget({
      label: 'Date Terms Accepted'
    }, {
      display: true
    }),
    password: widget({
      label: 'Password'
    }, {
      display: true
    }),
    user_id: widget({
      label: 'User Id'
    }, {
      display: true
    }),
    host: widget({
      label: 'Host'
    }, {
      display: true
    }),
    remote_addr: widget({
      label: 'Remote Addr'
    }, {
      display: true
    }),
    password_fail_count: widget({
      label: 'Password Fail Count'
    }, {
      display: true
    }),
    setup: widget({
      label: 'Setup'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    status_notes: widget({
      label: 'Status Notes'
    }, {
      display: true
    }),
    settings: widget({
      label: 'Settings'
    }, {
      display: true
    }),
    notification_email_address: widget({
      label: 'Notification Email Address'
    }, {
      display: true
    }),
    supported_id: widget({
      label: 'Supported Id'
    }, {
      display: true
    })
  }
})



export default data
