
import * as React from 'react'

import { FormLabel, FormControl, FormHelperText, OutlinedInput, Select, MenuItem } from '@mui/material'
import { Alert } from '@mui/material'
import context from 'lib/context'

export const Component = ({ options, name, value, error, helperText, required, onChange, ...props }) => {

  const handleChange = (event) => {
    const newEvent = {
      target: {
        type: 'input',
        name: name
      }
    }

    if (typeof event.target.value !== 'string') {
      const value = event.target.value.filter(item => item !== '')
      newEvent.target.value = value.join(',')
    } else {
      newEvent.target.value = value
    }
    onChange(newEvent)
  }
  return (
    <>
      <span error-id={name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend'>
          {
            props.label
          }
          {
            required &&
            <span style={{ color: 'red', fontSize: '.8em' }}>*</span>
          }
        </FormLabel>

        <Select
          name={name}
          value={value ? value.split(',') : ['']}
          multiple
          onChange={handleChange}
          input={<OutlinedInput />}
          fullWidth
          error={Boolean(error)}
          {...props}
          sx={{
            ...context.inputColors
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={{ fontFamily: option }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
        {helperText &&
          <FormHelperText>{helperText}</FormHelperText>}
        {error &&
          <Alert severity='error'>{error}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
