import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SaveButtons,
  SystemFields,
  TextField,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Blacklist'
          >
            All attempted formbuilder submissions
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...fields.source_ip.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.email_address.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.attempted_amount.widget}
            onChange={onEvent}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            {...fields.client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fields.campaign.widget}
            onChange={onEvent}
          />
        </Grid> */}
        <Grid item xs={12}>
          <br />
          <SaveButtons formName={formName} />
        </Grid>
      </Grid>
    </>
  )
}
