import * as React from 'react'
import {
  Alert,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
} from '@mui/material'


export const Component = (props) => {
  const id = props.id || props.name
  const name = props.name || props.id

  return (
    <>
      <span error-id={name} />
      <FormControl component='fieldset' fullWidth>
        {props.headerText && <FormLabel component='legend'>{props.headerText}</FormLabel>}
        {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>* Required</span>}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                checked={!!props.checked}
                onChange={props.onChange}
                name={name}
                title={name}
                value={props.value || ''}
                color={props.color}
              />
            }
            label={props.label}
          />
        </FormGroup>
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
