import * as React from 'react'
import {
  Box,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material'
import useGlobal from 'store'
import context from 'lib/context'
import {
  Header,
  ActionButton,
  MultiSelectNew,
  ToolBar
} from 'components'
//import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro'
import { DataGrid } from '@mui/x-data-grid'

const columns = [
  {
    field: 'sys_id',
    headerName: 'ID',
    type: 'string'
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string'
  },
  {
    field: 'chargingamount',
    headerName: 'Chargingamount',
    type: 'number',
    valueGetter: (params) => '$' + context.formatMoney(params.row.chargingamount)
  },
  {
    field: 'client_id',
    headerName: 'Client Id',
    type: 'string'
  },
  {
    field: 'campaign_id',
    headerName: 'Campaign Id',
    type: 'string'
  },
  {
    field: 'email_address',
    headerName: 'Email Address',
    type: 'string',
    minWidth: 250
  },
  {
    field: 'createddate',
    headerName: 'Createddate',
    type: 'string'
  },
  {
    field: 'lastupdateddate',
    headerName: 'Lastupdateddate',
    type: 'string'
  },

  {
    field: 'tx_type',
    headerName: 'Tx Type',
    type: 'string'
  },

  {
    field: 'effective_month',
    headerName: 'Effective Month',
    type: 'number',
    valueGetter: (params) => params.row.effective_month.toString().padStart(2, '0')
  },
  {
    field: 'effective_year',
    headerName: 'Effective Year',
    type: 'number',
    valueGetter: (params) => params.row.effective_year.toString()
  },
  {
    field: 'email_sent',
    headerName: 'Email Sent',
    type: 'string'
  },
  {
    field: 'test_account',
    headerName: 'Test Account',
    type: 'string'
  },

  {
    field: 'form_builder_template_id',
    headerName: 'Form Builder Id',
    type: 'string'
  },
  {
    field: 'success_email',
    headerName: 'Success Email',
    type: 'string'
  },
  {
    field: 'decline_email',
    headerName: 'Decline Email',
    type: 'string'
  },
  {
    field: 'notes',
    headerName: 'Notes',
    type: 'string'
  }
]

const TX_STATUS = ['pending', 'queued', 'skipped', 'complete', 'error', 'new']
const TX_STATUS_INTIAL = ['pending', 'queued', 'complete', 'error']
const TX_TYPE = ['rally']
const pageSizeOptions = [10, 20, 30, 40, 50, 100]

const Component = ({ ...rest }) => {
  const [, globalActions] = useGlobal()  // eslint-disable-line
  const [selectedIds, setSelectedIds] = React.useState([])
  const [tx, setTx] = React.useState({ rows: [], columns: [] })
  const [lastUpdate, setLastUpdate] = React.useState('') // eslint-disable-line
  const [updateFrequency, setUpdateFrequency] = React.useState(30000)
  const [visibleRows, setVisibleRows] = React.useState(10)
  const [statusFilter, setStatusFilter] = React.useState(TX_STATUS_INTIAL)
  const [typeFilter, setTypeFilter] = React.useState(TX_TYPE)

  const where = {}
  if (!context.isEmpty(typeFilter)) {
    where['tx_type'] = typeFilter
  }
  if (!context.isEmpty(statusFilter)) {
    where['status'] = statusFilter
  }
  where['effective_month'] = new Date().getMonth() + 1
  where['effective_year'] = new Date().getFullYear()

  const settings = {
    apiName: 'Events',
    apiPath: '/data',
    apiAction: 'query',
    apiPayload: {
      obj: 'month_transactions',
      params: {
        where: where
      },
      result_format: 'raw',
    },
    stateReducer: (store, response) => {
      if (response && response.rows) {
        setTx(response)
      }
    },
    spinner: {
      content: 'Loading pending and queued transactions. Please Wait...'
    }
  }
  context.useInterval(() => {
    globalActions.api.performApi(settings)
  }, 30000)

  const onRefresh = () => {
    globalActions.api.performApi(settings)
  }

  return (<>
    <Header label='RallyGive Billing' title='Billing' />
    <ToolBar
      inspector
      formbuilder
    />

    <Container sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <MultiSelectNew
            label='Month Transaction Status'
            callback={setStatusFilter}
            options={TX_STATUS}
            initialSelected={TX_STATUS_INTIAL}
          />
        </Grid>
        <Grid item xs={4}>
          <MultiSelectNew
            label='Month Transaction Type'
            callback={setTypeFilter}
            options={TX_TYPE}
          />
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Visible Rows</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={visibleRows}
              label='Visible Rows'
              onChange={e => setVisibleRows(e.target.value)}
            >
              {pageSizeOptions.map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Update Frequency</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={updateFrequency}
              label='UpdateFrequency'
              onChange={e => setUpdateFrequency(e.target.value)}
            >
              <MenuItem value={5000}>5 Sec</MenuItem>
              <MenuItem value={10000}>10 Sec</MenuItem>
              <MenuItem value={15000}>15 Sec</MenuItem>
              <MenuItem value={20000}>20 Sec</MenuItem>
              <MenuItem value={30000}>30 Sec</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={onRefresh}>Refresh</Button>
        </Grid>
        <Grid item xs={12}>
          {lastUpdate && <>Last Updated: {lastUpdate}</>}
        </Grid>
      </Grid>
      <Box sx={{ height: '80vh', width: '100%' }}>
        <DataGrid
          getRowId={(row) => row.sys_id}
          rows={tx.rows}
          columns={columns}
          checkboxSelection
          autoheight
          density='compact'
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedIds(newSelectionModel)
          }}
          rowSelectionModel={selectedIds}
        />
      </Box>
      <Box sx={{ mb: 15, mt: 5 }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={4}>
            <ActionButton
              data-handler='billing'
              data-action='download-rally-metrics'
              tooltip='Download Rally Metrics'
            >
              Download Rally Metrics Excel File
            </ActionButton>
          </Grid>
          <Grid item xs={8}>
            <b>Step 1.</b> Download a blank Rally Metrics File. Put the Rally Metrics in the spreadsheet column labeled "Metric Value".
            Do not modify any of the other columns (headers or data).
          </Grid>
          <Grid item xs={4}>
            <FileInput
              placeholder='Click to select file'
              onChange={handleFileChange}
            />
            {context.isNotEmpty(fileToUpload) && <ActionButton
              data-handler='billing'
              data-action='upload-rally-metrics-file'
              extraParams={{ files: [fileToUpload] }}
              tooltip='Upload Rally Metrics File'
            >
              Upload Rally Metrics Excel File
            </ActionButton>}
          </Grid>
          <Grid item xs={8}>
            <b>Step 2.</b> After filling in the blank rally metrics spreadsheet, upload it to
            the system. To upload, drag it over the blue box and press the "Upload Rally
            Metrics File" button when it appears. After the file is processed, the pending
            rally transactions will populate in the table above. You can review the
            transactions before they are processed.
          </Grid> */}
          <Grid item xs={4}>
            <ActionButton
              data-handler='billing'
              data-action='enqueue-rally-objects'
              data-selected={selectedIds}
              tooltip={selectedIds ? 'Enqueue Selected Rally Transactions' : 'Enqueue All Rally Transactions'}
              apiCallback={(store, response) => globalActions.api.performApi(settings)}
              data-title='Enqueue transactions?'
              data-warning={`
          Are you sure you want to queue these transactions? This means the credit card
          will be charged.`}
            >
              {context.isEmpty(selectedIds) ? 'Enqueue All Transactions' : `Enqueue ${selectedIds.length} Selected Transactions`}
            </ActionButton>
          </Grid>
          <Grid item xs={8}>
            Enqueue the transactions for processing. Select individual transactions
            or queueu them all at once. Press the button. Press dequeue to stop the
            process from running. [TBD] enhance this copy to provide instructions
          </Grid>
          <Grid item xs={4}>
            <ActionButton
              data-handler='billing'
              data-action='dequeue-rally-objects'
              data-selected={selectedIds}
              tooltip='Dequeue Rally Transactions'
              apiCallback={(store, response) => globalActions.api.performApi(settings)}
            >
              {context.isEmpty(selectedIds) ? 'Dequeue All Transactions' : `Dequeue ${selectedIds.length} Selected Transactions`}
            </ActionButton>
          </Grid>
          <Grid item xs={8}>
            In case of emergency, stop all transactions by dequeueing them from getting
            processed.
          </Grid>
          <Grid item xs={6} md={4}>
            <ActionButton
              data-handler='billing'
              data-action='skip-rally-objects'
              data-selected={selectedIds}
              tooltip='Skip Rally Billing Objects'
              apiCallback={(store, response) => globalActions.api.performApi(settings)}
            >
              Skip <i>&nbsp;{selectedIds.length} Selected&nbsp;</i> Rally Billing Object
            </ActionButton>
          </Grid>
          <Grid item xs={6} md={8}>
            Skip individual transactions from being processed. This is useful if a
            transaction is invalid or needs to be skipped for some reason.
          </Grid>
        </Grid>
      </Box>
    </Container >

  </>
  )
}

export default Component
