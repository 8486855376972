import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SystemFields,
  TextField,
  SaveButtons,
} from 'components'
import context from 'lib/context'
import { useImageLibrary } from 'store/state'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const textAreaRef = React.useRef()
  const [imageLibrary, setImageLibrary] = useImageLibrary()// eslint-disable-line
  const form = globalState.forms[formName]
  const fields = form.fields

  React.useEffect(() => {
    return () => {
      setImageLibrary.setPartialState({ client: '' })
      globalActions.control.deleteStateKey(['repo', 'image_library'])
    }
  }, [globalActions.control, setImageLibrary])

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='Email Templates'
          >
            Instructions: An email templates defines the outgoing emails that are sent at specific events.
            An event would be something like donation receipt, expired card, plaid account requires update, etc.
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.event.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.supported_tags.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.subject.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.html_template.widget}
            inputRef={textAreaRef}
            onChange={onEvent}
            onKeyDown={(event) => {
              if (event.code === 'KeyI' && event.ctrlKey) {
                event.stopPropagation()
                event.preventDefault()
                setImageLibrary.setPartialState({
                  formName,
                  insertFormat: 'img',
                  selection: {
                    start: event.target.selectionStart,
                    end: event.target.selectionEnd
                  },
                  fieldName: fields.html_template.widget.name,
                  inputRef: textAreaRef,
                  open: true
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div dangerouslySetInnerHTML={{ __html: context.swapFields(fields.html_template.widget.value) }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <br />
        <SaveButtons formName={formName} />
      </Grid>
    </>
  )
}
