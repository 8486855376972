export const makeLabel = (option) => {
    const value = []
    value.push(option.id)
    value.push('Handle: ')
    value.push(option.program_handle)
    value.push('\n')
    value.push('Run Mode: ')
    value.push(option.execution_environment || 'Manual')
    if (option.execution_environment === 'SQS Event' && option.in_effect) {
        value.push('\n')
        value.push('\nScheduled: True')
    }
    return value.join(' ')
}