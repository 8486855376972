import * as React from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow
} from '@react-google-maps/api'
import useGlobal from 'store'

const MapContainer = () => {
  const [globalState, globalActions] = useGlobal()

  const { maps } = globalState.controls

  const mapStyles = {
    height: '100vh',
    width: '100%'
  }

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-donor-map-data',
      spinner: {
        content: 'Reading Data. Please Wait...'
      }
    })
  }, [globalActions.api])

  const onSelect = item => {
    globalActions.control.mergeState({
      controls: {
        maps: {
          donorMap: {
            selected: item
          }
        }
      }
    })
  }

  return (
    <LoadScript
      googleMapsApiKey={maps.apiKey}
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={4}
        center={maps.defaultCenter}
      >
        {
          maps.donorMap.locations.map(item => {
            return (
              <Marker
                key={item.sys_id}
                position={item.location}
                onClick={() => onSelect(item)}
              />
            )
          })
        }
        {
          maps.donorMap.selected.location &&
          (
            <InfoWindow
              position={maps.donorMap.selected.location}
              clickable
              onCloseClick={() => globalActions.control.mergeState({
                maps: {
                  donorMap: {
                    selected: {}
                  }
                }
              })}
            >
              {maps.donorMap.selected.label}
            </InfoWindow>
          )
        }
      </GoogleMap>
    </LoadScript>
  )
}

export default MapContainer
