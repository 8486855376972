import context from 'lib/context'
import system from '../system'
import {
  widget,
  datetime,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'

import { Box } from '@mui/material'

export const table = 'sys_log'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    sys_created: datetime({
      label: 'Time'
    }, {
      display: true,
    }),
    sys_modified_by: widget({
      label: 'Modified By'
    }, {
      fetch: true,
      searchable: true,
    }),
    app_name: widget({
      label: 'App'
    }, {
      fetch: true,
      searchable: true,
    }),
    source_ip: widget({
      label: 'User'
    }, {
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return <>
          {row.sys_modified_by}<br />{value}
        </>
      },
    }),
    function: widget({
      label: 'Function'
    }, {
      searchable: true,
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        const row = tableMeta.tableData[tableMeta.rowIndex]
        return <>
          <Box noWrap align='left' sx={{
            maxWidth: 250,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {row.app_name}<br />{value}
          </Box>
        </>
      },
    }),
    message: widget({
      label: 'Message',
      multiline: true
    }, {
      display: true,
      searchable: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return
        return <>
          <Box align='left' sx={{
            maxWidth: 450,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {value}
          </Box>
        </>
      }
    })
  }
})



export default data
