import * as React from 'react'
import { createRoot } from 'react-dom/client'
import animateScrollTo from 'animated-scroll-to'
import awsconfig from 'aws-exports'
import GetInput from 'components/modals/GetInput'
import OkCancel from 'components/modals/OkCancel'
import Alert from 'components/modals/Alert'
import deepmerge from 'deepmerge'
import { Auth } from 'aws-amplify'

import {
  Typography
} from '@mui/material'

const environment = awsconfig.aws_cloud_logic_custom[0].endpoint.split('/').pop()

function ContextLogger(...args) {
  if (environment !== 'production') {
    const e = new Error()
    const re = /(\w+)@|at (\w+) \(/g
    const m = re.exec(e.stack)
    let prefix = '[Unknown]'
    if (m) {
      const callerName = m[1] || m[2]
      const s = new Date().toLocaleString()
      prefix = '[' + s + '] ' + callerName + ':  ^'
    }
    const suffix = '$'
    console.log(prefix, ...args, suffix)
  }
}

ContextLogger('ContextLogger is activated while in non-production mode')

export const context = {
  gradient: 'linear-gradient(90deg,  rgba(0,103,128,1)  15%, rgba(0,149,175,1) 36%)',
  gradientVert: 'linear-gradient(180deg,  rgba(0,103,128,1)  15%, rgba(0,149,175,1) 36%)',
  gradientOld: '-webkit-linear-gradient(left, #208dB9 0%,#1ba1B9 25%,#1f9fa5 50%,#1aA7a3 75%,#00Bb91 100%)',
  inputColors: {
    backgroundColor: 'rgba(0,153,175, .04)',
    color: 'rgba(0, 0, 0, 1)',
  },
  merge: deepmerge,
  environment: environment,
  log: ContextLogger,
  signOut: Auth.signOut,
  getTimeRemaining: (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  },
  formatMoney: (amount, places = 2) => Number(amount).toFixed(places).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  formatDate: (input) => {
    const date = new Date(input)
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    }
    return input
  },
  formatDateTime: (input) => {
    const date = new Date(input)
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear().toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    }
    return input
  },
  formatDbDate: (input) => {
    const date = new Date(input)
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear().toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return year + '-' + month + '-' + day
    }
    return input
  },
  months: [
    null,
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  round: (amount) => Number(amount).toFixed(2),
  getOffset: (el) => {
    let _x = 0
    let _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  },
  scrollToEl: (el) => window.scrollTo(0, context.getOffset(el).top),
  scrollToRef: (ref) => window.scrollTo(0, ref.current.offsetTop),
  dt: () => new Date().toLocaleDateString() + '  ' + new Date().toLocaleTimeString(),
  animateScrollTo: (selector, options) => {
    animateScrollTo(document.querySelector(selector), options)
  },
  animateScrollToName: (selector, options) => {
    if (document.querySelector(`label[for=${selector}]`)) {
      animateScrollTo(document.querySelector(`label[for=${selector}]`), options)
    } else if (document.querySelector(`input[name=${selector}]`)) {
      animateScrollTo(document.querySelector(`input[name=${selector}]`), options)
    } else if (document.querySelector(`input[id=${selector}]`)) {
      animateScrollTo(document.querySelector(`input[id=${selector}]`), options)
    }
  },
  calc_fee: (amount) => {
    return amount * 0.029 + 0.3
  },
  host: () => {
    if (window.location.hostname === 'localhost') {
      return 'www.caringcent.org'
    } else {
      return window.location.hostname
    }
  },
  url: () => {
    if (window.location.hostname === 'localhost') {
      return 'https://www.caringcent.org' + window.location.pathname
    } else {
      return 'https://' + window.location.hostname + window.location.pathname
    }
  },
  shallowEqual: (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      return false
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }
    return true
  },
  deepEqual: deepEqual,
  isObject: (value) => {
    return !!(value && typeof value === 'object' && !Array.isArray(value))
  },
  isString: (obj) => {
    if (typeof obj === 'string' || obj instanceof String) {
      return true
    }
  },
  isArray: (value) => {
    return !!(value && typeof value === 'object' && Array.isArray(value))
  },
  isFunction: (value) => {
    return typeof value === 'function'
  },
  isEmpty: (obj) => {
    for (const i in obj) return false // eslint-disable-line
    return true
  },
  isNotEmpty: (obj) => {
    for (const i in obj) return true // eslint-disable-line
    return false
  },
  isMetricEmpty: (metrics) => {
    for (const metric of Object.values(metrics)) {
      if (metric.selected === true) {
        return false
      }
    }
    return true
  },
  isNumberOnly: (value) => {
    if (typeof value === 'string') {
      return value.match(/^-?[0-9]*\.?[0-9]*$/)
    }
    return typeof value === 'number'
  },
  isInteger: (value) => {
    return Number.isInteger(value)
  },
  isPositiveInteger: (value) => {
    if (typeof value !== 'string') {
      return false
    }
    const num = Number(value)
    if (Number.isInteger(num) && num > 0) {
      return true
    }
    return false
  },
  isTrue: (obj) => {
    if (context.isObject(obj) && context.isEmpty(obj)) {
      return false
    }
    if (context.isArray(obj) && context.isEmpty(obj)) {
      return false
    }
    return Boolean(obj)
  },
  isUndefined: (v) => {
    if (v === undefined) return true
    if (v === null) return true
    if (context.shallowEqual(v, { undefined })) return true
    if (context.isArray(v)) {
      let allUndefined = true
      for (const element of v) {
        if (context.isDefined(element)) {
          allUndefined = false
        }
      }
      return allUndefined
    }
    return false
  },
  isDefined: (v) => {
    if (v === undefined) return false
    if (v === null) return false
    if (context.shallowEqual(v, { undefined })) return false
    return true
  },
  getInitials: (name = '') => {
    return name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('')
  },
  b64toBlob: (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
  },
  getScreenshot: (el = 'html', filename = '') => {
    return {
      html: document.getElementsByTagName('html')[0].innerHTML,
      filename: filename
    }
  },
  humanFileSize: (bytes, si = true, dp = 1) => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  },
  copy: (obj) => {
    return recursiveAssign({}, obj)
  },
  jsonCopy: (obj) => {
    return JSON.parse(JSON.stringify(obj))
  },
  getQuery: () => {
    return new URLSearchParams(window.location.search)
  },
  getParams: () => {
    const query = new URLSearchParams(window.location.search)
    const data = {}
    for (const [key, value] of query) {
      data[key] = value
    }
    return data
  },
  useWindowDimensions: () => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
  },
  getResultPopup: (message, title, inputLabel = '', defaultValue = '') => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <GetInput
            message={message}
            title={title}
            label={inputLabel}
            defaultValue={defaultValue}
            cancelLabel='Cancel'
            saveLabel='Save'
            onSave={(result) => {
              finalize()
              resolve(result)
            }}
            onCancel={(result) => {
              finalize()
              reject(result)
            }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  okCancelPopup: (message, title, okLabel = 'Ok', cancelLabel = 'Cancel') => {

    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <OkCancel
            message={message}
            title={title}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            onOk={() => {
              finalize()
              resolve('ok')
            }}
            onCancel={() => {
              finalize()
              reject('cancel')
            }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  alertPopup: (message, title) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }
      const PopupContent = () => {
        return (
          <Alert
            message={message}
            title={title}
            buttonLabel='Ok'
            onCancel={() => {
              finalize()
              resolve()
            }}
          />
        )
      }

      createRoot(node).render(PopupContent())
    })
  },
  handleApiError: (error) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <Alert
            message={<>
              <Typography sx={{
                fontSize: 12,
                pt: 2,
                color: 'red'
              }}>
                {error.message}
              </Typography>
            </>}
            title='Unhandled API Gateway Error'
            buttonLabel='Ok'
            onCancel={() => {
              finalize()
              resolve()
            }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  handlePythonException: (exc) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <Alert
            message={<>
              <Typography sx={{
                fontSize: 12,
                pt: 2,
                color: 'red'
              }}>
                {exc.value}
              </Typography>

              <Typography sx={{
                fontSize: 12,
                pt: 2
              }}>
                {exc.type}
              </Typography>

              <Typography sx={{
                fontSize: 12,
                pt: 2
              }}>
                <pre>
                  {exc.traceback}
                </pre>
              </Typography>
            </>}
            title='Unhandled Python Exception'
            buttonLabel='Ok'
            onCancel={() => {
              finalize()
              resolve()
            }}
          />
        )
      }

      createRoot(node).render(PopupContent())
    })
  },
  useInterval: (callback, delay, callInitial = true) => {
    const savedCallback = React.useRef()

    // Remember the latest callback.
    React.useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    React.useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])

    // Call initial.
    React.useEffect(() => {
      if (callInitial) {
        callback()
      }
    }, [callInitial]) // eslint-disable-line

  },
  redirect: (redirect) => {
    if (redirect.includes('http') && redirect.includes('//')) {
      window.location.replace(redirect)
    } else if (window.location.hostname === 'localhost') {
      window.location.replace('http://' + window.location.hostname + ':' + window.location.port + redirect)
    } else {
      window.location.replace('https://' + window.location.hostname + redirect)
    }
  },
  imageUrl: (path, wrap = false, base = 'https://s3.amazonaws.com/donate.resources') => {
    if (path.includes('https://')) {
      return `${wrap ? 'url(' : ''}${path}${wrap ? ')' : ''}`
    }
    return `${wrap ? 'url(' : ''}${base}${path}${wrap ? ')' : ''}`
  },
  invertList: (list, key) => {
    const result = {}
    for (const i of list) {
      result[i[key]] = result[i[key]] || { undefined }
      result[i[key]].push(i)
    }
    return result
  },
  to_json: (data) => {
    return JSON.stringify(data)
  },
  textFromList: (list, splitChar = ',', preserveWhiteSpace = false) => {
    if (preserveWhiteSpace)
      return '{' + list.join(splitChar) + '}'
  },
  textToList: (text, splitChar = ',', preserveWhiteSpace = false) => {
    if (text && text.length && text[0] === '{') {
      text = text.substring(1, text.length - 1)
    }
    if (!preserveWhiteSpace) {
      return text.split(splitChar).map((fragment) => {
        return fragment.trim()
      })
    }
    return text.split(splitChar)
  },
  readFile: (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  },
  convertFile: (file) => {
    const data = {
      filename: file.name,
      size: file.size.toString(),
      last_modified_date: file.lastModifiedDate.toString(),
      mime_type: file.type
    }
    context.readFile(file).then(result => data['data'] = result)
    return data
  },
  convertFiles: (files) => {
    return files.map((file) => context.convertFile(file))
  },
  range: (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step)),
  genKey: row => row.table + '-' + row.sys_id,
  getTextData: (obj) => {
    if (obj.props) {
      return context.getTextData(obj.props)
    }
    if (obj.children) {
      return context.getTextData(obj.children)
    }
    return obj
  },
  getCellData: (rowData, pos) => {
    return context.getTextData(rowData[pos])
  },
  make_where: (filters) => {
    const sql = []
    if (!filters) {
      return
    }
    let connect = ''
    for (let [key, val] of Object.entries(filters)) {
      if (context.isArray(val) && val.length === 0) {
        continue
      }
      if (context.isString(val) && val.length === 0) {
        continue
      }
      if (val === '@@') {
        continue
      }
      if (val === '@@NULL') {
        val = null
      }
      if (val === '@@EMPTY') {
        val = "@@''" // eslint-disable-line
      }
      if (connect) {
        sql.push(connect)
      }
      if (val === null) {
        if (key.includes('!')) {
          key = key.replace('!', '')
          sql.push(`${key} is not NULL`)
        } else {
          sql.push(`${key} is NULL`)
        }
      } else if (Array.isArray(val) && !key.includes('><')) {
        //wrap all elements of val in quotes
        val = val.map((v) => {
          return `'${v}'`
        })
        if (key.includes('!')) {
          key = key.replace('!', '')
          sql.push(`${key} not in (${val.join(',')})`)
        } else {
          sql.push(`${key} in (${val.join(',')})`)
        }
      } else {
        let op, caseInsensitive
        if (key.includes('<>')) {
          key = key.replace('<>', '')
          op = '<>'
        } else if (key.includes('!=')) {
          key = key.replace('!=', '')
          op = '<>'
        } else if (key.includes('!><')) {
          key = key.replace('!><', '')
          op = 'not between'
        } else if (key.includes('><')) {
          key = key.replace('><', '')
          op = 'between'
        } else if (key.includes('!%')) {
          key = key.replace('!%', '')
          op = 'not like'
        } else if (key.includes('%%')) {
          key = key.replace('%%', '')
          op = '%'
        } else if (key.includes('%>')) {
          key = key.replace('%>', '')
          op = '%>'
        } else if (key.includes('<%')) {
          key = key.replace('<%', '')
          op = '<%'
        } else if (key.includes('==')) {
          key = key.replace('==', '')
          op = '='
        } else if (key.includes('<=')) {
          key = key.replace('<=', '')
          op = '<='
        } else if (key.includes('>=')) {
          key = key.replace('>=', '')
          op = '>='
        } else if (key.includes('<')) {
          key = key.replace('<', '')
          op = '<'
        } else if (key.includes('>')) {
          key = key.replace('>', '')
          op = '>'
        } else if (key.includes('%')) {
          key = key.replace('%', '')
          op = 'ilike'
        } else if (key.includes('!~*')) {
          key = key.replace('!~*', '')
          op = '!~*'
          caseInsensitive = true
        } else if (key.includes('~*')) {
          // String matches regular expression, case insensitively
          // 'thomas' ~* 'T.*ma' → True
          key = key.replace('~*', '')
          op = '~*'
        } else if (key.includes('!~')) {
          // String does not match regular expression, case sensitively
          // 'thomas'!~ 't.*max' → True
          key = key.replace('!~', '')
          op = '!~'
        } else if (key.includes('~')) {
          // String matches regular expression, case sensitively
          // 'thomas' ~ 't.*ma' → True
          key = key.replace('~', '')
          op = '~'
        } else if (key.includes('!')) {
          key = key.replace('!', '')
          op = '<>'
        } else if (key.includes('=')) {
          key = key.replace('=', '')
          op = '='
        } else {
          op = '='
        }

        if (key.includes('#')) {
          key = key.replace('#', '')
          op = '='
          caseInsensitive = true
        }

        // if val is a string and starts with @@, then it is a raw sql
        if (typeof val === 'string' && val.startsWith('@@') && val.length > 2) {
          sql.push(`${key} ${op} ${val.slice(2)}`)
        } else if (['between', 'not between'].includes(op)) {
          sql.push(`${key} ${op} ${val[0]} and ${val[1]}`)
        } else {
          if (caseInsensitive) {
            sql.push(`lower(${key}) ${op} lower('${val}')`)
          } else {
            if (context.isNumberOnly(val)) {
              sql.push(`${key} ${op} ${val}`)
            } else {
              sql.push(`${key} ${op} '${val}'`)
            }
          }
        }
        connect = 'AND'
      }
    }
    return sql.join(' ')
  },
  swapFields: (html) => {
    if (!html) { return '' }
    const disclaimer = `<div style="font-style: italic">
    [Sample Values here are for demonstration purposes only. 
    Please do not use any of the information in this sample for any 
    purpose other than testing.    ** This message does not show up 
    in production views or emails. It is only visible in this editor.]
    </div>`
    const data = {
      first_name: 'John',
      last_name: 'Doe',
      email_address: 'test@email.com',
      summary: `          
Campaign: 2023 Test Campaign<br/>
Payment Type: Visa<br/>
    `,
      line_items: `
Choose an amount to donate every time the Players record a Strikeout!: <br>
    1 x $60.00 = $60.00<br/>
Choose an amount to donate every time the Players secure a Win!: 5 x $12.00 = $60.00<br/>
Choose an amount to donate every time the Players hit a Home Run!: 1 x $10.00 = $10.00<br/>
Pledge Cap: 100.00<br/>
Total Donation: 100.00<br/>
    `,
      descriptor: 'CARINGCENT  *TEST',
      share_url: 'https://caringcent.org/donate/1234',
      amount: '$100.00',
      payment_type: 'Visa',
      account_number: '4141',
      campaign: '2023 Test Campaign'
    }

    html = html.replace(/\{\{\s*(\w+)\s*\}\}/g, (match, key) => {
      return data[key] || match // Return the value if found, otherwise return the original match
    })

    html = html.replace(/\{\s*(\w+)\s*\}/g, (match, key) => {
      return data[key] || match // Return the value if found, otherwise return the original match
    })

    return html + disclaimer
  },
  deleteKey: (obj, path) => {

    if (!obj || !path) {
      return
    }

    if (typeof path === 'string') {
      path = path.split('.')
    }

    for (var i = 0; i < path.length - 1; i++) {

      obj = obj[path[i]]

      if (!context.isObject(obj)) {
        return
      }
    }

    delete obj[path.pop()]
  },
  calcQueryParams: (form) => {
    const queryParams = context.copy(form.queryParams)
    const { params } = queryParams
    if (params.columns) {
      params.columns.push('@@row_number() over (order by ' + (params.orderby || 'sys_id') + ') as row_number')
    }
    if (queryParams.filter) {
      if (context.isString(params.where) && params.where.length > 0) {
        // convert queryParams.filter to a string
        // and append it to params.where
        params.where = '(' + params.where + ') AND ' + context.make_where(queryParams.filter)
      } else {
        params.where = context.make_where(queryParams.filter)
      }
    }
    return queryParams
  },
  rot13: (str) => {
    var input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm'
    var index = x => input.indexOf(x)
    var translate = x => index(x) > -1 ? output[index(x)] : x
    return str.split('').map(translate).join('')
  }
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = context.isObject(val1) && context.isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
}

// Extract the window dimensions from the global window object.
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

function recursiveAssign(a, b) {
  if (context.isFunction(b)) return b
  if (Object(b) !== b) return b
  // Replace Arrays, do not merge
  if (context.isArray(b)) {
    a = []
    for (let i = 0; i < b.length; i++) {
      a.push(recursiveAssign(a[i], b[i]))
    }
    return a
  }
  // Merge objects, do not replace
  if (Object(a) !== a) a = {}
  for (let key in b) {
    a[key] = recursiveAssign(a[key], b[key])
  }
  return a
}
context.recursiveAssign = recursiveAssign

export default context

window.context = context