import * as React from 'react'
import { Alert, Box } from '@mui/material'
import Editor from '@monaco-editor/react'

import _ from 'underscore'
import {
  QuestionMark,
  MoreInfo
} from 'components'
const _onChange = _.debounce((event, func) => {
  func(event)
}, 500)

export default function Component({
  formName,
  onChange = () => { },
  value,
  showcount,
  moreInfo,
  ...props }) {

  if (value === null) { value = '' }
  const [_value, _setValue] = React.useState('')
  const [_count, _setCount] = React.useState(value ? value.length : 0)

  React.useEffect(() => {
    if (value !== _value) {
      _setValue(value)
      _setCount(value ? value.length : 0)
    }
  }, [value]) // eslint-disable-line

  const onEvent = (value, event) => {
    _setValue(value)
    _setCount(value ? value.length : 0)
    _onChange({
      target: {
        type: 'codefield',
        name: props.name,
        value
      }
    }, onChange)
  }
  return (
    <>
      <Box error-id={props.name} sx={{
        position: 'relative'
      }}>
        <Editor
          widget='CodeField'
          {...props}
          value={_value}
          onChange={onEvent}
        />
        {props.questionmark && <QuestionMark style={{
          position: 'absolute',
          top: -15,
          right: -15,
        }}>{props.questionmark}</QuestionMark>}
        {moreInfo && <MoreInfo>
          {moreInfo}
        </MoreInfo>}
        {showcount && showcount === 'true' && <Box sx={{
          position: 'absolute',
          top: 10,
          right: 3,
          fontSize: '80%',
          color: 'gray'
        }}>{_count}</Box>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}

      </Box>
    </>
  )
}
