import * as React from 'react'
import {
  Container,
  Grid,
} from '@mui/material'
import {
  Header
} from 'components'
import ToolBar from '../ToolBar'
import ReactMarkdown from 'react-markdown'

const Components = ({ ...rest }) => {

  return (
    <>
      <Header label='Documentation' title='Documentation' />
      <ToolBar links={[
        { label: 'Home', link: '/documentation/home' },
        { label: 'Developers', link: '/documentation/developers' },
        { label: 'Start Here', link: '/documentation/start_here' },
      ]} />
      <Container maxWidth={false} sx={{ pt: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <ReactMarkdown>
              {markdown}
            </ReactMarkdown>
          </Grid>

        </Grid>
      </Container>
    </>
  )
}

export default Components
const markdown = `

    Welcome to the documentation project for your Amazon Web Services (AWS) Amplify application!
    This comprehensive documentation aims to provide you with all the information and resources
    you need to successfully develop, deploy, and manage your application using AWS Amplify.

    AWS Amplify is a powerful platform that enables developers to build full-stack applications
    quickly and easily. It simplifies the process of creating scalable, secure, and high-performing
    applications by providing a wide range of features and services, including authentication, storage,
    APIs, analytics, and more. With Amplify, you can focus on building your application's unique features
    while leveraging the robust capabilities of AWS.

    In this documentation, we will guide you through the entire lifecycle of an Amplify application,
    from the initial setup to the deployment and ongoing maintenance. Whether you are a seasoned
    developer or new to AWS, this documentation will help you navigate the various components and


    - Getting Started: We'll walk you through the setup process, including creating an 
      AWS account, installing the Amplify CLI, and configuring your development environment.

    - Building Your Application: Learn how to define your application's infrastructure, 
      create and configure backend resources such as databases and APIs, and manage your 
      frontend codebase.

    - Authentication and Authorization: Discover how to implement user authentication and 
      authorization using Amplify's built-in authentication services, including social 
      sign-in, multi-factor authentication, and fine-grained access controls.
    
    - Data Storage and APIs: Explore how to leverage Amplify's powerful storage options, 
      including Amazon S3, Amazon DynamoDB, and Amazon AppSync, to manage and access your 
      application's data.

    - Integrations and Extensibility: Learn how to integrate third-party services and APIs 
      into your Amplify application, as well as extend Amplify's functionality through custom 
      plugins and serverless functions.

    - Deployment and Continuous Integration/Continuous Deployment (CI/CD): Understand how to 
      deploy your application to different environments, set up automated CI/CD pipelines, and 
      manage the entire deployment lifecycle.

    - Monitoring and Analytics: Explore how to gain insights into your application's 
      performance, track user behavior, and monitor logs and metrics using AWS monitoring and
      analytics services.

    - Security and Compliance: Learn about best practices for securing your Amplify 
      application, including encryption, access controls, and compliance with industry 
      standards and regulations.

    Throughout this documentation, we'll provide detailed instructions, code examples, and 
    best practices to help you make the most of AWS Amplify's features. Whether you're 
    developing a small-scale prototype or a production-ready application, this documentation 
    will be your go-to resource for all things Amplify.


`