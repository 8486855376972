
import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import { FormLabel, FormControl, FormHelperText, NativeSelect } from '@mui/material'
import { Alert } from '@mui/material'


export default function ClientDropDown(props) {
  const [globalState, globalActions] = useGlobal()
  const datatable = globalState.repo.active_customers || { undefined }
  const form = globalState.forms.active_customers || { undefined }

  React.useEffect(() => {
    globalActions.control.initForm('active_customers', false)
  }, [globalActions.control])

  React.useEffect(() => {
    if (context.isUndefined(datatable)) {
      if (context.isDefined(form)) {
        globalActions.control.reloadTable('active_customers')
      }
    }
  }, [globalActions.control, form, datatable])

  if (context.isUndefined(datatable)) { return null }
  return (
    <>
      <span error-id={props.name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend' >
          {props.label}
          {props.required && <span style={{ color: 'red', fontSize: '.8em' }}>*</span>}
        </FormLabel>

        <NativeSelect
          name={props.name}
          value={props.value || ''}
          onChange={props.onChange}
          fullWidth
          error={Boolean(props.error)}
          color={props.color}
          inputProps={{
            sx: context.inputColors
          }}
        >
          <option value=''>{props.initialLabel || 'Please select a client...'}</option>
          {datatable.rows &&
            datatable.rows.map((item) => {
              if (item.sys_id && item.client_id && item.client_name) {
                return <option key={item.sys_id} value={item.client_id}>{item.client_name}</option>
              }
              return null
            })}
        </NativeSelect>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {props.error &&
          <Alert severity='error'>{props.error}</Alert>}
      </FormControl>
    </>
  )
}
