import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'users'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    username: widget({
      label: 'Username'
    }, {
      display: true
    }),
    city: widget({
      label: 'City'
    }),
    isdonationcapexplicitlyset: checkbox({
      label: 'Isdonationcapexplicitlyset'
    }),
    lastupdateddate: widget({
      label: 'Lastupdateddate'
    }),
    firstname: widget({
      label: 'Firstname'
    }, {
      display: true
    }),
    campaignid: widget({
      label: 'Campaignid'
    }),
    dob: widget({
      label: 'Dob'
    }),
    lastname: widget({
      label: 'Lastname'
    }, {
      display: true
    }),
    address: widget({
      label: 'Address'
    }),
    zipcode: widget({
      label: 'Zipcode'
    }),
    state: widget({
      label: 'State'
    }),
    registrationstatus: widget({
      label: 'Registrationstatus'
    }),
    orgid: widget({
      label: 'Orgid'
    }),
    iswholedollarroundingenabled: checkbox({
      label: 'Iswholedollarroundingenabled'
    }),
    createddate: widget({
      label: 'Createddate'
    }),
    active: checkbox({
      label: 'Active'
    }),
    monthlydonationmax: widget({
      label: 'Monthlydonationmax'
    }),
    email: widget({
      label: 'Email'
    }),
    isactive: checkbox({
      label: 'Isactive'
    }),
    _id: widget({
      label: ' Id'
    }),
    isclosed: checkbox({
      label: 'Isclosed'
    }),
    otherbank: widget({
      label: 'Otherbank'
    }),
    ispoliticalattestationaccepted: checkbox({
      label: 'Ispoliticalattestationaccepted'
    }),
    employer: widget({
      label: 'Employer'
    }),
    occupation: widget({
      label: 'Occupation'
    }),
    isdeleted: checkbox({
      label: 'Isdeleted'
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    internal_notes: widget({
      label: 'Internal Notes'
    }),
    status_notes: widget({
      label: 'Status Notes'
    }),
    account_id: widget({
      label: 'Account Id'
    }),
    remote_account_id: widget({
      label: 'Remote Account Id'
    }),
    supported_id: widget({
      label: 'Supported Id'
    }),
    remote_profile_id: widget({
      label: 'Remote Profile Id'
    }),
    remote_payment_id: widget({
      label: 'Remote Payment Id'
    }),
    remote_access_token: widget({
      label: 'Remote Access Token'
    }),
    remote_identity_token: widget({
      label: 'Remote Identity Token'
    }),
    is_legacy: checkbox({
      label: 'Is Legacy'
    }),
    legacy_id: widget({
      label: 'Legacy Id'
    }),
    max_donation: widget({
      label: 'Max Donation'
    }),
    dollar_roundup: checkbox({
      label: 'Dollar Roundup'
    }),
    remote_item_id: widget({
      label: 'Remote Item Id'
    }),
    recurring_donation: checkbox({
      label: 'Recurring Donation'
    }),
    recurring_donation_type: widget({
      label: 'Recurring Donation Type'
    }),
    recurring_custom_amount: widget({
      label: 'Recurring Custom Amount'
    }),
    emailaddress: widget({
      label: 'Emailaddress'
    }),
    laststname: widget({
      label: 'Laststname'
    }),
    plaid_last_update: widget({
      label: 'Plaid Last Update'
    }),
    status_date: widget({
      label: 'Status Date'
    }),
    recurring_donation_start_date: widget({
      label: 'Recurring Donation Start Date'
    }),
    recurring_donation_end_date: widget({
      label: 'Recurring Donation End Date'
    }),
    test_account: checkbox({
      label: 'Test Account'
    }),
    absolutely_closed: checkbox({
      label: 'Absolutely Closed'
    }),
    mark_open: widget({
      label: 'Mark Open'
    }),
    jill_stein: checkbox({
      label: 'Jill Stein'
    }),
    legacy_closed: checkbox({
      label: 'Legacy Closed'
    }),
    what_to_bill: widget({
      label: 'What To Bill'
    }),
    plaid_error: widget({
      label: 'Plaid Error'
    }),
    plaid_traceback: widget({
      label: 'Plaid Traceback'
    }),
    plaid_error_date: widget({
      label: 'Plaid Error Date'
    }),
    plaid_date_acquired: widget({
      label: 'Plaid Date Acquired'
    }),
    plaid_institution_name: widget({
      label: 'Plaid Institution Name'
    }),
    plaid_local_institution_id: widget({
      label: 'Plaid Local Institution Id'
    }),
    plaid_remote_institution_id: widget({
      label: 'Plaid Remote Institution Id'
    }),
    payment_account_expired: checkbox({
      label: 'Payment Account Expired'
    }),
    last_plaid_num_transactions: widget({
      label: 'Last Plaid Num Transactions'
    }),
    plaid_account_verfied: checkbox({
      label: 'Plaid Account Verfied'
    })
  }
})



export default data
