import * as React from 'react'
import useGlobal from 'store'
import {
  Tooltip,
  Button,
  Grid
} from '@mui/material'
import MenuButton from '../MenuButton'
import VCRButtons from '../VCRButtons'
import SystemSearch from '../SystemSearch'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { saveMenu } = globalState.controls

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }
  return (
    <>
      <Grid
        container
        justifyContent='space-between'
      >
        <Grid item>
          <Grid container>
            <Grid item>
              <MenuButton
                formName={formName}
                label={saveMenu[0].label}
                name={saveMenu[0].name}
                tooltip={saveMenu[0].tooltip}
                color='primary'
                size='small'
                menuItems={saveMenu}
                showBrackets
              />
            </Grid>
            <Grid item>
              <Tooltip title='[Ctrl-I] Create a new object' enterDelay={1500} leaveDelay={200}>
                <Button
                  onClick={onEvent}
                  color='secondary'
                  name='@new'
                  size='small'
                >
                  New
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title='[Escape] Clear the currently loaded object' enterDelay={1500} leaveDelay={200}>
                <Button
                  onClick={onEvent}
                  color='secondary'
                  name='@clear'
                  size='small'
                >
                  Clear
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title='[Ctrl-D] Delete the currently loaded object' enterDelay={1500} leaveDelay={200}>
                <Button
                  onClick={onEvent}
                  color='secondary'
                  name='@delete'
                  size='small'
                >
                  Delete
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title='[Ctrl-R] Discard changes and reload' enterDelay={1500} leaveDelay={200}>
                <Button
                  onClick={onEvent}
                  color='secondary'
                  name='@load'
                  size='small'
                >
                  Reload
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title='Validate this form' enterDelay={1500} leaveDelay={200}>
                <Button
                  onClick={onEvent}
                  color='secondary'
                  name='@validate'
                  size='small'
                >
                  Validate
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SystemSearch
            formName={formName}
          />
        </Grid>
        <Grid item>
          <VCRButtons
            formName={formName}
          />
        </Grid>
      </Grid>
    </>
  )
}
