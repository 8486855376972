import * as React from 'react'
import useGlobal from 'store'
import InfoIcon from '@mui/icons-material/Info'
import context from 'lib/context'
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
} from '@mui/material'
import LoadClientImage from './LoadClient.png'
import NoResults from './NoResults.png'


export default function ImageListComponent({ selectedImage, setSelectedImage }) {
    const [globalState, globalActions] = useGlobal() // eslint-disable-line
    const { image_library: datatable } = globalState.repo

    return (<>
        <ImageList
            sx={{ width: 600, height: 450 }}
            variant="quilted"
            cols={5}
            rowHeight={180}
        >
            {context.isDefined(datatable) && context.isNotEmpty(datatable.rows) && datatable.rows.map((item) => {
                const url = 'https://s3.amazonaws.com/' + item.bucket + '/' + item.thumbnail_key
                const ratio = item.width / item.height
                let cols = 1, rows = 1
                if (ratio < .66) {
                    rows = 2
                } else if (ratio > 9) {
                    cols = 2
                } else if (ratio > 5) {
                    cols = 3
                } else if (ratio > 1.49) {
                    cols = 2
                } else if (ratio > 1.25) {
                    if (item.width > 2000) {
                        cols = 3
                    } else if (item.width > 1600) {
                        cols = 4
                    } else if (item.width > 800) {
                        cols = 3
                    } else {
                        cols = 2
                    }
                }

                return (
                    <ImageListItem
                        key={item.sys_id}
                        rows={rows}
                        cols={cols}
                        sx={{
                            border: item.key === selectedImage.key ? '2px solid red' : '2px solid transparent',
                        }}
                    >
                        <img
                            src={`${url}`}
                            alt={item.name}
                            loading="lazy"
                            onClick={() => {
                                setSelectedImage(item)
                            }}

                        />
                        <ImageListItemBar
                            subtitle={`${item.client}   (${item.width} w x ${item.height} h) ${ratio.toFixed(2)}`}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    title={`${item.name}    ${item.client}   (${item.width} w x ${item.height} h) ${ratio.toFixed(2)}`}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                            sx={{
                                border: item.key === selectedImage.key ? '2px solid yellow' : '2px solid transparent',
                            }}
                        />
                    </ImageListItem>
                )
            })}
            {context.isDefined(datatable) && context.isEmpty(datatable.rows) && <>
                <ImageListItem
                    rows={2}
                    cols={5}
                >
                    <img
                        style={{ height: '100%' }}
                        src={NoResults}
                        alt="No Results Found"
                    />
                </ImageListItem>
            </>}
            {context.isUndefined(datatable) && <>
                <ImageListItem
                    rows={2}
                    cols={5}
                >
                    <img
                        style={{ height: '100%' }}
                        src={LoadClientImage}
                        alt="Load Client Images"
                    />
                </ImageListItem>
            </>}
        </ImageList >

    </>
    )
}
