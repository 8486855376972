import * as React from 'react'
import useGlobal from 'store'
import {
  Loupe,
} from '@mui/icons-material'

import * as defaults from './defaults'

const Component = ({ formName }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { inspectorOptions } = globalState.controls
  return (
    <>
      <Loupe
        title="Inspector View"
        sx={defaults.ButtonStyle}
        onClick={() => {
          globalActions.control.mergeState({
            controls: {
              inspectorOptions: {
                open: !inspectorOptions.open
              }
            }
          })
        }}
      />
    </>
  )
}

export default Component
