import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useGlobal from 'store'
import context from 'lib/context'

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import * as Icons from '@mui/icons-material'
import { BuildInfo, Logo } from 'components'

const categories = [
  {
    id: 'Site Administration',
    children: [
      { id: 'Admin Dashboard', icon: <Icons.Home />, link: '/app/Dashboard' },
      { id: 'Admin Accounts', icon: <Icons.AccountBox />, link: '/app/AdminAccounts' },
    ],
  },
  {
    id: 'Billing Management',
    children: [
      { id: 'Billing Dashboard', icon: <Icons.Dashboard />, link: '/app/BillingDashboard' },
      { id: 'Rally Billing', icon: <Icons.SportsFootball />, link: '/app/RallyGiveBilling' },
      { id: 'Swipegive Billing', icon: <Icons.CurrencyExchangeRounded />, link: '/app/SwipeGiveBilling' },
      { id: 'Fees & Formulas', icon: <Icons.TrendingUp />, link: '/app/BillingFormula' },
      { id: 'Name Translations', icon: <Icons.SwapHoriz />, link: '/app/BillingTranslation' },
      { id: 'One-Time Fee', icon: <Icons.AttachMoney />, link: '/app/BillingFee' },
      { id: 'Billing Reports', icon: <Icons.DateRange />, link: '/app/BillingPeriod' },
      { id: 'Braintree Disputes', icon: <Icons.ReportRounded />, link: '/app/Disputes' },
      { id: 'Braintree Disbursements', icon: <Icons.AccountBalance />, link: '/app/Disbursements' },
      { id: 'Receipts', icon: <Icons.Receipt />, link: '/app/Receipts' },
    ],
  },
  {
    id: 'Fraud Detection',
    children: [
      { id: 'Blacklist', icon: <Icons.TrendingUpRounded />, link: '/app/Blacklist' },
      { id: 'Fraud Detection', icon: <Icons.MoneyRounded />, link: '/app/FraudDetection' },
    ],
  },
  {
    id: 'Transaction Management',
    children: [
      { id: 'Transactions', icon: <Icons.TrendingUpRounded />, link: '/app/Transactions' },
      { id: 'Month Transactions', icon: <Icons.MoneyRounded />, link: '/app/BillingObject' },
      { id: 'Donation Lineitems', icon: <Icons.MapRounded />, link: '/app/donation_lineitems' },
      { id: 'Roundups', icon: <Icons.Map />, link: '/app/Roundups' },
    ],
  },
  {
    id: 'Donor Management',
    children: [
      { id: 'Donor Dashboard', icon: <Icons.Dashboard />, link: '/app/DonorDashboard' },
      { id: 'Donor Accounts', icon: <Icons.AccountBox />, link: '/app/DonorAccounts' },
      { id: 'Donors', icon: <Icons.People />, link: '/app/Donors' },
      // { id: 'Map', icon: <Icons.MapRounded />, link: '/app/DonorMap' },
      { id: 'SwipeGiveSettings', icon: <Icons.Settings />, link: '/app/swipegive_settings' },
      { id: 'RallyGiveSettings', icon: <Icons.Settings />, link: '/app/rallygive_settings' },
      { id: 'Rally Metrics', icon: <Icons.SportsHandballRounded />, link: '/app/RallyMetrics' },
      { id: 'Payment Profiles', icon: <Icons.SportsHandballRounded />, link: '/app/paymentprofiles' },
      { id: 'Plaid Accounts', icon: <Icons.SportsHandballRounded />, link: '/app/plaidaccounts' },
    ],
  },
  {
    id: 'Client Management',
    children: [
      { id: 'Client Dashboard', icon: <Icons.Dashboard />, link: '/app/ClientDashboard' },
      { id: 'Client Accounts', icon: <Icons.AccountBox />, link: '/app/ClientAccounts' },
      { id: 'Clients', icon: <Icons.SportsBaseballRounded />, link: '/app/clients' },
      // { id: 'Campaigns', icon: <Icons.AssignmentRounded />, link: '/app/Client_Campaigns' },
      { id: 'Form Builder', icon: <Icons.BuildRounded />, link: '/app/FormBuilder' },
      { id: 'Settings', icon: <Icons.Settings />, link: '/app/Client_Settings' },
      { id: 'In/Out Market Zip Codes', icon: <Icons.Map />, link: '/app/Client_ZipCodes' },
      { id: 'Existing Donors', icon: <Icons.Map />, link: '/app/Client_Existing' },
      { id: 'Upload Existing', icon: <Icons.CloudUpload />, link: '/app/Upload-Existing-Donors' },
      { id: 'Upload Zip Codes', icon: <Icons.CloudUpload />, link: '/app/Upload-Zip-Codes' },
    ],
  },
  {
    id: 'Mail Management',
    children: [
      { id: 'Mail Dashboard', icon: <Icons.Dashboard />, link: '/app/MailDashboard' },
      { id: 'Email Templates', icon: <Icons.Map />, link: '/app/EmailBuilder' },
      { id: 'Email Instances', icon: <Icons.Map />, link: '/app/EmailInstance' },
      { id: 'Unsubscribe List', icon: <Icons.AccountBox />, link: '/app/MasterEmailList' },
      { id: 'Email Lists', icon: <Icons.AccountBox />, link: '/app/MailLists' },
      { id: 'Email Accounts', icon: <Icons.AccountBox />, link: '/app/MailAccounts' },
      { id: 'SMTP Settings', icon: <Icons.AccountBox />, link: '/app/SMTPSettings' },
      { id: 'Receipts', icon: <Icons.Receipt />, link: '/app/Receipts' },
    ],
  },
  {
    id: 'Images & Docs',
    children: [
      { id: 'Images', icon: <Icons.Filter />, link: '/app/images' },
      { id: 'Documents', icon: <Icons.PermMedia />, link: '/app/documents' },
    ],
  },
  {
    id: 'Documentation',
    children: [
      { id: 'Home', icon: <Icons.Home />, link: '/documentation/home' },
      { id: 'Developers', icon: <Icons.StorageRounded />, link: '/documentation/developers' },
      { id: 'Administrators', icon: <Icons.ColorLens />, link: '/documentation/administrators' },
      { id: 'Clients', icon: <Icons.ColorLens />, link: '/documentation/clients' },
      { id: 'Donors', icon: <Icons.ColorLens />, link: '/documentation/donors' },
    ],
  },
  {
    id: 'Programming',
    children: [
      { id: 'Data Store', icon: <Icons.Inventory />, link: '/prog/DataStore' },
      { id: 'System Log View', icon: <Icons.RssFeed />, link: '/prog/systemlog' },
      { id: 'Async Actions', icon: <Icons.PendingActions />, link: '/prog/Async' },
      { id: 'Feature Flags', icon: <Icons.Flag />, link: '/prog/FeatureFlags' },
      { id: 'Program Objects', icon: <Icons.Terminal />, link: '/prog/ProgramObjects' },
    ],
  },
  {
    id: 'Beta Labs',
    children: [
      { id: 'User Data', icon: <Icons.SettingsAccessibility />, link: '/prog/UserData' },
      //{ id: 'My User Data', icon: <Icons.Dashboard />, link: '/prog/MyUserData' },
      { id: 'Lambda Activity', icon: <Icons.ChangeHistory />, link: '/prog/Lambda' },
      { id: 'DataBase', icon: <Icons.Storage />, link: '/prog/DataBase' },
    ],
  },
]


const header = {
  py: 1.5,
  px: 3,
  color: 'white',
}

const item = {
  py: .1,
  px: 4,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}


function NavListItem({ data, level = -1 }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  if (level === -1) {
    const { id, children } = data
    return (
      <>
        <Box>
          <ListItem sx={{ ...header }}>
            <ListItemText>{id}</ListItemText>
          </ListItem>
          {children && children.map(data => (<NavListItem key={data.id} data={data} level={level + 1} />))}
          <Divider />
        </Box>
      </>)
  } else {
    const { id: childId, icon, active, link, children } = data
    const sx = { ml: level * 2 }
    return (
      <>
        <RouterLink
          style={{ textDecoration: 'none' }}
          to={link}
          onClick={globalActions.control.closeNavigator}
        >
          <ListItem disablePadding sx={sx}>
            <ListItemButton selected={active} sx={item}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText sx={{ ml: 1 }}>{childId}</ListItemText>
            </ListItemButton>
          </ListItem>
        </RouterLink>
        {children && children.map(data => (
          <NavListItem key={data.id} data={data} level={level + 1} />
        ))}
      </>
    )
  }
}


export default function Navigator({ ...other }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const {
    vars = {}
  } = globalState.controls

  return (<>
    <Drawer
      variant='permanent'
      {...other}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: context.gradientVert,
        }
      }}
    >
      <List disablePadding>
        <ListItem>
          {vars.AWS_BRANCH === 'demo' ? <>
            <Box sx={{
              color: 'red',
              fontSize: 'large',
              position: 'relative',
              fontWeight: 'bold',
              top: 15,
              left: 25
            }}>
              DEMO SYSTEM
            </Box>
          </> :
            <Logo style={{ maxHeight: 35 }} />}
        </ListItem>
        {categories.map(data => (
          <React.Fragment key={data.id}>
            <NavListItem data={data} />
          </React.Fragment>)
        )}
      </List>
      <BuildInfo />
    </Drawer>
  </>
  )
}
