import * as React from 'react'
import useGlobal from 'store'
import {
  Tooltip,
  Typography,
  Box
} from '@mui/material'
import {
  NotInterested,
  CheckCircleOutline,
  RadioButtonUnchecked
} from '@mui/icons-material'

function Component({ formName, ...props }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const form = globalState.forms[formName]
  const { fields } = form
  return (
    <>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        {fields.sys_id.widget.value ? form.dirty ? <>
          <Tooltip title='Form state is dirty (has changes)'>
            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row'
            }}>
              <NotInterested /><Typography variant='caption' sx={{ color: 'gray' }}>form changed</Typography>
            </Box>
          </Tooltip>
        </>
          : <>
            <Tooltip title='Form state is clean (no changes)' aria-label='add'>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
              }}>
                <CheckCircleOutline /><Typography variant='caption' sx={{ color: 'gray' }}>form unchanged</Typography>
              </Box>
            </Tooltip>
          </>
          : <>
            <Tooltip title='Form is not loaded' aria-label='add'>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
              }}>
                <RadioButtonUnchecked /><Typography variant='caption' sx={{ color: 'gray' }}>form unloaded</Typography>
              </Box>
            </Tooltip>
          </>}
      </div>
    </>
  )
}

export default Component
