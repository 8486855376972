import context from 'lib/context'
import system from '../system'
import {
  widget,
  money
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const table = 'braintree_disputes'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    src: widget({
      label: 'Src'
    }),
    src_id: widget({
      label: 'Src Id'
    }),
    src_reference_id: widget({
      label: 'Src Reference Id'
    }, {
      display: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link to={'/app/transactions/' + value} style={{ textDecoration: 'none' }}>
            <Typography noWrap align='center' >
              {value}
            </Typography>
          </Link>
        )
      }
    }),
    amount_disputed: money({
      label: 'Amount Disputed'
    }, {
      display: true
    }),
    amount_won: money({
      label: 'Amount Won'
    }, {
      display: true
    }),
    reason: widget({
      label: 'Reason'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    date_opened: widget({
      label: 'Date Opened'
    }, {
      display: true
    }),
    processor_reply_by_date: widget({
      label: 'Processor Reply By Date'
    }, {
      display: true
    }),
    reply_by_date: widget({
      label: 'Reply By Date'
    }, {
      display: true
    }),
    date_won: widget({
      label: 'Date Won'
    }, {
      display: true
    })
  }
})



export default data
