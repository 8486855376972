import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useGlobal from 'store'
import {
  BuildRounded,
} from '@mui/icons-material'
import {
  Button,
} from '@mui/material'

import * as defaults from './defaults'

const Component = ({ formName }) => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  return (
    <>
      <Button
        style={{ textDecoration: 'none' }}
        component={RouterLink}
        to='/app/FormBuilder'
        onClick={globalActions.control.closeNavigator}
        title="Form Builder"
      >
        <BuildRounded
          title="Form Builder"
          sx={defaults.ButtonStyle}
        />
        Form Builder
      </Button>
    </>
  )
}

export default Component
