import * as React from 'react'
import {
  Container,
  Grid
} from '@mui/material'
import Header from 'components/Header'
import ToolBar from 'components/ToolBar'
import SwipeGive from './SwipeGive'
import LatestDonations from './LatestDonations'
import RecentCampaigns from './RecentCampaigns'
import DailySales from './DailySales'
import DisputedDonations from './DisputedDonations'
import TotalDonations from './TotalDonations'
import RefundedDonations from './RefundedDonations'

import useGlobal from 'store'
import 'chart.js/auto'


const Dashboard = () => {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  React.useEffect(() => {
    globalActions.control.setUserData({
      'controls.menu.options.selected': {
        controls: {
          menu: {
            options: {
              selected: 'Dashboard'
            }
          }
        }
      }
    })

    globalActions.control.mergeState({
      controls: {
        PageTitle: 'Admin Dashboard',
        menu: {
          options: {
            selected: 'Dashboard'
          }
        }
      }
    }, true)
  }, [globalActions.control])

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-initial-data',
      spinner: {
        content: 'Reading Data. Please Wait...'
      }
    })
  }, [globalActions.api])

  return (<>
    <Header label='Admin Dashboard' title='Dashboard' />
    <ToolBar
      inspector
      formbuilder
    />
    <Container maxWidth={false} sx={{ pt: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <SwipeGive />
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalDonations />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <RefundedDonations />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <DisputedDonations />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <DailySales />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <RecentCampaigns />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <LatestDonations />
        </Grid>
      </Grid>
    </Container>
  </>
  )
}

export default Dashboard
