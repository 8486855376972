import * as React from 'react'
import TermsOfService from './TermsOfService'
import useGlobal from 'store'

import {
  Box,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material'

export default function ScrollDialog() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { termsOfService } = globalState.notifications

  const [scroll, setScroll] = React.useState('paper')

  const handleClickOpen = (scrollType) => () => {
    globalActions.control.mergeState({
      notifications: {
        termsOfService: true
      }
    })
    setScroll(scrollType)
  }

  const handleClose = () => {
    globalActions.control.mergeState({
      notifications: {
        termsOfService: false
      }
    })
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (termsOfService) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [termsOfService])

  return (
    <>
      <div onClick={handleClickOpen('paper')} variant="link">Terms Of Service</div>
      <Dialog
        open={termsOfService}
        onClose={handleClose}
        scroll={scroll}
      >
        <DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box sx={{
              fontSize: '80%'
            }}>
              <TermsOfService />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}