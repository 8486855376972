import * as React from 'react'
import PrivacyPolicy from './PrivacyPolicy'
import useGlobal from 'store'

import {
  Box,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material'

export default function ScrollDialog() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { privacyPolicy } = globalState.notifications
  const [scroll, setScroll] = React.useState('paper')

  const handleClickOpen = (scrollType) => () => {
    globalActions.control.mergeState({
      notifications: {
        privacyPolicy: true
      }
    })
    setScroll(scrollType)
  }

  const handleClose = () => {
    globalActions.control.mergeState({
      notifications: {
        privacyPolicy: false
      }
    })
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (privacyPolicy) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [privacyPolicy])

  return (
    <>
      <div onClick={handleClickOpen('paper')} variant="link">Privacy Policy</div>
      <Dialog
        open={privacyPolicy}
        onClose={handleClose}
        scroll={scroll}
      >
        <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box sx={{
              fontSize: '80%'
            }}>
              <PrivacyPolicy />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}