import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'user_data'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    val: widget({
      label: 'Val'
    }, {
      display: true
    }),
    userid: widget({
      label: 'Userid'
    }, {
      display: true
    }),
    key: widget({
      label: 'Key'
    }, {
      display: true
    })
  }
})



export default data
