// This is a special file that defines system fields.
// It is included in all metadata definition files.
import * as widgets from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'

export const data = {
  cards,
  functions,
  muidatatable: {},
  datagrid: {},
  sysIdx: 1,
  selected_fields: [
    { units: 2, column: 'sys_id', display: data => <b>{data}</b> },
    { units: 2, column: 'sys_created', display: data => <i>{data}</i> },
    { units: 2, column: 'sys_modified', display: data => <i>{data}</i> },
    { units: 6, column: 'description', display: data => <b>{data}</b> },
  ],
  fields: {
    row_number: widgets.widget({
      label: '#'
    }, {
      anonymous: true,
      display: true,
    }),
    sys_id: widgets.widget({
      label: 'ID'
    }, {
      display: true,
    }),
    sys_created: widgets.datetime({
      label: 'Created',
      showcount: false,
      InputProps: {
        readOnly: true
      }
    }),
    sys_modified: widgets.datetime({
      label: 'Modified',
      showcount: false,
      InputProps: {
        readOnly: true
      }
    }),
    sys_modified_by: widgets.widget({
      label: 'Modified By',
      showcount: false,
      InputProps: {
        readOnly: true
      }
    }),
    description: widgets.widget({
      label: 'Description',
    }),
  }
}

export default data
