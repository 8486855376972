import * as React from 'react'
// import IconButton from '@material-ui/core/IconButton'
// import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@mui/icons-material/Refresh'
import useGlobal from 'store'
import {
  Tooltip,
  IconButton
} from '@mui/material'

const CustomToolbar = ({ formName }) => {
  const [globalState, globalActions] = useGlobal() // eslint-disable-line
  const handleRefresh = () => {
    globalActions.control.reloadTable(formName)
  }
  return (
    <>
      <Tooltip title='Refresh table data from server'>
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default CustomToolbar
