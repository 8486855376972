import * as React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Fade,
  CircularProgress,
  Backdrop,
  Modal,
  Typography
} from '@mui/material'
import useGlobal from 'store'

export default function Spinner() {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line
  const { apiSpinner } = globalState.controls
  const request = apiSpinner.request[0]
  return (
    <Modal
      open={apiSpinner.state}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={apiSpinner.state}>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                m: 5
              }}>
                <Typography variant="caption" component="div" color="text.secondary">
                  <CircularProgress disableShrink />
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="caption" component="div" color="text.secondary">
                  {apiSpinner.title[0]}
                </Typography>
              </Box >
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {request && typeof request._cancel === 'function' && <Button
                variant='outlined'
                onClick={request._cancel}
              > Cancel
              </Button>}
            </CardActions>
          </Card>
        </Box>
      </Fade >
    </Modal >
  )
}
