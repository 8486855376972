import { Auth } from 'aws-amplify'
import FileDownload from 'js-file-download'

import context from 'lib/context'
import * as meta from 'store/meta-data'
import * as validate from 'store/validation'
import PubSub from 'pubsub-js'

const subscriptions = []

/**
 *  A helper function to initialize the pubsub system.  This function subscribes
 * to all topics that are used by the application.  This function is called
 * at a very high level in the application during page initialization.
 * @param {*} store - The store object as provided by use-global-hook.  
 */
export const subscribe = (store) => {
  subscriptions.push(PubSub.subscribe('formEvent', function (topic, message) {
    const { formName, event } = message
    onFormEvent(store, formName, event)
  }))
  subscriptions.push(PubSub.subscribe('setForm', function (topic, message) {
    const { action } = message
    setForm(store, action)
  }))
  subscriptions.push(PubSub.subscribe('callAfter', function (topic, message) {
    const { func, params } = message
    func(...params)
  }))
  subscriptions.push(PubSub.subscribe('dispatch', function (topic, message) {
    actionHandler(store, message)
  }))
}

/**
 * A helper function to unsubscribe from all pubsub topics.
 * @param {*} store - The store object as provided by use-global-hook.  This 
 * is provided by default and is not used in this function.
 */
export const unsubscribe = (store) => {
  subscriptions.forEach((item) => PubSub.unsubscribe(item))
}

/**
 * Exposes the ability to publish messages tot he pubsub system from
 * anywhere in the application using globalActions.control.publish.
 * @param {*} store - The store object as provided by use-global-hook.  This 
 * is provided by default and is not used in this function.
 * @param {string} topic - The topic to publish on,  e.g. 'formEvent'
 * @param {*} message - The message to publish.  This can be any type of object.
 */
export const publish = (store, topic, message) => {
  console.log('control.publish() called', topic, message)
  PubSub.publish(topic, message)
}

/**
 * Put the AWS user details into the store so that they can be used by the
 * application. If the user is not logged in, then the application will revert 
 * to the sign in page.  
 * @param {*} store - The store object as provided by use-global-hook. 
 */
export const setAuthState = async (store) => {
  const user = Auth.user
  if (user) {
    mergeState(store, {
      controls: {
        user: user
      }
    })
  } else {
    window.location.reload()
  }
}

/**
 * Persist key:val pairs as user data to database through API. This persistent data can later be 
 * retrieved to set UI customizations.
 * @param store
 * @param userData
 */
export const setUserData = (store, userData) => {
  call(store, {
    apiName: 'Events',
    apiPath: '/data',
    apiAction: 'store-user-data',
    apiPayload: {
      userData
    }
  })
}

/**
 * Publish an asynchronous event to execute after the current execution context.
 * call is a shorthand for using callAfter to execute an API call.
 * @param store - the store object as provided by use-
 * @param params - { func, params } -func parameter is pointer to the function and is 
 * invoked with spread operator on params as  func(...params)
 * params is an object {} that contains the parametres to pass to performAPI
 * (see performAPI in api.js for details)
 */
export const call = (store, params) => {
  publish(store, 'callAfter', {
    func: store.actions.api.performApi,
    params: [params]
  })
}

/**
 * Changes the state of a form in the store to blank  and default values.
 * @param formName - formName is the name of the form to reset. formName is the key 
 * in the store.forms object.
 * @param original - if this parameter contains an object with values, then 
 * the form is set using the vaules provided.
 */
export const resetForm = (formName, original = {}) => {
  if (!(formName in meta)) {
    throw new Error('Form `' + formName + '` does not have a definition entry in meta-data')
  }
  let form = {
    formName,
    dirty: false
  }
  context.recursiveAssign(form, meta[formName].data || {})
  const fields = form.fields
  for (const key in fields) {
    const value = fields[key]
    value.widget = {
      // I put them in this order on purpose. We want the ability to initialize
      // the widget to a non-default value, set to default otherwise.
      id: key,
      name: key,
      title: key,
      ...value.defaults,
      ...value.widget
    }
  }
  const select = []
  const headers = []
  const visiblityModel = {}
  const columns = []
  for (const key in fields) {
    if ('dt' in fields[key]) {
      // If 'dt' exists in the data definition, then it is a data table field.
      // If it is a displayed field, then add it to the headers. If it is a
      // fetched field, then add it to the select. A displayed field is
      // automaticall a fetched field, but not the other way around. If it is
      // fetched but not displayed, then we need to add it to the select, and
      // add to the headers as a hidden field.
      if (fields[key].dt.options.display || fields[key].dt.options.fetch) {
        if (fields[key].dt.options.display) {
          visiblityModel[key] = true
        }
        headers.push({
          name: fields[key].widget.name,
          label: fields[key].widget.label,
          ...fields[key].dt
        })
        columns.push({
          field: fields[key].widget.column || fields[key].widget.id,
          headerName: fields[key].widget.label || fields[key].widget.column || fields[key].widget.id,
        })
      }
      if (!fields[key].dt.options.anonymous) {
        // Always add any 'dt' field to select if not anonymous, even if it is
        // not displayed in any table or form. This allows us to cache the data
        // in the client-side repository (CSR) and load forms and grids faster
        // without requiring a new API call. There may be a use case for
        // fetching data from the server that does not have a 'dt' definition.
        // If we want to do that later, Then move the next line of code AFTER
        // the next  '}' and change this comment.
        select.push(fields[key].widget.column || fields[key].widget.id)
      }
    }
  }
  form.query.params.columns = select
  form.muidatatable.tableHeaders = headers
  form.datagrid = {
    columns,
    props: {
      visiblityModel
    },
  }

  if (context.isDefined(original.queryParams)) {
    form.queryParams = context.recursiveAssign(form.query, original.queryParams)
  } else {
    form.queryParams = context.copy(form.query)
  }
  return form
}

/**
 * Initial load of form if it does not exist. Calls reset form. Inserts the form into 
 * the store using the formName ias the key. A form is a collection of inputs and is 
 * generally persisted as a single entry in the database. A row for the database is 
 * stored as "original" for comparison of changed values. A shadow copy of the object 
 * is updated.
 * @param store
 * @param formName
 */
export const initForm = (store, formName, current = true) => {

  if (!(formName in store.state.forms)) {
    context.log(`Creating new entry '${formName}' in forms table.`)

    const form = resetForm(formName)
    const newState = {
      forms: {
        [formName]: form
      }
    }
    if (current) {
      newState.forms.current = form
    }
    mergeState(store, newState)
  } else {
    context.log(`'${formName}' already exists. No action taken.`)
  }
}


/**
 * Has something to do with toast and notifications
 * @param store
 * @param id
 */
export const storeDisplayed = (store, id) => {
  let { toast, displayed } = store.state.notifications
  displayed = [...displayed, id]
  store.setState({
    notifications: {
      toast,
      displayed
    }
  })
}

/**
 * Has something to do with toast and notifications
 * @param store
 * @param id
 */
export const removeDisplayed = (store, id) => {
  let { toast, displayed } = store.state.notifications
  displayed = [...displayed.filter(key => id !== key)]
  delete toast[id]
  store.setState({
    notifications: {
      toast,
      displayed
    }
  })
}

/**
 * Process a form event. This function extracts prepares the data from the event and standardises it into an action object,
 * and evenutally calls setForm to complete the work.
 * @param store
 * @param formName
 * @param event
 * @param asynchronous If asynchronous is true then publish the event through pubsub to execute immediately after calling context.
 */
export const onFormEvent = (store, formName, event, asynchronous = true) => {
  let target = event.target
  while (target) {
    if (target.hasAttribute && target.hasAttribute('name') && target.hasAttribute('value')) { break }
    if (target.hasAttribute && target.hasAttribute('type')) { break }
    if (target.type) { break }
    if (target.tagName === 'TEXTAREA') { break }
    target = target.parentNode
  }

  if (!target) {
    context.log('In control.onFormatEvent: Unable to handle widget type from event', event)
    return
  }

  let { name, value, checked, type, tagName } = target
  if (tagName === 'TEXTAREA') {
    type = 'textarea'
  }

  if (type === 'checkbox') {
    value = checked
  }
  if (asynchronous) {
    publish(store, 'setForm', {
      action: {
        formName,
        type,
        name,
        value
      }
    })
  } else {
    setForm(store, {
      formName,
      type,
      name,
      value
    })
  }
}

/**
 * Process a form event. Generate a new state by passing the action through setFormReducer.
 * Merge the resultant state into the datastore.
 * @param store
 * @param action
 */
export const setForm = async (store, action) => {
  const newState = await setFormReducer(store, action)
  if (context.isObject(newState)) {
    store.setState(newState)
  }
}

/**
 * Process a form event. This is where the event actually gets handled.
 * Inputs, buttons and other form elements get handled here.
 * @param store
 * @param action
 */
const setFormReducer = async (store, action) => {
  const { forms } = store.state
  let form = forms[action.formName]
  const { fields, shadow, original } = form
  const widget = fields[action.name] && fields[action.name].widget
  const defaults = fields[action.name] && fields[action.name].defaults
  const table = store.state.repo[action.formName]
  if (action.formName === 'current') {
    action.formName = form.formName
  }
  switch (action.type) {
    case 'setError':
      widget.error = true
      widget.helperText = action.value
      break
    case 'clearError':
      widget.error = false
      widget.helperText = defaults.helperText
      break
    case 'button':
      switch (action.name) {
        case '@skip-next':
          if (table?.rows) {
            let position = table?.config?.current_index || 0
            //eslint-disable-next-line
            position = ++position % table.rows.length
          }
          break
        case '@clear':
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to clear this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }
          form = resetForm(action.formName, form)
          break
        case '@new':
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to clear this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }
          form.fields.sys_id.widget.value = ''
          form = resetForm(action.formName, form)
          form.fields.sys_id.widget.value = '@new'
          form.fields.sys_id.widget.variant = 'filled'
          form.fields.sys_id.widget.InputProps = {
            readOnly: true
          }
          form.original = {}
          form.shadow = {}
          form.loaded = true
          break
        case '@delete':
          if (fields.sys_id.widget.value) {
            try {
              await context.okCancelPopup(
                <>
                  This action will delete this object. Deleting is permenant
                  and there is no backup. Are you sure you want to delete this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
            const object = {}
            for (const key in fields) {
              object[key] = fields[key].widget.value
            }
            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'delete-object',
              apiPayload: {
                tableName: form.table,
                object
              },
              callback: (store, response) => {
                if ('afterDelete' in form.functions) {
                  publish(store, 'callAfter', { func: form.functions.afterDelete, params: [store, form, response] })
                }
                publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
              },
              spinner: {
                content: 'Deleting Data. Please Wait...'
              }
            })
          }
          break
        case '@save-as':
          let newId = null
          try {
            newId = await context.getResultPopup(
              <>
                Enter an ID to save this object as... <br />
                (Leave blank for a new object with a new ID)
              </>,
              'SaveAs...'
            )
          } catch {
            Notify(store, 'Save as canceled')
            return
          }
        // eslint-disable-next-line
        case '@save':
        case '@save-clear':
        case '@save-keep':
        case '@save-new':
          handleSaveMenu(store, action.name)
          if (fields.sys_id.widget.value) {
            const diff = {}
            const object = {}
            for (const key in fields) {
              if (fields[key].widget.displayonly === 'true') {
                continue
              }
              object[key] = fields[key].widget.value
              if (key.search('sys_') !== -1) continue
              if (key.search('row_number') !== -1) continue
              if (fields[key].widget.value !== original[key]) {
                diff[key] = fields[key].widget.value
              }
            }
            if (!Object.keys(diff).length && action.name !== '@save-new' && action.name !== '@save-as') {
              Notify(store, 'Nothing Changed. Object save quashed.')
              if (action.name === '@save-clear') {
                publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
              }
              return
            }
            diff.sys_id = original.sys_id
            if (action.name === '@save-new' || object.sys_id === '@new') {
              object.sys_id = '@new'
              diff.sys_id = '@new'
            } else if (action.name === '@save-as') {
              if (newId) {
                object.sys_id = newId
                diff.sys_id = newId
              } else {
                object.sys_id = '@new'
                diff.sys_id = '@new'
              }
            }

            if (typeof form.beforeSave === 'function') {
              form.beforeSave(store, form, object, diff)
            }

            const apiPayload = {
              tableName: form.table,
              object: (action.name === '@save-new' || action.name === '@save-as') ? context.copy(object) : context.copy(diff)
            }

            delete apiPayload.object.row_number

            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'write-object',
              apiPayload: apiPayload,
              spinner: {
                content: 'Writing Data. Please Wait...'
              },
              callback: (store, response) => {
                if (!response.object) { return }
                if ('afterSave' in form.functions) {
                  publish(store, 'callAfter', { func: form.functions.afterSave, params: [store, form, response] })
                }
                if (action.name === '@save-clear') {
                  publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
                }
                publish(store, 'callAfter', { func: Notify, params: [store, `Object ${response.object.sys_id} saved`] })
              },
              stateReducer: (store, response) => {
                if ('object' in response) {
                  // This is a special case that modifies the forms object
                  // which is a complex structure
                  const object = response.object
                  for (const key in object) {
                    if (key in fields) {
                      fields[key].widget.value = object[key]
                    }
                  }
                  form.dirty = false
                  form.original = object
                  form.shadow = context.copy(object)
                  return {
                    forms: {
                      [action.formName]: form,
                      current: form
                    }
                  }
                }
                // Otherwise return the response object for handleresponse and merge
                // into the global state.
                return response
              }
            })
          }
          break
        case '@load': {
          const object = {}
          if (action.value) {
            object.sys_id = action.value
          } else if (form.fields.sys_id.widget.value) {
            object.sys_id = form.fields.sys_id.widget.value
          } else {
            return
          }
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to reload this object from the database?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Operation canceled')
              return
            }
          }

          if (table?.lookup) {
            const row = table.lookup[object.sys_id]
            if (context.isNotEmpty(row)) {
              form = resetForm(action.formName, form)
              context.recursiveAssign(object, row)
              for (const key in object) {
                if (key in form.fields) {
                  form.fields[key].widget.value = object[key]
                  form.fields[key].widget.checked = Boolean(object[key])
                  form.fields[key].widget.selected = Boolean(object[key])
                }
              }
              form.fields.sys_id.widget.variant = 'filled'
              form.fields.sys_id.widget.InputProps = {
                readOnly: true
              }
              form.dirty = false
              form.original = object
              form.shadow = context.copy(object)
              context.log(`form.${form.formName}`, form)
              form.loaded = true
              publish(store, 'setForm', {
                action: {
                  formName: action.formName,
                  type: 'button',
                  name: '@remote-load',
                  value: action.value
                }
              })
              return {
                forms: {
                  [action.formName]: form,
                  current: form
                }
              }
            }
          } else {
            publish(store, 'setForm', {
              action: {
                formName: action.formName,
                type: 'button',
                name: '@remote-load',
                value: action.value,
                spinner: {
                  content: 'Reading Data. Please Wait...'
                },
              }
            })
          }
          break
        }
        case '@remote-load': {
          const object = {}
          if (action.value) {
            object.sys_id = action.value
          } else if (form.fields.sys_id.widget.value) {
            object.sys_id = form.fields.sys_id.widget.value
          } else {
            return
          }
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to reload this object from the database?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }

          call(store, {
            apiName: 'Events',
            apiPath: '/data',
            apiAction: 'read-object',
            apiPayload: {
              tableName: form.table,
              object
            },
            spinner: action.spinner,
            stateReducer: (store, response) => {
              response = handleResponse(store, response)
              const { object } = response
              if (table?.lookup) {
                context.log('in lookup')
                const row = table.lookup[object.sys_id]
                if (context.isNotEmpty(row)) {
                  context.log('found row', row)
                  //  form = resetForm(action.formName, form)
                  context.recursiveAssign(object, row)
                }
              }
              for (const key in object) {
                if (key in fields) {
                  fields[key].widget.value = object[key]
                  fields[key].widget.checked = Boolean(object[key])
                  fields[key].widget.selected = Boolean(object[key])
                }
              }
              form.fields.sys_id.widget.variant = 'filled'
              form.fields.sys_id.widget.InputProps = {
                readOnly: true
              }
              form.dirty = false
              form.original = object
              form.shadow = context.copy(object)
              form.loaded = true
              return {
                forms: {
                  [action.formName]: form,
                  current: form
                }
              }
            }
          })
          break
        }
        case '@validate':
          if (form.fields.sys_id.widget.value) {
            if (action.formName in validate) {
              validate[action.formName].validate(form)
            }
          }
          break
        default:
          context.log('In setFormReducer.button: (unhandled)', action)
          break
      }
      break
    case 'text':
    case 'textarea':
    case 'color':
      action.value = action.value.trim()
    // eslint-disable-next-line
    case 'checkbox':
    default:
      if (action.name === 'sys_id') {
        widget.value = action.value
      } else if (form.loaded) {
        if (action.formName in validate) {
          if ('beforeUpdateField' in validate[action.formName]) {
            validate[action.formName].beforeUpdateField(store, action, form)
          }
        }
        widget.value = action.value
        widget.error = false
        widget.selected = Boolean(action.value)
        widget.checked = Boolean(action.value)
        widget.helperText = defaults.helperText
        if (action.value !== original[action.name]) {
          form.dirty = true
        }
        shadow[action.name] = action.value
        if (action.formName in validate) {
          if ('afterUpdateField' in validate[action.formName]) {
            validate[action.formName].afterUpdateField(store, action, form)
          }
        }
      } else if (action.value) {
        await context.alertPopup('Please load or create new object.', 'Form Error')
      }
      break
  }
  return {
    forms: {
      [action.formName]: form,
      current: form
    }
  }
}

/**
 * MERGE a new state into the existing state. A wrapper for setState with the deepmerge function.
 * @param {*} store
 * @param {*} state
 * @param {bool} overwriteArray - if false, merge new arrays with existing arrays. 
 * If true, replace existing arrays with new arrays.
 */
export const deepMergeState = (store, state, overwriteArray = false) => {
  if (overwriteArray) {
    store.setState(context.merge(store.state, state, { arrayMerge: overwriteMerge }))
  } else {
    store.setState(context.merge(store.state, state))
  }
}

/**
 * NOOP returns teh sourceArray unmodified.
 * @param {*} destinationArray
 * @param {*} sourceArray
 * @param {*} options
 */
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

/**
 * Merges a new state into the existing state. A wrapper for setState with the 
 * recursiveAssign function. See recursiveAssign for implementation details.
 * @param {*} store
 * @param {*} newState
 */
export const mergeState = (store, newState) => {
  store.setState(context.recursiveAssign(store.state, newState))
}

/**
 * NOOP returns the sourceArray unmodified.
 * @param {*} store
 * @param {*} newState
 */
export const insertState = (store, newState) => {
  store.setState({
    ...store.state,
    ...newState
  })
}

/**
 * Deletes an entry in the store corresponding to the key parameter.
 * if the key is a string, then the key is split into an array by periods. 
 * if the key is an array, then the object is traversed by the key and the data 
 * at the final key location is deleted from the object.
 * @param {*} store - 
 * @param {string, array} key - 
 */
export const deleteStateKey = (store, key) => {
  store.setState(context.deleteKey(store.state, key))
}

export const setState = (store, newState) => {
  store.setState(newState)
}

/**
 * This is a utility function that gets called for most API calls. This function looks for a 
 * key called 'actions' in the JSON response, then iterates through each item in 'actions' 
 * and passes the action to the action handler. 
 * @param {*} store
 * @param {*} response
 */
export const handleResponse = (store, response) => {
  if (response && response.actions) {
    response.actions.forEach((item) => {
      publish(store, 'dispatch', item)
    })
    delete response.actions
  }
  return response
}

/**
 * Handle an action item in the JSON response. actions are defined messages that are
 * passed from the back end to the front end and perform specific tasks, including
 * (but not limited) to create file for download, CRUD operations to the store, 
 * populate forms, or create notifications and pop up messages.
 * @param {*} store
 * @param {*} action
 */
const actionHandler = async (store, action) => {
  switch (action.action) {
    case 'set-table':
      if (context.isDefined(action.payload)) {
        for (const [key, value] of Object.entries(action.payload)) {
          if (value.rows) {
            const lookup = {}
            for (const row of value.rows) {
              lookup[row.sys_id] = row
            }
            action.payload[key].lookup = lookup
          }
        }
      }
    // Fall through to set-repo
    // eslint-disable-next-line
    case 'set-repo':
      // repo is a cached representation of the database data ( subset of the data). The repo 
      // contains data in tabular/list format for the purpose of displaying the data in a datatable,
      // as well as a lookup table which is a hashmap indexed by id. Payload should be in the 
      // expected format and is inserted directly into the store. 
      if (context.isDefined(action.payload)) {
        const newState = {
          repo: {
            ...action.payload
          }
        }
        mergeState(store, newState)
      }
      break
    case 'load-object':
      // repo is a cached representation of the database data ( subset of the data). The repo 
      // contains data in tabular/list format for the purpose of displaying the data in a datatable,
      // as well as a lookup table which is a hashmap indexed by id.
      //
      // load-object takes an object that is read on the back end and is passed to the front-end
      // for the purpose of updating the record in the store. The record is updated or added
      // to the data list and lookup data structures.
      if (context.isTrue(action.payload)) {
        const object = action.payload
        const sys_id = object.sys_id.toString()
        const rows = store.state.repo[object.sys_table]?.rows || []
        const index = rows.findIndex((item) => item.sys_id.toString() === sys_id)
        if (index !== -1) {
          context.recursiveAssign(rows[index], object)
        } else {
          object.row_number = rows.length
          rows.push(object)
        }
        const newState = {
          repo: {
            [object.sys_table]: {
              lookup: {
                [sys_id]: object
              },
              rows
            }
          }
        }
        mergeState(store, newState)
      }
      break
    case 'update-store':
      // merge the payload into the store. TODO: there are no sanity checks
      // done here, which can be a risk.
      if (context.isTrue(action.payload)) {
        mergeState(store, action.payload)
      }
      break
    case 'console':
      // Print a message attached to the payload from the back end to the console of the browser. 
      if (context.isTrue(action.payload)) {
        if (action.payload.title) {
          context.log(action.payload)
        } else {
          context.log(action.payload.message)
        }
      }
      break
    case 'file-download':
      // Create a file for download, trigger download in the browser
      if (context.isTrue(action.payload)) {
        FileDownload(context.b64toBlob(action.payload.data), action.payload.filename)
      }
      break
    case 'alert':
      // pop up a modal alert window. (this is not a js alert, it is a MUI modal window.)
      if (context.isTrue(action.payload)) {
        let message = action.payload.message.message || action.payload.message
        let title = action.payload.message.title || action.payload.title
        await context.alertPopup(message, title)
      }
      break
    case 'toast':
      // create a notification using the Snackbar library
      if (!context.isEmpty(action.payload)) {
        NotifyRaw(store, action.payload)
      }
      break
    case 'signout':
      // Sign user out using the AWS library.
      context.signOut()
      break
    case 'redirect':
      // Redirects using the browser's window.location, and not react-router-dom 
      if (!context.isEmpty(action.payload)) {
        context.redirect(action.payload.location)
      }
      break
    default:
      throw new Error('Unhandled action ' + JSON.stringify(action))
  }
}


/**
 * This function is used on forms. This function saves the last "save menu" function item that 
 * was selected and makes it the default option moving forward. This is a helper function that
 * tries to save on mouse clicks if you are doing a bunch of data entry.
 * @param {*} store
 * @param {*} name - The name of the button that was selected and is to be made the default
 */
export const handleSaveMenu = (store, name) => {
  if (name === '@save-new') return
  if (name === '@save-as') return

  const { saveMenu } = store.state.controls
  let i = saveMenu.length
  while (i--) {
    if (saveMenu[i].name === name) {
      if (i === 0) return

      const item = saveMenu.splice(i, 1)
      saveMenu.unshift(item[0])
      break
    }
  }
  mergeState(store, {
    controls: {
      saveMenu: saveMenu
    }
  })
}

/**
 * Perform an API call onButtonClick. pass parameters stored in data set on button through 
 * to api call and execute the handler on the back end.
 * @param {*} store
 * @param {*} event
 */
export const onButtonClick = async (store, event, apiCallback, extraParams = {}) => {
  let current = event.target
  let dataset = {}
  while (current) {
    if (current.dataset && 'action' in current.dataset) {
      dataset = current.dataset
      break
    }
    current = current.parentNode
  }
  if (dataset.title || dataset.warning) {
    try {
      await context.okCancelPopup(dataset.warning,
        dataset.title || 'Warning -- Please Read!'
      )
    } catch {
      Notify(store, 'Action canceled')
      return
    }
  }
  const payload = {
    dataset: { ...dataset, ...extraParams }
  }
  store.actions.api.performApi({
    apiName: 'Events',
    apiPath: '/event',
    apiAction: 'button-click',
    apiPayload: payload,
    spinner: {
      content: 'Performing requested action. Please wait...'
    },
    callback: apiCallback
  })
}

/**
 * Get Column and Row data for a table by FormName
 * @param {*} store
 * @param {*} tableName
 */
export const NotifyRaw = (store, payload) => {
  const key = new Date().getTime() + Math.random()
  const newState = {
    notifications: {
      toast: {
        [key]: payload
      }
    }
  }
  mergeState(store, newState)
}

/**
 * Get Column and Row data for a table by FormName
 * @param {*} store
 * @param {*} tableName
 */
export const Notify = (store, message, variant = 'success') => {
  NotifyRaw(store, {
    options: {
      variant: variant
    },
    message: message
  })
}

/**
 * Perform an API Call to reload a table with data freshly retrieved from the database
 * on the back end. 
 * 
 * A more efficient way to to do this is just to retrive the data that changed
 * and use action `load-object` 
 * @param {*} store
 * @param {*} formName
 */
export const reloadTable = (store, formName, spinner) => {
  const formInstance = formName ? store.state.forms[formName] : store.state.forms.current
  if (formInstance) {
    store.actions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'query',
      apiPayload: context.calcQueryParams(formInstance),
      spinner
    })
  }
}

