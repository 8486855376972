import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'receipt_lookup'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    donation_form_html: widget({
      label: 'Donation Form Html'
    }, true),
    receipt: checkbox({
      label: 'Receipt'
    }),
    receipt_html: widget({
      label: 'Receipt Html'
    }, true),
    receipt_sender: widget({
      label: 'Receipt Sender'
    }),
    receipt_subject: widget({
      label: 'Receipt Subject'
    }, {
      display: true,
      align: 'left'
    }),
    receipt_to: widget({
      label: 'Receipt To'
    }, {
      display: true,
      searchable: true
    }),
    receipt_cc: widget({
      label: 'Receipt Cc'
    }),
    receipt_bcc: widget({
      label: 'Receipt Bcc'
    }),
    receipt_date: widget({
      label: 'Receipt Date'
    }, {
      display: true
    }),
    braintree_transaction: widget({
      label: 'Braintree Transaction'
    }, {
      display: true
    })
  }
})



export default data
