import context from 'lib/context'
import system from '../system'
import {
  color,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'customers'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    client_id: widget({
      label: 'Client Id'
    }, {
      display: true,
      searchable: true
    }),
    client_name: widget({
      label: 'Client Name'
    }, {
      display: true,
      searchable: true
    }),
    descriptor: widget({
      label: 'Descriptor'
    }, {
      display: true
    }),
    page_title: widget({
      label: 'Page Title'
    }),
    logo: widget({
      label: 'Logo'
    }),
    primary_color: color({
      label: 'Primary Color'
    }),
    secondary_color: color({
      label: 'Secondary Color'
    }),
    header_font: widget({
      label: 'Header Font'
    }),
    text_font: widget({
      label: 'Text Font'
    })
  }
})



export default data
