import context from 'lib/context'
import system from '../system'
import {
  checkbox,
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'supported'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    ein: widget({
      label: 'Ein'
    }, {
      display: true
    }),
    group: widget({
      label: 'Group'
    }, {
      display: true
    }),
    street: widget({
      label: 'Street'
    }, {
      display: true
    }),
    filing_req_cd: widget({
      label: 'Filing Req Cd'
    }, {
      display: true
    }),
    classification: widget({
      label: 'Classification'
    }, {
      display: true
    }),
    asset_cd: widget({
      label: 'Asset Cd'
    }, {
      display: true
    }),
    tax_period: widget({
      label: 'Tax Period'
    }, {
      display: true
    }),
    ntee_cd: widget({
      label: 'Ntee Cd'
    }, {
      display: true
    }),
    pf_filing_req_cd: widget({
      label: 'Pf Filing Req Cd'
    }, {
      display: true
    }),
    revenue_amt: widget({
      label: 'Revenue Amt'
    }, {
      display: true
    }),
    subsection: widget({
      label: 'Subsection'
    }, {
      display: true
    }),
    activity: widget({
      label: 'Activity'
    }, {
      display: true
    }),
    organization: widget({
      label: 'Organization'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    foundation: widget({
      label: 'Foundation'
    }, {
      display: true
    }),
    ico: widget({
      label: 'Ico'
    }, {
      display: true
    }),
    zip: widget({
      label: 'Zip'
    }, {
      display: true
    }),
    income_amt: widget({
      label: 'Income Amt'
    }, {
      display: true
    }),
    state: widget({
      label: 'State'
    }, {
      display: true
    }),
    acct_pd: widget({
      label: 'Acct Pd'
    }, {
      display: true
    }),
    city: widget({
      label: 'City'
    }, {
      display: true
    }),
    name: widget({
      label: 'Name'
    }, {
      display: true
    }),
    asset_amt: widget({
      label: 'Asset Amt'
    }, {
      display: true
    }),
    sort_name: widget({
      label: 'Sort Name'
    }, {
      display: true
    }),
    affiliation: widget({
      label: 'Affiliation'
    }, {
      display: true
    }),
    deductibility: widget({
      label: 'Deductibility'
    }, {
      display: true
    }),
    ruling: widget({
      label: 'Ruling'
    }, {
      display: true
    }),
    income_cd: widget({
      label: 'Income Cd'
    }, {
      display: true
    }),
    irs_confirmed_date: widget({
      label: 'Irs Confirmed Date'
    }, {
      display: true
    }),
    irs_confirmed: checkbox({
      label: 'Irs Confirmed'
    }, {
      display: true
    }),
    approved: checkbox({
      label: 'Approved'
    }, {
      display: true
    }),
    campaign_title: widget({
      label: 'Campaign Title'
    }, {
      display: true
    }),
    createddate: widget({
      label: 'Createddate'
    }, {
      display: true
    }),
    inactive: checkbox({
      label: 'Inactive'
    }, {
      display: true
    }),
    inuse: checkbox({
      label: 'Inuse'
    }, {
      display: true
    }),
    ispolitical: checkbox({
      label: 'Ispolitical'
    }, {
      display: true
    }),
    lastupdateddate: widget({
      label: 'Lastupdateddate'
    }, {
      display: true
    }),
    largelogourl: widget({
      label: 'Largelogourl'
    }, {
      display: true
    }),
    longdescription: widget({
      label: 'Longdescription'
    }, {
      display: true
    }),
    primaryemail: widget({
      label: 'Primaryemail'
    }, {
      display: true
    }),
    registered: checkbox({
      label: 'Registered'
    }, {
      display: true
    }),
    shortdescription: widget({
      label: 'Shortdescription'
    }, {
      display: true
    }),
    smalllogourl: widget({
      label: 'Smalllogourl'
    }, {
      display: true
    }),
    vanityurl: widget({
      label: 'Vanityurl'
    }, {
      display: true
    }),
    website: widget({
      label: 'Website'
    }, {
      display: true
    }),
    isactive: checkbox({
      label: 'Isactive'
    }, {
      display: true
    }),
    attestation_text: widget({
      label: 'Attestation Text'
    }, {
      display: true
    }),
    notes: widget({
      label: 'Notes'
    }, {
      display: true
    }),
    is_rallygive_active: checkbox({
      label: 'Is Rallygive Active'
    }, {
      display: true
    }),
    rally_metrics: widget({
      label: 'Rally Metrics'
    }, {
      display: true
    }),
    is_swipegive_active: checkbox({
      label: 'Is Swipegive Active'
    }, {
      display: true
    }),
    connector_logo: widget({
      label: 'Connector Logo'
    }, {
      display: true
    }),
    donation_amounts: widget({
      label: 'Donation Amounts'
    }, {
      display: true
    }),
    recurring_support_title: widget({
      label: 'Recurring Support Title'
    }, {
      display: true
    }),
    recurring_support_description: widget({
      label: 'Recurring Support Description'
    }, {
      display: true
    }),
    connector_text: widget({
      label: 'Connector Text'
    }, {
      display: true
    }),
    is_donation_active: checkbox({
      label: 'Is Donation Active'
    }, {
      display: true
    }),
    rally_metrics_title: widget({
      label: 'Rally Metrics Title'
    }, {
      display: true
    }),
    donation_form_logo: widget({
      label: 'Donation Form Logo'
    }, {
      display: true
    }),
    donation_form_text: widget({
      label: 'Donation Form Text'
    }, {
      display: true
    }),
    donation_form_photo: widget({
      label: 'Donation Form Photo'
    }, {
      display: true
    }),
    rally_metrics_subtitle: widget({
      label: 'Rally Metrics Subtitle'
    }, {
      display: true
    }),
    approval_required: checkbox({
      label: 'Approval Required'
    }, {
      display: true
    }),
    billing_street: widget({
      label: 'Billing Street'
    }, {
      display: true
    }),
    billing_city: widget({
      label: 'Billing City'
    }, {
      display: true
    }),
    billing_state: widget({
      label: 'Billing State'
    }, {
      display: true
    }),
    billing_zip: widget({
      label: 'Billing Zip'
    }, {
      display: true
    }),
    largelogourl_proposed: widget({
      label: 'Largelogourl Proposed'
    }, {
      display: true
    }),
    longdescription_proposed: widget({
      label: 'Longdescription Proposed'
    }, {
      display: true
    }),
    receiptlogourl_proposed: widget({
      label: 'Receiptlogourl Proposed'
    }, {
      display: true
    }),
    geographic_distribution_link: widget({
      label: 'Geographic Distribution Link'
    }, {
      display: true
    })
  }
})



export default data
