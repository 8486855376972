import * as React from 'react'
import {
  Alert,
  TextField as MuiTextField,
  Box,
} from '@mui/material'
import _ from 'underscore'
import { QuestionMark, MoreInfo } from 'components'
import context from 'lib/context'

const _onChange = _.debounce((event, func) => {
  func(event)
}, 500)

export default function MultiValue({
  formName,
  onChange,
  value,
  showcount,
  moreInfo,
  ...props }) {

  if (value === null) { value = '' }
  const [_calculated, _setCalculated] = React.useState('') // This holds the '/n' separated string
  const [_count, _setCount] = React.useState(0)

  React.useEffect(() => {
    const list = context.textToList(value)
    const calculated = list.join('\n')
    if (calculated !== _calculated) {
      _setCount(list.length)
      _setCalculated(calculated)
    }
  }, [value]) // eslint-disable-line

  const onEvent = (event) => {
    const temp = context.textToList(event.target.value, '\n')
    const newValue = '{' + temp.join(',') + '}'
    const newEvent = {
      target: {
        type: 'input',
        name: props.name,
        value: newValue
      }
    }
    _setCount(temp.length)
    _onChange(newEvent, onChange)
    _setCalculated(event.target.value)
  }

  return (
    <>
      <Box error-id={props.name} sx={{
        position: 'relative'
      }}>

        <MuiTextField
          widget='MultiValue'
          InputLabelProps={{ shrink: true }}
          {...props}
          error={Boolean(props.error)}
          value={_calculated}
          onChange={onEvent}
          multiline
        />
        {props.questionmark && <QuestionMark style={{
          position: 'absolute',
          top: -15,
          right: -15,
        }}>{props.questionmark}</QuestionMark>}
        {moreInfo && <MoreInfo>
          {moreInfo}
        </MoreInfo>}
        {showcount && showcount === 'true' && <Box sx={{
          position: 'absolute',
          top: 10,
          right: 3,
          fontSize: '80%',
          color: 'gray'
        }}>{_count}</Box>}
        {props.error && <Alert severity='error'>{props.error}</Alert>}
      </Box>
    </>
  )
}
