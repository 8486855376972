import * as React from 'react'
import useGlobal from 'store'
import { Grid } from '@mui/material'


import {
  TextField,
  Checkbox
} from 'components'


export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const { fields } = globalState.forms[formName]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            {...fields.pii_instructions.widget}
            onChange={onEvent}
          />
        </Grid>
        <fieldset sx={{
          p: 20,
          borderRadius: 3,
          color: 'rgba(0, 0, 0, 0.4)',
          borderColor: 'rgba(0, 0, 0, 0.1)'
        }}>
          <legend>Donor Information</legend>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_first_name.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_last_name.widget}
                onChange={onEvent}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_email_address.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_phone_number.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_postal_address.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_postal_city.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_postal_state.widget}
                onChange={onEvent}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox
                {...fields.pii_collect_postal_zipcode.widget}
                onChange={onEvent}
              />
            </Grid>

          </Grid>
        </fieldset>
      </Grid>
    </>
  )
}
