import * as React from 'react'
import {
  Button,
  Tooltip
} from '@mui/material'
import useGlobal from 'store'

export default function ActionButton({
  children,
  tooltip,
  beforeCall,
  apiCallback,
  extraParams = {},
  ...props
}) {
  const [globalState, globalActions] = useGlobal() // eslint-disable-line
  const onButtonClick = (event) => {
    let okToContinue = true
    if (beforeCall) {
      okToContinue = beforeCall(event, extraParams) !== false
    }
    console.log('extraParams', extraParams)
    if (okToContinue) {
      globalActions.control.onButtonClick(event, apiCallback, extraParams)
    }
    event.preventDefault()
    event.stopPropagation()
  }
  if (tooltip) {
    return (
      <Tooltip title={tooltip} enterDelay={1500} leaveDelay={200}>
        <span>
          <Button
            onClick={onButtonClick}
            color='secondary'
            variant='contained'
            size='small'
            fullWidth
            {...props}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <span>
      <Button
        onClick={onButtonClick}
        color='secondary'
        variant='contained'
        size='small'
        fullWidth
        {...props}
      >
        {children}
      </Button>
    </span>
  )
}
