import * as React from 'react'
import {
  Box,
  Container,
} from '@mui/material'
import Notifications from './Notifications'
import Password from './Password'


const SettingsView = () => {

  return (
    <Container maxWidth={false}>
      <Notifications />
      <Box mt={3}>
        <Password />
      </Box>
    </Container>
  )
}

export default SettingsView
