import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'billing_period'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    description: widget({
      label: 'Description'
    }, {
      display: true
    }),
    effective_month: widget({
      label: 'Effective Month'
    }, {
      display: true
    }),
    effective_year: widget({
      label: 'Effective Year'
    }, {
      display: true
    }),
    bt_start_date: widget({
      label: 'Bt Start Date'
    }),
    bt_end_date: widget({
      label: 'Bt End Date'
    }),
    an_start_date: widget({
      label: 'An Start Date'
    }),
    an_end_date: widget({
      label: 'An End Date'
    })
  }
})



export default data
