import { createSharedStoreHook } from 'shared-store-hook'

export const useKeyValueData = createSharedStoreHook({
    initialState: {
        dragActive: false,
        dragItemNode: null,
        dragItemIndex: null,
        dragItemElement: null,
        dropItemNode: null,
        dropItemIndex: null,
        dropItemElement: null,
        dragDirection: null,
    }
})