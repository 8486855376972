import context from 'lib/context'
import system from '../system'
import {
  widget,
} from '../_widgets'
import * as cards from './cards'
import * as functions from './functions'


export const table = 'sys_setup_account'
export const data = context.merge(system, {
  table,
  cards,
  functions,
  query: {
    obj: table,
    datatable: table,
    params: {
      columns: null, // Replaced during form init with proper values.
      orderby: 'sys_id desc'
    },
    headers: null // Replaced during form init with proper values.
  },
  fields: {
    email_address: widget({
      label: 'Email Address'
    }, {
      display: true
    }),
    status: widget({
      label: 'Status'
    }, {
      display: true
    }),
    first_name: widget({
      label: 'First Name'
    }, {
      display: true
    }),
    last_name: widget({
      label: 'Last Name'
    }, {
      display: true
    }),
    remote_addr: widget({
      label: 'Remote Addr'
    }, {
      display: true
    }),
    supported_id: widget({
      label: 'Supported Id'
    }, {
      display: true
    }),
    host: widget({
      label: 'Host'
    }, {
      display: true
    }),
    date_terms_accepted: widget({
      label: 'Date Terms Accepted'
    }, {
      display: true
    }),
    password: widget({
      label: 'Password'
    }, {
      display: true
    }),
    div1: widget({
      label: 'Div1'
    }, {
      display: true
    }),
    role: widget({
      label: 'Role'
    }, {
      display: true
    }),
    full_name: widget({
      label: 'Full Name'
    }, {
      display: true
    }),
    start_date: widget({
      label: 'Start Date'
    }, {
      display: true
    }),
    user_id: widget({
      label: 'User Id'
    }, {
      display: true
    }),
    entered_by: widget({
      label: 'Entered By'
    }, {
      display: true
    }),
    entered_by_name: widget({
      label: 'Entered By Name'
    }, {
      display: true
    }),
    identifier: widget({
      label: 'Identifier'
    }, {
      display: true
    })
  }
})



export default data
