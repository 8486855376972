import * as React from 'react'
import useGlobal from 'store'
import { Link as RouterLink } from 'react-router-dom'
import { Divider, Grid, Button } from '@mui/material'
import {
  DirtyForm,
  SaveButtons,
  SystemFields,
  TextField,
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields formName={formName} title='Client'>
            Instructions: TBD
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Button>
            <RouterLink to='/app/upload-zip-codes'> Upload Zip Code File</RouterLink>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.client.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.zipcode.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <br />
        <SaveButtons formName={formName} />
      </Grid>
    </>
  )
}
