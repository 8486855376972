import * as React from 'react'
import useGlobal from 'store'
import { Divider, Grid } from '@mui/material'
import {
  DirtyForm,
  SystemFields,
  TextField,
  TextFieldRot13,
  SaveButtons,
  Checkbox
} from 'components'

export default function Component({ formName, ...rest }) {
  const [globalState, globalActions] = useGlobal()  // eslint-disable-line

  const onEvent = (event) => {
    globalActions.control.onFormEvent(formName, event, false)
  }

  const form = globalState.forms[formName]
  const fields = form.fields

  return (
    <>
      <DirtyForm formName={formName} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SaveButtons formName={formName} />
        </Grid>
        <Grid item xs={12}>
          <SystemFields
            formName={formName}
            title='SMTP Settings'
          >
            Instructions: An email Instance defines an instance of email
          </SystemFields>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldRot13
            {...fields.host_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fields.port.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            {...fields.ttls.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldRot13
            {...fields.user_name.widget}
            onChange={onEvent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldRot13
            {...fields.password.widget}
            onChange={onEvent}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <br />
        <SaveButtons formName={formName} />
      </Grid>
    </>
  )
}
