
import context from 'lib/context'

export const beforeSave = (store, form) => { }
export const afterSave = (store, form) => { store.actions.control.reloadTable(form.formName) }
export const afterDelete = (store, form) => { store.actions.control.reloadTable(form.formName) }
export const makeLabel = option => String(option.id) + String(option.description)
export const makeWhere = (value, form) => {
  const predicate = []
  if (context.isPositiveInteger(value)) {
    predicate.push(`sys_id = ${value}`)
  }
  if (context.isDefined(form)) {
    const searchableFields = Object.keys(form.fields).filter(key => form.fields[key]?.dt?.options?.searchable ? key : null)
    if (context.isString(value)) {
      value.split(' ').forEach(element => {
        searchableFields.forEach(field => {
          predicate.push(`${field} ilike '%${value}%'`)
        })
      })
    }

  } else {
    if (context.isString(value)) {
      value.split(' ').forEach(element => {
        predicate.push(`description ilike '%${element}%'`)
      })
    }
  }

  return predicate.join(' or ')
}
